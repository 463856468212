import { FC, ReactElement, useState } from 'react';
import PhoneInput from 'react-phone-number-input/min';
import { useBem } from 'shared/hooks';
import { FloatingLabel } from '../FloatingLabel/FloatingLabel';

import './PhoneInput.sass';
import cn from 'classnames';

interface PhoneInputProps {
  label: string;
  onChange: any;
  disabled?: boolean;
  autoComplete?: string;
  value: string;
  inputClassName?: string;
  containerClassName?: string;
}

export const PhoneInputFloatingLabel: FC<PhoneInputProps> = ({
  label,
  onChange,
  disabled,
  value,
  inputClassName,
  containerClassName,
}): ReactElement => {
  const [focus, setFocus] = useState(false);
  const bem = useBem('PhoneInput');

  return (
    <div
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      className={cn(bem(), containerClassName)}
    >
      <PhoneInput className={inputClassName} onChange={onChange} />
      <FloatingLabel
        text={label || ''}
        small={focus || !!value}
        disabled={disabled}
      />
    </div>
  );
};
