import { Dispatch } from 'react';

import { api } from 'api';
import { setCookie } from 'shared/utils/cookie';
import { authUser } from 'store';
import { handleError } from 'shared/utils/validation';

type AuthData = {
  Email: string;
  Password: string;
};

type UserData = {
  Name: string;
  Email: string;
  CounterpartyType: number;
};

const getUserRole = async () => {
  try {
    const {
      data: { CounterpartyType },
    } = await api.get<UserData>('Account/info');
    localStorage.setItem('CounterpartyType', JSON.stringify(CounterpartyType));
  } catch (e) {
    handleError(e);
  }
};

const onLogin = async (
  { Email, Password }: AuthData,
  dispatch: Dispatch<{ payload: boolean; type: string }>,
) => {
  try {
    const {
      data: { Token },
    } = await api.post<{ Token: string }>('/Account/login', {
      Email,
      Password,
    });
    setCookie('auth_token', Token);
    dispatch(authUser(true));

    await getUserRole();
  } catch (e) {
    handleError(e);
  }
};

export const useAuthorizationPage = () => {
  return {
    onLogin,
  };
};
