import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { useParams, Link } from 'react-router-dom';

import { InsurerFranchiseType } from 'types/insuranceContracts';
import { GeneralDetailsForm } from '../GeneralDetailsForm/GeneralDetailsForm';
import { isRussian } from 'shared/utils/isRussian';
import { SpinWrapper } from 'shared/components';
import { GeneralDetailsProps } from 'types/generalDetails';
import { useGeneralDetails } from './useGeneralDetails';
import { formatCombineLimits, formatDate } from 'shared/utils/formatting';
import { flotCount, getFranchiseType } from './utils';
import { formatMoney } from 'shared/utils/formatMoney';

export const GeneralDetails: FC<GeneralDetailsProps> = ({
  isEditable,
  setEditable,
  data,
}): ReactElement => {
  const { t, i18n } = useTranslation();
  const { requestId } = useParams<{ requestId: string }>();
  const { Title } = Typography;

  const { combineLimits, isLoading, refreshGeneralDetails } = useGeneralDetails(
    requestId,
  );

  if (isEditable) {
    return (
      <GeneralDetailsForm
        id={requestId}
        initialData={data}
        refreshContract={refreshGeneralDetails}
        closeForm={() => setEditable(false)}
      />
    );
  }

  return (
    <SpinWrapper isLoading={isLoading}>
      <div className="info-group">
        <p>
          {t('generalInformation.fieldText.flot')}: {flotCount(combineLimits)}{' '}
          {t('generalInformation.valuesText.aircraft')}
        </p>
        <p>
          {t('generalInformation.fieldText.insurancePeriod')}:{' '}
          {formatDate(data?.StartDate, data?.EndDate)}
        </p>
        <p>
          {t('generalInformation.fieldText.insuranceTerritory')}:{' '}
          {isRussian(i18n.language)
            ? data?.CountriesNameRu
            : data?.CountriesNameEn}
        </p>
      </div>
      <div className="info-group">
        <p>
          {t('generalInformation.fieldText.insured')}:{' '}
          <Link to={`/company=${data?.InsuredCounterpartyId}`}>
            {isRussian(i18n.language)
              ? data?.InsuredCounterpartyNameRu ?? '-'
              : data?.InsuredCounterpartyNameEn ?? '-'}
          </Link>
        </p>
        <p>
          {t('generalInformation.fieldText.insurer')}:{' '}
          {data?.InsurerCounterpartyId ? (
            <Link to={`/company=${data?.InsurerCounterpartyId}`}>
              {isRussian(i18n.language)
                ? data?.InsurerCounterpartyNameRu ?? '-'
                : data?.InsurerCounterpartyNameEn ?? '-'}
            </Link>
          ) : (
            <span className="empty-string">{t('emptyValues.value')}</span>
          )}
        </p>
      </div>
      <div className="info-group">
        <Title level={4}>{t('generalInformation.fieldText.casco')}</Title>
        <p>
          {t('generalInformation.fieldText.maxCost')}:{' '}
          {formatMoney(data?.MaxVal)}
        </p>
        <p>
          {t('generalInformation.fieldText.onBoardKit')}:{' '}
          {formatMoney(data?.OnboardkitVal)}
        </p>
        <p>
          {t('generalInformation.fieldText.sparePartsAndEquipment')}:{' '}
          {formatMoney(data?.ToolkitVal)}
        </p>
      </div>
      <div className="info-group">
        <Title level={4}>
          {t('generalInformation.fieldText.responsibility')}
        </Title>
        <p>
          {t('generalInformation.fieldText.singleCombinedLimit')}:{' '}
          {formatCombineLimits(combineLimits!, t)}
        </p>
        <p>
          {t('generalInformation.fieldText.personalInjury')}:{' '}
          {formatMoney(data?.PersonalInjuryVal)}
        </p>
      </div>
      <div className="info-group">
        <Title level={4}>{t('generalInformation.fieldText.franchise')}</Title>
        <p>
          {t('generalInformation.fieldText.view')}:{' '}
          {data ? getFranchiseType(data.FranchiseType, t) : ' - '}
        </p>
        {data?.FranchiseType !== InsurerFranchiseType.Absent && (
          <>
            <p>
              {t('generalInformation.fieldText.aircraftCost')}:{' '}
              {formatMoney(data?.FranchisePlaneVal)}
            </p>
            <p>
              {t('generalInformation.fieldText.onBoardKitCost')}:{' '}
              {formatMoney(data?.FranchiseOnboardkitVal)}
            </p>
            <p>
              {t('generalInformation.fieldText.sparePartsAndEquipmentCost')}:{' '}
              {formatMoney(data?.FranchiseToolkitVal)}
            </p>
          </>
        )}
      </div>
    </SpinWrapper>
  );
};
