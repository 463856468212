import { useCallback, useEffect, useMemo, useState } from 'react';
import { Popconfirm, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { Params } from 'types/common';
import { api } from 'api';
import { Service } from './ServicePage';
import { useSearch } from 'shared/hooks/useSearch';
import { PROFILE_SERVICE } from 'api/endpoints';
import { handleError } from 'shared/utils/validation';
import { isRussian } from 'shared/utils/isRussian';
import { useParams } from 'react-router-dom';
import { isReinsurer } from 'shared/utils/checkUserRole';

const getServices = async (insuranceContractId: string) => {
  try {
    const { data } = await api.get<Service[]>(
      `/InsuranceContracts/${insuranceContractId}/fleet/maintenances`,
    );
    return data;
  } catch (e) {
    handleError(e);
  }
};

const deleteMaintenance = async (id: number) => {
  try {
    await api.delete(`/Maintenances/${id}`);
  } catch (e) {
    handleError(e);
  }
};

const createMaintenance = async (value: Service) => {
  try {
    await api.post('/Maintenances', value);
  } catch (e) {
    handleError(e);
  }
};

export const useServicePage = (id: string, showTitle: boolean = true) => {
  const { requestId } = useParams<Params>();
  const [services, setServices] = useState<Service[]>();
  const { data: serviceData, refreshSearch, isFetching } = useSearch(
    PROFILE_SERVICE,
  );
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [showForm, setShowForm] = useState<boolean>(false);
  const counterpartyType = localStorage.getItem('CounterpartyType');
  const dataSource = useMemo(
    () => (requestId ? services : serviceData?.Items ?? (serviceData as any)),
    [requestId, serviceData, services],
  );

  const openForm = () => {
    setShowForm(true);
  };

  const refresh = useCallback(
    (config?: {}) => {
      if (requestId) {
        getServices(requestId).then(setServices);
      } else {
        refreshSearch(config);
      }
    },
    [refreshSearch, requestId],
  );

  const closeForm = () => {
    setShowForm(false);
  };

  const onFormSubmit = async (record: any) => {
    try {
      createMaintenance(record).then(refreshSearch);
      closeForm();
    } catch (e) {
      handleError(e);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: t('servicePage.table.company'),
        dataIndex: 'Company',
        key: 'Company',
        width: '30%',
        isRequired: true,
        editable: !requestId && !isReinsurer(counterpartyType),
        sorter: true,
      },
      {
        title: t('servicePage.table.country'),
        dataIndex: 'CountryId',
        key: 'CountryId',
        width: '15%',
        selectType: 'country',
        typeCell: 'select',
        isRequired: true,
        editable: !requestId && !isReinsurer(counterpartyType),
        sorter: true,
        render: (_: undefined, { CountryNameRu, CountryNameEn }: Service) =>
          isRussian(language) ? CountryNameRu : CountryNameEn,
      },
      {
        title: t('servicePage.table.typeOfShips'),
        dataIndex: 'PlaneTypeIds',
        key: 'Planes',
        width: '25%',
        isService: true,
        typeCell: 'multiSelect',
        isRequired: true,
        editable: !requestId && !isReinsurer(counterpartyType),
        render: (_: number[], { Planes }: Service): JSX.Element[] | string => {
          return Planes?.length
            ? Planes?.map(({ Id, Name }) => {
                return <Tag key={Id}>{Name}</Tag>;
              })
            : '-';
        },
      },
      {
        title: t('servicePage.table.typeOfWork'),
        dataIndex: 'ServiceTypeIds',
        key: 'typeOfWork',
        width: '30%',
        typeCell: 'multiSelect',
        multiType: 'work',
        isService: true,
        isRequired: true,
        editable: !requestId && !isReinsurer(counterpartyType),
        render: (
          _: number[],
          { Services }: Service,
        ): JSX.Element[] | string => {
          return Services?.length
            ? Services?.map(({ Id, Name }) => {
                return <Tag key={Id}>{Name}</Tag>;
              })
            : '-';
        },
      },
      ...[
        showTitle && !isReinsurer(counterpartyType)
          ? {
              title: '',
              dataIndex: 'actions',
              width: '5%',
              render: (_: undefined, { Id }: Service) => {
                return (
                  <Popconfirm
                    title={t('deleteRow')}
                    onConfirm={() =>
                      deleteMaintenance(Id).then(() => refresh())
                    }
                    okText={t('deleteOk')}
                    cancelText={t('deleteCancel')}
                  >
                    <p className="custom-cell">
                      {t('usersTable.deleteAction')}
                    </p>
                  </Popconfirm>
                );
              },
            }
          : {},
      ],
    ],
    [counterpartyType, language, refresh, requestId, showTitle, t],
  );

  useEffect(() => {
    if (requestId) {
      getServices(requestId).then(setServices);
    } else {
      refreshSearch();
    }
  }, [refreshSearch, requestId]);

  return {
    isFetching,
    serviceData: dataSource,
    columns,
    deleteMaintenance,
    showForm,
    openForm,
    closeForm,
    onFormSubmit,
    refresh,
  };
};
