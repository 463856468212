import { api } from 'api';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Plane } from 'types/planes';
import { formatMoney } from 'shared/utils/formatMoney';
import { isRussian } from 'shared/utils/isRussian';
import {
  canAirlineEdit,
  canBrokerEdit,
  isAirline,
  isBroker,
} from 'shared/utils/checkUserRole';
import { handleError } from 'shared/utils/validation';
import { formatDate } from '../../../../shared/utils/formatting';
import { InsuranceContractType } from 'types/insuranceContracts';

const updateFlot = async (id: string, record: Plane) => {
  const updatedPlaneData = {
    StartVal: Number(record.StartVal),
    EndVal: Number(record.EndVal),
    PlaneId: record.PlaneId,
  };
  try {
    const { data } = await api.put(
      `/InsuranceContracts/${id}/fleet/planes/${record.Id}`,
      updatedPlaneData,
    );
    return data;
  } catch (e) {
    handleError(e);
  }
};

const createFlot = async (id: string, record: Plane) => {
  try {
    const { data } = await api.post(`/InsuranceContracts/${id}/fleet/planes`, {
      PlaneId: record.PlaneId,
      InsuranceContractId: Number(id),
    });
    return data;
  } catch (e) {
    throw handleError(e);
  }
};

export const useFlotTable = (
  refresh: () => void,
  id: string,
  contractStatus?: number,
) => {
  const {
    i18n: { language },
  } = useTranslation();
  const counterpartyType = localStorage.getItem('CounterpartyType');

  const editableStatus = useMemo(() => {
    return (
      (isAirline(counterpartyType) &&
        [
          InsuranceContractType.Blank,
          InsuranceContractType.Correction,
        ].includes(Number(contractStatus))) ||
      (isBroker(counterpartyType) &&
        contractStatus === InsuranceContractType.Review)
    );
  }, [contractStatus, counterpartyType]);

  const columns = useMemo(() => {
    return [
      {
        title: isRussian(language) ? 'Модель' : 'Model',
        dataIndex: 'TypeName',
        key: 'type',
        width: '25%',
        sorter: true,
      },
      {
        title: isRussian(language) ? 'Бортовой номер' : 'Plane number',
        dataIndex: 'RegisterNumber',
        key: 'RegisterNumber',
        sorter: true,
      },
      {
        title: isRussian(language) ? 'Заводской номер' : 'Factory number',
        dataIndex: 'FactoryNumber',
        key: 'factoryNumber',
        sorter: true,
      },
      {
        title: isRussian(language) ? 'Период' : 'Period',
        dataIndex: 'Period',
        key: 'period',
        width: 200,
        sorter: true,
        render: (_: undefined, { StartDate, EndDate }: Plane) =>
          formatDate(StartDate, EndDate),
      },
      {
        title: isRussian(language) ? 'Стоимость начальная' : 'Initial cost',
        dataIndex: 'StartVal',
        key: 'startVal',
        typeCell: 'inputNumber',
        editable: editableStatus,
        sorter: true,
        render: (StartVal: number) => formatMoney(StartVal),
      },
      {
        title: isRussian(language) ? 'Стоимость средняя' : 'Average cost',
        dataIndex: 'AvgVal',
        key: 'avgVal',
        sorter: true,
        render: (AvgVal: number) => formatMoney(AvgVal),
      },
      {
        title: isRussian(language) ? 'Стоимость конечная' : 'End cost',
        dataIndex: 'EndVal',
        key: 'EndVal',
        typeCell: 'inputNumber',
        editable: editableStatus,
        sorter: true,
        render: (EndVal: number) => formatMoney(EndVal),
      },
      {
        title: isRussian(language) ? 'Комбинированный лимит' : 'Combined limit',
        dataIndex: 'CombineLimit',
        key: 'CombineLimit',
        sorter: true,
        render: (CombineLimit: string) => {
          return formatMoney(CombineLimit);
        },
      },
      {
        title: isRussian(language) ? 'Мест' : 'Places',
        dataIndex: 'PlacesAmount',
        key: 'PlacesAmount',
        sorter: true,
      },
    ];
  }, [editableStatus, language]);

  const onFlotSave = useCallback(
    async (record: any) => {
      if (!record.Id) {
        createFlot(id, record).then(data => {
          const createdData = { ...data, ProfileId: record.ProfileId };
          updateFlot(id, {
            ...createdData,
            StartVal: record.StartVal,
            EndVal: record.EndVal,
          }).then(refresh);
        });
      } else {
        updateFlot(id, record).then(refresh);
      }
    },
    [id, refresh],
  );

  useEffect(() => {
    refresh();
  }, [refresh, id]);

  return {
    columns,
    onFlotSave,
  };
};
