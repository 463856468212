import { ReduxState } from '../store';
import {
  getFetchedRequestState,
  getFetchingRequestState,
  getInitialRequestState,
  MergeRequestState,
  ResponseApiError,
} from '../utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'api';
import { InsuranceContract } from 'types/insuranceContracts';

export interface InsuranceContractsState
  extends MergeRequestState<InsuranceContract[], ResponseApiError> {}

const selector = {
  state: (state: ReduxState) => state.insuranceContracts,
  isFetching: (state: ReduxState) => state.insuranceContracts.isFetching,
  data: (state: ReduxState) => state.insuranceContracts.data,
  error: (state: ReduxState) => state.insuranceContracts.error,
};

const initialState: InsuranceContractsState = {
  ...getInitialRequestState(),
  error: null,
  data: null,
};

const SLICE_NAME = `insuranceContracts`;

const requestThunk = createAsyncThunk(
  `${SLICE_NAME}/request`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await api.get<InsuranceContract[]>(
        '/InsuranceContracts',
      );

      return data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  },
  {
    condition: (payload, { getState }) =>
      !selector.isFetching(getState() as ReduxState),
  },
);

const deleteThunk = createAsyncThunk(
  `${SLICE_NAME}/delete`,
  async (insuranceContractId: string, { rejectWithValue }) => {
    try {
      await api.delete(`/InsuranceContracts/${insuranceContractId}`);
    } catch (e) {
      throw rejectWithValue(e);
    }
  },
);

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(requestThunk.pending, state => ({
        ...state,
        ...getFetchingRequestState(),
      }))
      .addCase(requestThunk.fulfilled, (state, action) => ({
        ...state,
        ...getFetchedRequestState(),
        data: action.payload as InsuranceContract[],
        error: null,
      }))
      .addCase(requestThunk.rejected, (state, action) => ({
        ...state,
        ...initialState,
        error: action.payload as ResponseApiError,
      }));
  },
});

interface InsuranceContracts {
  action: typeof actions;
  thunk: {
    request: typeof requestThunk;
    delete: typeof deleteThunk;
  };
  reducer: typeof reducer;
  selector: typeof selector;
}

export const insuranceContracts: InsuranceContracts = {
  action: actions,
  thunk: {
    request: requestThunk,
    delete: deleteThunk,
  },
  reducer,
  selector,
};
