import { FC, ReactElement } from 'react';
import { Button, Row, Table } from 'antd';
import { SpinWrapper } from 'shared/components';
import { useCoverageTable } from 'pages/InsurancePage/components/CoverageTable/useCoverageTable';
import { useParams } from 'react-router';
import { Params } from 'types/common';
import { CoverageHistoryModal } from 'pages/InsurancePage/components/CoverageHistoryModal';
import { useTranslation } from 'react-i18next';
import { useCreateCoverageModal } from './components/CreateCoverageModal/useCreateCoverageModal';
import { CreateCoverageModal } from './components';
import {
  InsuranceContractType,
  InsuranceTabProps,
} from 'types/insuranceContracts';
import { isBroker } from 'shared/utils/checkUserRole';

export const CoverageTable: FC<InsuranceTabProps> = ({
  contractStatus,
}): ReactElement => {
  const { requestId } = useParams<Params>();
  const { t } = useTranslation();
  const counterpartyType = localStorage.getItem('CounterpartyType');

  const {
    isLoading,
    data,
    columns,
    modalData,
    closeStatusModal,
    refreshCoverage,
  } = useCoverageTable(requestId, contractStatus);

  const { showModal, openModal, close } = useCreateCoverageModal(
    requestId,
    refreshCoverage,
  );

  return (
    <SpinWrapper isLoading={isLoading}>
      {isBroker(counterpartyType) &&
        contractStatus === InsuranceContractType.Review && (
          <Row justify="end">
            <Button type="primary" onClick={openModal}>
              {t('add')}
            </Button>
          </Row>
        )}
      <Table
        pagination={false}
        dataSource={data}
        rowKey="Id"
        columns={columns}
      />
      {modalData && (
        <CoverageHistoryModal
          contract={modalData}
          closeModal={closeStatusModal}
          refresh={refreshCoverage}
        />
      )}
      <CreateCoverageModal
        refreshCoverage={refreshCoverage}
        visible={showModal}
        close={close}
      />
    </SpinWrapper>
  );
};
