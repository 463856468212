import React, { FC, ReactElement, useRef } from 'react';
import { Modal, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { finishFailed } from '../../utils/finishFailed';
import { getFormField } from '../TableForm';
import { useForm } from 'antd/es/form/Form';
import { useBem } from 'shared/hooks';
import { TableFormField } from '../../../types/tables';
import { Button } from 'shared/components';
import './AddingModal.sass';

type AddingModalProps = {
  close: () => void;
  visible: boolean;
  fields: TableFormField[];
  initialValues?: Record<string, any>;
  onSubmit: (record: any) => void;
};

export const AddingModal: FC<AddingModalProps> = ({
  visible,
  close,
  fields,
  initialValues,
  onSubmit,
}): ReactElement => {
  const editableFields = fields.filter(field => field.isEditable);
  const [form] = useForm();
  const bem = useBem('AddingModal');
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const mutableRecord: React.MutableRefObject<{}> = useRef({});

  const onSave = (field: string | number, value: any) => {
    form.setFieldsValue({ [field]: value });
  };

  const onFinish = (record: any) => {
    onSubmit({ ...record, ...mutableRecord.current });
    onClose();
  };

  const onClose = () => {
    close();
    form.resetFields();
  };

  return (
    <Modal
      className={bem()}
      visible={visible}
      onCancel={onClose}
      title={t('addingModal.title')}
    >
      <Form
        form={form}
        initialValues={initialValues}
        requiredMark={false}
        layout="inline"
        onFinish={onFinish}
        onFinishFailed={({ errorFields }) => {
          finishFailed(t, errorFields);
        }}
      >
        {editableFields.map(field => {
          return getFormField(
            field,
            onSave,
            mutableRecord.current,
            t,
            language,
          );
        })}
        <Row className={bem('container-buttons')}>
          <Button
            className={bem('button-reset-styles')}
            onClick={onClose}
            htmlType="reset"
            customType="cancel"
            type="primary"
            title={t('tableForm.buttons.cancelText')}
          />
          <Button
            className={bem('button-submit-styles')}
            type="primary"
            customType="send"
            htmlType="submit"
            title={t('add')}
          />
        </Row>
      </Form>
    </Modal>
  );
};
