import { useState, useCallback, useEffect } from 'react';

import { CompanyData } from 'types/company';
import { api } from 'api';
import { handleError } from 'shared/utils/validation';

const requestCompanyData = async (companyId?: string) => {
  try {
    const { data } = await api.get<CompanyData>(
      `/Counterparties/${companyId || 'profile'}`,
    );
    return data;
  } catch (e) {
    throw handleError(e);
  }
};

export const useAboutCompany = (companyId?: string) => {
  const [companyData, setCompanyData] = useState<CompanyData>();
  const [showEditingForm, setShowEditingForm] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const openEditingForm = () => setShowEditingForm(true);
  const closeEditingForm = () => setShowEditingForm(false);

  const refreshAboutCompanyPage = useCallback(() => {
    requestCompanyData(companyId).then(data => {
      setCompanyData(data);
      setIsFetching(false);
    });
  }, [companyId]);

  useEffect(() => {
    refreshAboutCompanyPage();
  }, [refreshAboutCompanyPage]);

  return {
    companyData,
    showEditingForm,
    isFetching,
    openEditingForm,
    closeEditingForm,
  };
};
