import React, { FC, ReactElement, useRef } from 'react';
import { Modal, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { finishFailed } from '../../utils/finishFailed';
import { getFormField } from '../TableForm';
import { useForm } from 'antd/es/form/Form';
import { useBem } from 'shared/hooks';
import { TableFormField } from '../../../types/tables';
import { Button } from 'shared/components';
import './EditingModal.sass';

type EditingModalProps = {
  close: () => void;
  visible: boolean;
  refresh: () => void;
  fields: TableFormField[];
  initialValues: Record<string, any> | undefined;
  onSubmit: (record: any) => void;
  onDelete: (id: number) => void;
};

export const EditingModal: FC<EditingModalProps> = ({
  visible,
  close,
  refresh,
  fields,
  initialValues,
  onSubmit,
  onDelete,
}): ReactElement => {
  const editableFields = fields.filter(field => field.isEditable);
  const [form] = useForm();
  const bem = useBem('EditingModal');
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const mutableRecord: React.MutableRefObject<{}> = useRef({});

  const onSave = (field: string | number, value: any) => {
    form.setFieldsValue({ [field]: value });
  };

  const onFinish = (record: any) => {
    onSubmit({ ...record, ...mutableRecord.current });
    onClose();
  };

  const onClose = () => {
    close();
    form.resetFields();
  };

  console.log({ fields, initialValues });

  return (
    <Modal
      className={bem()}
      visible={visible}
      onCancel={onClose}
      title="Редактирование"
    >
      <Form
        form={form}
        initialValues={initialValues}
        requiredMark={false}
        layout="inline"
        onFinish={onFinish}
        onFinishFailed={({ errorFields }) => {
          finishFailed(t, errorFields);
        }}
      >
        {fields.map(field => {
          console.log('FIELD', field);
          return getFormField(field, onSave, initialValues, t, language);
        })}
        <Row className={bem('container-buttons')}>
          <Button
            className="cancel-large"
            onClick={onClose}
            htmlType="reset"
            type="primary"
            title={t('tableForm.buttons.cancelText')}
          />
          <Button
            className="delete"
            onClick={onClose}
            danger
            title={t('routesPage.table.delete')}
          />
          <Button
            className="send-large"
            type="primary"
            htmlType="submit"
            title={t('tableForm.buttons.okText')}
          />
        </Row>
      </Form>
    </Modal>
  );
};
