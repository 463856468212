import React, { FC, useState } from 'react';
import { Select as AntSelect } from 'antd';
import cn from 'classnames';

import { useBem } from 'shared/hooks';
import { FloatingLabel } from 'shared/components/Input/InputFloatingLabel/FloatingLabel';

import './Select.sass';
import { isRussian } from 'shared/utils/isRussian';
import { Country } from 'types/company';
import { FieldNamesType } from 'antd/es/cascader';

interface SelectProps extends FieldNamesType {
  label: string;
  customType?: string;
  className?: string;
  list: Country[];
  formLanguage: string;
}

const { Option } = AntSelect;

export const SelectFloatingLabel: FC<SelectProps> = ({
  label,
  className,
  list,
  formLanguage,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const bem = useBem('UIKitSelect');

  return (
    <div
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      className={cn(bem(), `${className}`)}
    >
      <AntSelect showSearch optionFilterProp="children" {...props}>
        {list.map(({ Id, NameRu, NameEn }: Country) => (
          <Option key={Id} value={Id}>
            {isRussian(formLanguage) ? NameRu : NameEn}
          </Option>
        ))}
      </AntSelect>
      <FloatingLabel
        text={label || ''}
        small={focus || !!props.value}
        required
      />
    </div>
  );
};
