export enum RouteTypes {
  Local = 0,
  International = 1,
  Total = 3,
}

export type NewRoute = {
  Period: number;
  TypeId: 1 | 2;
  FlightsAmount: number;
};
