import { Dispatch, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

import { authUser } from 'store';
import { deleteCookie } from 'shared/utils/cookie';
import { useInsuranceContracts } from 'shared/hooks';
import { isAirline, isBroker } from 'shared/utils/checkUserRole';

export const useSidebar = () => {
  const [counterPartyType, setCounterpartyType] = useState<string>();
  const { contracts, resetInsuranceContracts } = useInsuranceContracts();
  const { SubMenu } = Menu;

  const onLogout = (dispatch: Dispatch<{ payload: boolean; type: string }>) => {
    localStorage.removeItem('CounterpartyType');
    dispatch(authUser(false));
    resetInsuranceContracts();
    deleteCookie('auth_token');
  };

  const renderMenuItem = useCallback(
    ({
      Id,
      StartDate,
      EndDate,
    }: {
      Id: number;
      StartDate: string;
      EndDate: string;
    }) => (
      <Menu.Item key={Id}>
        <Link to={`/request=${Id}`}>
          {`${moment(StartDate).format('DD.MM.YYYY')} - ${moment(
            EndDate,
          ).format('DD.MM.YYYY')}`}
        </Link>
      </Menu.Item>
    ),
    [],
  );

  const renderSubMenu = useCallback(
    (contractItem: any) => {
      if (contractItem) {
        if (isBroker(counterPartyType)) {
          return (
            <SubMenu
              key={contractItem.CounterpartyName}
              title={contractItem.CounterpartyName ?? '-'}
            >
              {contractItem.InsuranceContracts?.map(renderMenuItem)}
            </SubMenu>
          );
        }
        if (isAirline(counterPartyType)) {
          return contractItem.InsuranceContracts?.map(renderMenuItem);
        }
      }
    },
    [SubMenu, counterPartyType, renderMenuItem],
  );

  useEffect(() => {
    setTimeout(() => {
      const userType = localStorage.getItem('CounterpartyType');
      if (userType) {
        setCounterpartyType(userType);
      }
    }, 700);
  }, [contracts, renderSubMenu]);

  return {
    counterPartyType,
    onLogout,
    renderMenuItem: renderSubMenu,
  };
};
