import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { InsuranceContractType } from 'types/insuranceContracts';
import { CounterpartyType } from 'types/counterparties';
import { api } from 'api';
import { isNumber } from 'lodash-es';
import { isRussian } from 'shared/utils/isRussian';
import { isReinsurer } from 'shared/utils/checkUserRole';
import { handleError } from 'shared/utils/validation';

const requestNextContractStatus = async (id: string) => {
  try {
    const {
      data: { AvailableStatuses },
    } = await api.get<{ AvailableStatuses: number[] }>(
      `/InsuranceContracts/${id}/status/available`,
    );
    return AvailableStatuses;
  } catch (e) {
    handleError(e);
  }
};

export const useContractStatusSelect = (id: string, status: number) => {
  const [nextContractStatuses, setNextContractStatuses] = useState<number[]>();
  const [showChangingStatusModal, setShowChangingStatusModal] = useState<
    boolean
  >(false);
  const {
    i18n: { language },
  } = useTranslation();
  const isLoading = !nextContractStatuses;
  const userRole = Number(localStorage.getItem('CounterpartyType'));
  const contractStatusByRole: { [key: number]: number[] } = useMemo(
    () => ({
      [CounterpartyType.Broker]: [
        InsuranceContractType.Review,
        InsuranceContractType.DocumentCorrection,
      ],
      [CounterpartyType.Airline]: [
        InsuranceContractType.Blank,
        InsuranceContractType.Correction,
        InsuranceContractType.DocumentApproval,
      ],
    }),
    [],
  );
  const contractStatusTypeNames: Record<number, string> = useMemo(() => {
    return {
      [InsuranceContractType.Canceled]: isRussian(language)
        ? 'Отменен'
        : 'Canceled',
      [InsuranceContractType.Blank]: isRussian(language) ? 'Черновик' : 'Blank',
      [InsuranceContractType.Review]: isRussian(language)
        ? 'На рассмотрении'
        : 'Review',
      [InsuranceContractType.Correction]: isRussian(language)
        ? 'Корректировка сведений'
        : 'Correction',
      [InsuranceContractType.Agreed]: isRussian(language)
        ? 'Принято'
        : 'Agreed',
      [InsuranceContractType.DocumentApproval]: isRussian(language)
        ? 'Согласование документов'
        : 'Document approval',
      [InsuranceContractType.DocumentCorrection]: isRussian(language)
        ? 'Корректировка документов'
        : 'Document correction',
    };
  }, [language]);
  const insuranceContractStatus =
    isNumber(status) && contractStatusTypeNames[status];

  const refreshContractStatus = useCallback(() => {
    requestNextContractStatus(id).then(setNextContractStatuses);
  }, [id]);

  const open = () => setShowChangingStatusModal(true);
  const close = () => setShowChangingStatusModal(false);

  useEffect(() => {
    if (!isReinsurer(userRole)) {
      refreshContractStatus();
    }
  }, [id, refreshContractStatus, userRole]);

  return {
    userRole,
    insuranceContractStatus,
    contractStatusByRole,
    isLoading,
    visible: showChangingStatusModal,
    open,
    close,
  };
};
