import { notification } from 'antd';
import { InternalNamePath } from 'rc-field-form/lib/interface';

export const finishFailed = (
  t: (text: string) => string,
  errorFields: {
    name: InternalNamePath;
    errors: string[];
  }[],
) => {
  const errors = Array.from(
    new Set(
      errorFields?.map(({ errors }: { errors: string[] }) =>
        errors.join(', '),
      ) ?? 'Error',
    ),
  ).join('; ');
  notification.error({ message: errors ?? t('requiredFields') });
};
