export type TDocument = {
  Id: 0;
  InsuranceContractId: number;
  DocumentId: string;
  Name: string;
  Type: 0;
  SigningDate: string;
  LoadingDate: string;
  Agreed: boolean;
  Extension: string;
  Version: number;
  UpdateDate: string;
  CreateDate: string;
};

export type NewDocument = {
  LoadingDate: string;
  Type: number;
  Name: string;
  File: string;
};

export type UpdatedDocument = {
  SigningDate: string;
  Agreed: boolean;
  Type: number;
  Name: string;
  File: string;
};

export type TFile = {
  file: {
    originFileObj: File;
  };
};

export enum DocumentType {
  CoverNote = 0,
  DebetNote = 1,
  InsuranceContract = 2,
  Other = 3,
}
