import React, { useEffect, useState } from 'react';
import { SpinWrapper } from 'shared/components';
import { useGeneralDetailsForm } from './useGeneralDetailsForm';
import { Button, Form, InputNumber, Radio, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import Title from 'antd/es/typography/Title';
import {
  InsuranceContract,
  InsuranceContractUpdateRequest,
  InsurerFranchiseType,
} from 'types/insuranceContracts';
import { useBem, useCountries } from 'shared/hooks';
import './GeneralDetailsForm.sass';
import { useForm } from 'antd/es/form/Form';
import { isRussian } from 'shared/utils/isRussian';

const formItemLayout = {};

type GeneralDetailsFormProps = {
  closeForm: () => void;
  refreshContract: () => void;
  initialData?: InsuranceContract;
  id: string;
};

export const GeneralDetailsForm: React.FC<GeneralDetailsFormProps> = ({
  closeForm,
  refreshContract,
  initialData,
  id,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const bem = useBem('GeneralDetailsForm');
  const { countryList } = useCountries();
  const { isLoading, insurerList, onSubmit } = useGeneralDetailsForm(
    id,
    refreshContract,
  );
  const [form] = useForm<InsuranceContractUpdateRequest>();

  const [franchiseEditable, setFranchiseEditable] = useState<boolean>(false);

  const onFinish = (contract: InsuranceContractUpdateRequest) => {
    onSubmit(contract).then(closeForm);
  };

  useEffect(() => {
    onValuesChange(initialData?.FranchiseType);
  }, [initialData?.FranchiseType]);

  const onValuesChange = (franchiseType?: number) => {
    if (franchiseType === InsurerFranchiseType.Absent) {
      setFranchiseEditable(false);
    } else {
      setFranchiseEditable(true);
    }
  };

  return (
    <SpinWrapper isLoading={isLoading}>
      <Form
        wrapperCol={{ span: 10 }}
        form={form}
        onFinish={onFinish}
        initialValues={initialData}
      >
        <Title level={4}>
          {t('contractGeneralDetailsForm.insurerCounterparty')}
        </Title>
        <Form.Item
          {...formItemLayout}
          name="InsurerCounterpartyId"
          rules={[
            {
              required: true,
              message: t('contractGeneralDetailsForm.errors.insurer'),
            },
          ]}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label as string)
                ?.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            options={insurerList?.map(insurer => ({
              label: isRussian(language) ? insurer.NameRu : insurer.NameEn,
              value: insurer.Id,
            }))}
          />
        </Form.Item>
        <Title level={4}>
          {t('generalInformation.fieldText.insuranceTerritory')}
        </Title>
        <Form.Item
          name="CountryIds"
          rules={[
            {
              required: true,
              message: t(
                'contractGeneralDetailsForm.errors.insuranceTerritory',
              ),
            },
          ]}
        >
          <Select
            mode="multiple"
            optionFilterProp="label"
            options={countryList?.map(country => ({
              label: isRussian(language) ? country.NameRu : country.NameEn,
              value: country.Id,
            }))}
          />
        </Form.Item>
        <Title level={4}>{t('contractGeneralDetailsForm.casco')}</Title>
        <Form.Item
          name="MaxVal"
          label={t('contractGeneralDetailsForm.maxValue')}
          rules={[{ required: true }]}
        >
          <InputNumber
            min={0}
            addonAfter="$"
            className={bem('input-number')}
            precision={2}
          />
        </Form.Item>
        <Form.Item
          name="OnboardkitVal"
          label={t('contractGeneralDetailsForm.onboardKitValue')}
          rules={[{ required: true }]}
        >
          <InputNumber
            min={0}
            addonAfter="$"
            className={bem('input-number')}
            precision={2}
          />
        </Form.Item>
        <Form.Item
          name="ToolkitVal"
          label={t('contractGeneralDetailsForm.toolkitValue')}
          rules={[{ required: true }]}
        >
          <InputNumber
            min={0}
            addonAfter="$"
            className={bem('input-number')}
            precision={2}
          />
        </Form.Item>
        <Title level={4}>
          {t('contractGeneralDetailsForm.responsibility')}
        </Title>
        <Form.Item
          name="PersonalInjuryVal"
          label={t('contractGeneralDetailsForm.personalInjury')}
          rules={[{ required: true }]}
        >
          <InputNumber
            min={0}
            addonAfter="$"
            className={bem('input-number')}
            precision={2}
          />
        </Form.Item>
        <Title level={4}>{t('contractGeneralDetailsForm.franchise')}</Title>
        <Form.Item
          name="FranchiseType"
          label={t('contractGeneralDetailsForm.franchiseType')}
          rules={[{ required: true }]}
        >
          <Radio.Group onChange={event => onValuesChange(event.target.value)}>
            <Space direction="vertical">
              <Radio value={InsurerFranchiseType.Absent}>
                {t('franchise.absent')}
              </Radio>
              <Radio value={InsurerFranchiseType.Conditional}>
                {t('franchise.conditional')}
              </Radio>
              <Radio value={InsurerFranchiseType.Unconditional}>
                {t('franchise.unconditional')}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {franchiseEditable && (
          <>
            <Form.Item
              name="FranchisePlaneVal"
              label={t('contractGeneralDetailsForm.aircraftValue')}
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                precision={2}
                defaultValue={0}
                addonAfter="$"
                className={bem('input-number')}
              />
            </Form.Item>
            <Form.Item
              name="FranchiseOnboardkitVal"
              label={t('contractGeneralDetailsForm.onBoardKitValue')}
              rules={[{ required: true }]}
            >
              <InputNumber
                precision={2}
                min={0}
                defaultValue={0}
                addonAfter="$"
                className={bem('input-number')}
              />
            </Form.Item>
            <Form.Item
              name="FranchiseToolkitVal"
              label={t(
                'contractGeneralDetailsForm.sparePartsAndEquipmentValue',
              )}
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                defaultValue={0}
                precision={2}
                addonAfter="$"
                className={bem('input-number')}
              />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" className={bem('button')}>
            {t('contractGeneralDetailsForm.buttons.okText')}
          </Button>
          <Button onClick={closeForm} className={bem('button')}>
            {t('contractGeneralDetailsForm.buttons.cancelText')}
          </Button>
        </Form.Item>
      </Form>
    </SpinWrapper>
  );
};
