import React, { useRef } from 'react';
import { useForm } from 'antd/es/form/Form';
import { Button, Form, Space } from 'antd';
import { getFormField } from './utils';
import { useBem } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import './TableForm.sass';
import { finishFailed } from 'shared/utils/finishFailed';
import { TableFormField } from 'types/tables';

type TableFormProps = {
  fields: TableFormField[];
  initialValues?: Record<string, any>;
  closeForm: () => void;
  onSubmit: (record: any) => void;
};

export const TableForm: React.FC<TableFormProps> = ({
  fields,
  initialValues,
  closeForm,
  onSubmit,
}) => {
  const editableFields = fields.filter(field => field.isEditable);
  const [form] = useForm();
  const bem = useBem('TableForm');
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const mutableRecord = useRef({});

  const onSave = (field: string | number, value: any) => {
    form.setFieldsValue({ [field]: value });
  };

  const onFinish = (record: any) => {
    onSubmit({ ...record, ...mutableRecord.current });
    closeForm();
  };

  return (
    <Form
      className={bem()}
      form={form}
      initialValues={initialValues}
      layout="inline"
      onFinish={onFinish}
      onFinishFailed={({ errorFields }) => {
        finishFailed(t, errorFields);
      }}
    >
      {editableFields.map(field => {
        return getFormField(field, onSave, mutableRecord.current, t, language);
      })}
      <Space>
        <Button type="primary" htmlType="submit" className={bem('button')}>
          {t('tableForm.buttons.okText')}
        </Button>
        <Button onClick={closeForm} className={bem('button')}>
          {t('tableForm.buttons.cancelText')}
        </Button>
      </Space>
    </Form>
  );
};
