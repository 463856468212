import { ReinsuranceContractDto } from 'types/insuranceContracts';
import {
  getFetchedRequestState,
  getFetchingRequestState,
  getInitialRequestState,
  MergeRequestState,
  ResponseApiError,
} from 'store/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ReduxState } from 'store/store';
import { api } from 'api';

export interface ReinsuranceContractsState
  extends MergeRequestState<ReinsuranceContractDto[], ResponseApiError> {}

const selector = {
  state: (state: ReduxState) => state.reinsuranceContracts,
  isFetching: (state: ReduxState) => state.reinsuranceContracts.isFetching,
  data: (state: ReduxState) => state.reinsuranceContracts.data,
  error: (state: ReduxState) => state.reinsuranceContracts.error,
};

const initialState: ReinsuranceContractsState = {
  ...getInitialRequestState(),
  error: null,
  data: null,
};

const SLICE_NAME = `reinsuranceContracts`;

const requestThunk = createAsyncThunk(
  `${SLICE_NAME}/request`,
  async (params: void, { rejectWithValue }) => {
    try {
      const { data } = await api.get<ReinsuranceContractDto[]>(
        '/ReinsuranceContracts',
      );

      return data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  },
  {
    condition: (payload, { getState }) =>
      !selector.isFetching(getState() as ReduxState),
  },
);

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(requestThunk.pending, state => ({
        ...state,
        ...getFetchingRequestState(),
      }))
      .addCase(requestThunk.fulfilled, (state, action) => ({
        ...state,
        ...getFetchedRequestState(),
        data: action.payload as ReinsuranceContractDto[],
        error: null,
      }))
      .addCase(requestThunk.rejected, (state, action) => ({
        ...state,
        ...initialState,
        error: action.payload as ResponseApiError,
      }));
  },
});

interface ReinsuranceContracts {
  action: typeof actions;
  thunk: {
    request: typeof requestThunk;
  };
  reducer: typeof reducer;
  selector: typeof selector;
}

export const reinsuranceContracts: ReinsuranceContracts = {
  action: actions,
  thunk: {
    request: requestThunk,
  },
  reducer,
  selector,
};
