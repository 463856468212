import React from 'react';
import { Spin } from 'antd';
import { useBem } from '../../hooks';
import './SpinWrapper.sass';
import { SpinSize } from 'antd/lib/spin';

type SpinWrapperProps = {
  isLoading: boolean;
  size?: SpinSize;
};

export const SpinWrapper: React.FC<SpinWrapperProps> = ({
  children,
  isLoading,
  size = 'large',
}) => {
  const bem = useBem('SpinWrapper');

  return isLoading ? (
    <div className={bem()}>
      <Spin size={size} />
    </div>
  ) : (
    <>{children}</>
  );
};
