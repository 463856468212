import { isNumber } from 'lodash-es';
import {
  InsuranceContractType,
  ReinsuranceContractStatus,
} from 'types/insuranceContracts';
import { CounterpartyType } from 'types/counterparties';

export const isBroker = (counterpartyType?: string | null) => {
  return counterpartyType === CounterpartyType.Broker;
};

export const isAirline = (counterpartyType?: string | null) => {
  return counterpartyType === CounterpartyType.Airline;
};

export const isReinsurer = (counterpartyType?: string | number | null) => {
  return String(counterpartyType) === CounterpartyType.Reinsurer;
};

export const canAirlineEdit = (
  counterpartyType: string | null,
  contractStatus?: number,
) => {
  if (!contractStatus || !isAirline(counterpartyType)) {
    return false;
  }

  return [
    InsuranceContractType.Blank,
    InsuranceContractType.Correction,
  ].includes(contractStatus);
};

export const canBrokerEdit = (
  counterpartyType: string | null,
  contractStatus?: number,
) => {
  if (!isNumber(contractStatus) || !isBroker(counterpartyType)) {
    return false;
  }

  return [
    InsuranceContractType.Review,
    InsuranceContractType.DocumentCorrection,
  ].includes(contractStatus);
};

export const canReinsurerEdit = (
  counterpartyType: string | null,
  contractStatus?: number,
) => {
  if (!contractStatus || !isReinsurer(counterpartyType)) {
    return false;
  }

  return [
    ReinsuranceContractStatus.Withdrawn,
    ReinsuranceContractStatus.Agreed,
    ReinsuranceContractStatus.Correction,
    ReinsuranceContractStatus.Refused,
  ].includes(contractStatus);
};
