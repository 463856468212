import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';

import { Plane } from 'types/planes';
import { formatMoney } from 'shared/utils/formatMoney';
import { api } from 'api';
import { handleError } from 'shared/utils/validation';
import { useSearch } from 'shared/hooks/useSearch';
import { PROFILE_PLANES } from 'api/endpoints';
import { Params } from 'types/common';
import { ResponseDataWithPagination } from 'types/requests';

type Statistic = {
  PlaneCount: number;
  PlaceCount: number;
};

const getAircraftStatistic = async () => {
  try {
    const { data } = await api.get('/Profile/planes/statistic');
    return data;
  } catch (e) {
    handleError(e);
  }
};

const getAircraftData = async () => {
  try {
    const { data } = await api.get<ResponseDataWithPagination<Plane[]>>(
      '/Profile/planes/paginated',
    );
    return data;
  } catch (e) {
    handleError(e);
  }
};

const createAircraft = async (createdAircraft: Plane) => {
  try {
    await api.post('/Profile/planes', createdAircraft);
  } catch (e) {
    handleError(e);
  }
};

const deleteAircraft = async (id: number) => {
  try {
    await api.delete(`/Planes/${id}`);
  } catch (e) {
    handleError(e);
  }
};

export const useAircraftTable = () => {
  const [statistic, setStatistic] = useState<Statistic>();
  const [aircraftData, setAircraftData] = useState<
    ResponseDataWithPagination<Plane[]>
  >();
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const { page } = useParams<Params>();
  const { t } = useTranslation();

  const refresh = useCallback((config?: {}) => {
    Promise.all([getAircraftData(), getAircraftStatistic()])
      .then(response => {
        const [data, statistic] = response;
        setStatistic(statistic);
        setAircraftData(data);
      })
      .finally(() => setIsFetching(false));
  }, []);

  const onSubmit = useCallback(
    (createdAircraft: Plane) => {
      createAircraft(createdAircraft).then(() => refresh({ page }));
    },
    [page, refresh],
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  const columns = useMemo(
    () => [
      {
        title: t('flotPage.flotTable.model'),
        dataIndex: 'TypeId',
        key: 'TypeId',
        width: '15%',
        selectType: 'search',
        typeCell: 'select',
        isRequired: true,
        editable: true,
        sorter: true,
        render: (TypeId: number, { TypeName }: Plane) => {
          return <div>{TypeName}</div>;
        },
      },
      {
        title: t('flotPage.flotTable.tailNumber'),
        dataIndex: 'RegisterNumber',
        key: 'RegisterNumber',
        width: '10%',
        maxLength: 20,
        isRequired: true,
        editable: true,
        sorter: true,
      },
      {
        title: t('flotPage.flotTable.factoryNumber'),
        dataIndex: 'FactoryNumber',
        key: 'FactoryNumber',
        width: '10%',
        typeCell: 'inputNumber',
        maxLength: 9,
        isRequired: true,
        editable: true,
        sorter: true,
      },
      {
        title: t('flotPage.flotTable.deliveryDate'),
        dataIndex: 'DateOfDelivery',
        key: 'date',
        typeCell: 'datePicker',
        width: '10%',
        isRequired: true,
        editable: true,
        sorter: true,
        render: (DateOfDelivery: string) =>
          DateOfDelivery ? moment(DateOfDelivery).format('DD.MM.YYYY') : '-',
      },
      {
        title: t('flotPage.flotTable.writeOffDate'),
        dataIndex: 'DateOfDisposal',
        key: 'dateOfDisposal',
        typeCell: 'dateOfDisposal',
        width: '10%',
        editable: true,
        sorter: true,
        render: (DateOfDisposal: string) => {
          return DateOfDisposal
            ? moment(DateOfDisposal).format('DD.MM.YYYY')
            : '-';
        },
      },
      {
        title: t('flotPage.flotTable.places'),
        dataIndex: 'PlacesAmount',
        key: 'PlacesAmount',
        typeCell: 'inputNumber',
        maxLength: 9,
        minNumCount: 0,
        width: '10%',
        isRequired: true,
        editable: true,
        sorter: true,
      },
      {
        title: t('flotPage.flotTable.combinedLimit'),
        dataIndex: 'CombineLimit',
        key: 'CombineLimit',
        typeCell: 'inputNumber',
        maxLength: 13,
        width: '10%',
        isDecimal: true,
        minNumCount: 0,
        isRequired: true,
        editable: true,
        sorter: true,
        render: (CombineLimit: number) =>
          CombineLimit ? formatMoney(CombineLimit) : '-',
      },
    ],
    [t],
  );

  return {
    statistic,
    aircraftData,
    isFetching,
    columns,
    refresh,
    onSubmit,
  };
};
