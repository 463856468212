import { DatePicker, Form } from 'antd';
import moment from 'moment';
import {
  CustomSelect,
  CustomMultiselect,
  DebounceSelect,
  Input,
} from 'shared/components';
import { getPointList } from 'shared/utils/getPointList';
import { quarterString } from 'shared/utils/quarterString';
import { isPositiveNumber } from 'shared/utils/validation';
import { TableFormField } from 'types/tables';
import { InputNumber } from '../InputNumber/InputNumber';

export const columnsToFormFields = (column: any): TableFormField => ({
  title: column.title,
  dataIndex: column.dataIndex,
  type: column.typeCell,
  isEditable: column.editable,
  render: column.render,
  minNumCount: column.minNumCount,
  maxNumCount: column.maxNumCount,
  maxLength: column.maxLength,
  selectType: column.selectType,
  multiType: column.multiType,
  isRequired: column.isRequired,
  isDecimal: column.isDecimal,
});

export const isNeedToPrepare = (values: any) => {
  const keyList = Object.keys(values);
  for (let i = 0; i < keyList.length; i++) {
    if (
      [
        'CountryNameRu',
        'CountryNameEn',
        'PeriodicyNameRu',
        'PeriodicyNameEn',
      ].includes(keyList[i])
    ) {
      return true;
    }
  }
  return false;
};

export const preparedData = (values: any) => {
  return {
    ...values,
    CountryId: values.CountryNameRu || values.CountryNameEn,
    PeriodicyId: values.PeriodicyNameRu || values.PeriodicyNameEn,
  };
};

export const getFormField = (
  field: TableFormField,
  onSave: (field: string | number, value: any) => void,
  record: any,
  t: (key: string) => string,
  language: string,
) => {
  console.log({ field, record });
  switch (field.type) {
    case 'searchSelect':
      return (
        <Form.Item
          className="inline-form-item"
          label={field.title}
          key={field.dataIndex}
          name={field.dataIndex}
          rules={[{ required: field.isRequired }]}
        >
          <DebounceSelect
            showSearch
            autoFocus
            placeholder={t('selectPoint')}
            fetchOptions={getPointList}
            onChange={({ value }: { value: number }) => {
              onSave(value, field.dataIndex);
            }}
          />
        </Form.Item>
      );
    case 'select':
      return (
        <Form.Item
          className="inline-form-item"
          label={field.title}
          key={field.dataIndex}
          name={field.dataIndex}
          rules={[{ required: field.isRequired }]}
        >
          <CustomSelect
            record={record}
            dataIndex={field.dataIndex}
            type={field.selectType}
            onSave={(value: any, field: string) => onSave(field, value)}
          />
        </Form.Item>
      );
    case 'multiSelect':
      return (
        <Form.Item
          className="inline-form-item"
          label={field.title}
          key={field.dataIndex}
          name={field.dataIndex}
          rules={[{ required: field.isRequired }]}
        >
          <CustomMultiselect
            key={field.dataIndex}
            record={record}
            dataIndex={field.dataIndex}
            save={(value: any, field: string) => onSave(field, value)}
            multiType={field.multiType as string}
            adding
          />
        </Form.Item>
      );
    case 'dateOfDisposal':
      return (
        <Form.Item
          key={field.dataIndex}
          className="inline-form-item"
          name={field.dataIndex}
          label={field.title}
          rules={[{ required: field.isRequired }]}
        >
          <DatePicker
            autoFocus
            allowClear
            inputReadOnly
            format="DD.MM.YYYY"
            onChange={(value: any) => {
              onSave(field.dataIndex, moment(value));
              record[field.dataIndex] = moment(value).format('YYYY-MM-DD');
            }}
          />
        </Form.Item>
      );
    case 'quarter':
      return (
        <Form.Item
          key={field.dataIndex}
          className="inline-form-item"
          name={field.dataIndex}
          label={field.title}
          rules={[
            {
              required: field.isRequired,
            },
          ]}
        >
          <DatePicker
            picker="quarter"
            autoFocus
            allowClear={false}
            format={(date: any) =>
              quarterString(
                moment(date).quarter(),
                moment(date).year(),
                language,
              )
            }
            onChange={(value: any) => {
              onSave(field.dataIndex, moment(value));
              record['Quarter'] = moment(value).quarter();
              record['Year'] = moment(value).year();
            }}
          />
        </Form.Item>
      );
    case 'datePicker':
      return (
        <Form.Item
          key={field.dataIndex}
          name={field.dataIndex}
          className="inline-form-item"
          label={field.title}
          rules={[
            {
              required: field.isRequired,
            },
          ]}
        >
          <DatePicker
            autoFocus
            allowClear={false}
            inputReadOnly
            format="DD.MM.YYYY"
            onChange={(value: any) => {
              onSave(field.dataIndex, moment(value));
              record[field.dataIndex] = moment(value).format('YYYY-MM-DD');
            }}
          />
        </Form.Item>
      );
    case 'inputNumber':
      return (
        <Form.Item
          key={field.dataIndex}
          className="inline-form-item"
          name={field.dataIndex}
          label={field.title}
          rules={[
            {
              required: field.isRequired,
              validator: (rule, value, callback) => {
                isPositiveNumber(t, rule, value, callback);
              },
            },
          ]}
        >
          <InputNumber
            max={field.maxNumCount}
            className="input"
            controls={false}
            maxLength={field.maxLength}
            stringMode
            {...(field.isDecimal && { precision: 2 })}
          />
        </Form.Item>
      );
    default:
      return (
        <Form.Item
          key={field.dataIndex}
          className="inline-form-item"
          name={field.dataIndex}
          label={field.title}
          rules={[
            {
              required: field.isRequired,
            },
          ]}
        >
          <Input maxLength={field.maxLength} className="input" />
        </Form.Item>
      );
  }
};
