import { DocumentType } from 'types/InsuredDocuments';
import { isRussian } from 'shared/utils/isRussian';

const documentTypeEn: Record<DocumentType, string> = {
  [DocumentType.CoverNote]: 'Cover Note',
  [DocumentType.DebetNote]: 'Debet Note',
  [DocumentType.InsuranceContract]: 'Insurance Contract',
  [DocumentType.Other]: 'Other',
};

const documentTypeRu: Record<DocumentType, string> = {
  [DocumentType.CoverNote]: 'Ковер нота',
  [DocumentType.DebetNote]: 'Дебет нота',
  [DocumentType.InsuranceContract]: 'Договор страхования',
  [DocumentType.Other]: 'Иное',
};

export const getDocumentTypeTranslated = (
  documentType: DocumentType,
  lang: string,
) => (isRussian(lang) ? documentTypeRu : documentTypeEn)[documentType];
