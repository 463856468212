import { FC, ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'antd';

import { Params } from 'types/common';
import { useFlotTable } from './useFlotTable';
import { EditableTable, SearchInput } from 'shared/components';
import { useSearch } from 'shared/hooks/useSearch';
import { Plane } from 'types/planes';

export const FlotTable: FC<{
  contractStatus: number | undefined;
}> = ({ contractStatus }): ReactElement => {
  const { requestId } = useParams<Params>();
  const url = `/InsuranceContracts/${requestId}/fleet/planes/paginated`;
  const { data: flotData, refreshSearch, isFetching } = useSearch(url);
  const { columns, onFlotSave } = useFlotTable(
    refreshSearch,
    requestId,
    contractStatus,
  );
  const dataSource = useMemo(() => {
    return isFetching ? [] : flotData?.Items;
  }, [flotData, isFetching]);

  return (
    <EditableTable
      loading={isFetching}
      onSave={onFlotSave}
      dataSourceProps={dataSource as Plane[]}
      columns={columns}
      afterSave={refreshSearch}
      totalItems={flotData?.TotalItems}
    />
  );
};
