import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Auth = {
  Name: string;
  Email: string;
  isAuth?: boolean;
};

const initialState: Auth = {
  Name: '',
  Email: '',
  isAuth: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authUser(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload;
    },
    setUserData(state, action: PayloadAction<Auth>) {
      state = { ...state, ...action.payload };
    },
  },
});

export const { authUser, setUserData } = authSlice.actions;
export const authReducer = authSlice.reducer;
