import moment from 'moment';
import { CombineLimit } from 'types/generalDetails';
import { formatMoney } from './formatMoney';
import { pForm } from './pForm';

export const formatDate = (startDate?: string, endDate?: string): string =>
  `${moment(startDate).format('DD.MM.YYYY')} - ${moment(endDate).format(
    'DD.MM.YYYY',
  )}`;

export const formatCombineLimits = (
  combineLimits: CombineLimit[],
  t: (text: string) => string,
): string => {
  return combineLimits?.length
    ? combineLimits
        ?.map(({ PlanesAmount, Sum }) => {
          const suffix = t(`planeCount_${pForm(PlanesAmount)}`);
          return `${formatMoney(Sum)} ${t('for')} ${PlanesAmount} ${suffix}`;
        })
        .join(', ')
    : '0 $';
};
