import React, { ReactElement } from 'react';
import {
  Input as AntInput,
  InputNumber as AntInputNumber,
  InputProps,
} from 'antd';
import { useBem } from 'shared/hooks';

import './Input.sass';
import { InputNumberProps } from 'antd/lib/input-number';

type CustomInputProps = (InputProps | InputNumberProps) & {
  customType?: string;
};

export const Input: React.FC<CustomInputProps> = ({
  customType,
  ...props
}: CustomInputProps) => {
  const bem = useBem('Input');

  switch (customType) {
    case 'password':
      return <AntInput.Password className={bem()} {...(props as InputProps)} />;

    case 'number':
      return (
        <AntInputNumber className={bem()} {...(props as InputNumberProps)} />
      );

    default:
      return <AntInput className={bem()} {...(props as InputProps)} />;
  }
};
