import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { Col, Form, Row, Spin } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { InputFloatingLabel } from 'shared/components/Input';
import { PhoneInputFloatingLabel } from 'shared/components/Input';
import { isUndefined } from 'lodash-es';

import { api } from 'api';
import { setFormFields } from 'shared/utils/setFormFields';
import { CompanyData, Country } from 'types/company';
import 'react-phone-number-input/style.css';
import { isRussian } from 'shared/utils/isRussian';
import { useBem } from 'shared/hooks';
import { Button } from 'shared/components';
import { SelectFloatingLabel } from './components/Select';
import { BankDetail } from './components/BankDetail';

import './EditingForm.sass';
import 'shared/styles/common.sass';

type EditingFormProps = {
  changeLanguage: () => void;
  formLanguage: string;
  onFinish: (values: CompanyData, isAddressEqual?: boolean) => void;
  close: () => void;
  editableData: any;
};

export const EditingForm: FC<EditingFormProps> = ({
  changeLanguage,
  formLanguage,
  onFinish,
  close,
  editableData,
}): ReactElement => {
  const [countriesList, setCountriesList] = useState<Country[]>();
  const [isAddressEqual, setIsAddressEqual] = useState<boolean>();
  const [form] = Form.useForm();
  const bem = useBem('EditingForm');
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    if (editableData) {
      Object.keys(editableData).forEach(key => {
        form.setFieldsValue({ [key]: editableData[key] });
      });
    }
  }, [editableData, form]);

  useEffect(() => {
    if (editableData) {
      setIsAddressEqual(editableData.IsAddressEqual);
      const formData = form.getFieldsValue();
      setFormFields(form, editableData, formData);
    }
  }, [editableData, form, language]);

  useEffect(() => {
    api.get<Country[]>('/Countries').then(({ data }) => setCountriesList(data));
  }, []);

  const onChangeCheckbox = useCallback(({ target: { checked } }) => {
    setIsAddressEqual(checked);
  }, []);

  if (
    !editableData ||
    isUndefined(editableData?.IsAddressEqual) ||
    !countriesList?.length
  ) {
    return <Spin />;
  }

  return (
    <Form
      form={form}
      className={bem()}
      requiredMark={false}
      onFinish={values => onFinish(values, isAddressEqual)}
      layout="vertical"
    >
      <Form.Item
        name={isRussian(formLanguage) ? 'fullNameRu' : 'fullNameEn'}
        rules={[{ required: true, message: t('editingCompanyInfo.fullName') }]}
      >
        <InputFloatingLabel label={t('detailedInfoContent.fullName')} />
      </Form.Item>
      <h1 className={bem('title')}>Общая информация</h1>
      <Row justify="space-between">
        <Col span={12}>
          <Form.Item
            name="legalCountry"
            rules={[
              { required: true, message: t('editingCompanyInfo.country') },
            ]}
          >
            <SelectFloatingLabel
              label={t('detailedInfoContent.country')}
              list={countriesList}
              formLanguage={formLanguage}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={isRussian(formLanguage) ? 'legalCityRu' : 'legalCityEn'}
            rules={[
              {
                required: true,
                message: t('editingCompanyInfo.city'),
              },
            ]}
          >
            <InputFloatingLabel
              className={bem('input', ['country'])}
              rc-virtual-list
              label={t('detailedInfoContent.city')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={12}>
          <Form.Item
            name={isRussian(formLanguage) ? 'legalStreetRu' : 'legalStreetEn'}
            rules={[
              { required: true, message: t('editingCompanyInfo.street') },
            ]}
          >
            <InputFloatingLabel
              className={bem('input')}
              label={t('detailedInfoContent.street')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Row className={bem('row-container')} justify="space-between">
            <div className={bem('container-building')}>
              <Form.Item
                name={
                  isRussian(formLanguage)
                    ? 'legalBuildingRu'
                    : 'legalBuildingEn'
                }
                rules={[
                  { required: true, message: t('editingCompanyInfo.building') },
                ]}
              >
                <InputFloatingLabel label={t('detailedInfoContent.building')} />
              </Form.Item>
            </div>
            <div className={bem('container-index')}>
              <Form.Item
                name="legalIndex"
                rules={[
                  { required: true, message: t('editingCompanyInfo.index') },
                ]}
              >
                <InputFloatingLabel
                  customType="number"
                  className={bem('input')}
                  label={t('detailedInfoContent.index')}
                  controls={false}
                  maxLength={9}
                  min={0}
                  stringMode
                />
              </Form.Item>
            </div>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={12}>
          <Form.Item
            name="kpp"
            rules={[{ required: true, message: t('editingCompanyInfo.kpp') }]}
          >
            <InputFloatingLabel
              customType="number"
              className={bem('input')}
              label={t('detailedInfoContent.kpp')}
              controls={false}
              maxLength={9}
              min={0}
              stringMode
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="inn"
            rules={[{ required: true, message: t('editingCompanyInfo.inn') }]}
          >
            <InputFloatingLabel
              customType="number"
              className={bem('input', ['margin-left'])}
              label={t('detailedInfoContent.inn')}
              controls={false}
              maxLength={12}
              min={0}
              stringMode
            />
          </Form.Item>
        </Col>
      </Row>
      <h2 className={bem('title')}>{t('detailedInfoContent.bankDetails')}:</h2>
      <Row justify="space-between">
        <BankDetail
          name={isRussian(formLanguage) ? 'bankNameRu' : 'bankNameEn'}
          className={bem('input')}
          message={t('editingCompanyInfo.bankName')}
          label={t('detailedInfoContent.bankName')}
          maxLength={20}
          typeNumber
        />
        <BankDetail
          name="checkingAccount"
          className={bem('input', ['margin-left'])}
          message={t('editingCompanyInfo.checkingAccount')}
          label={t('detailedInfoContent.checkingAccount')}
          maxLength={20}
          typeNumber
        />
      </Row>
      <Row justify="space-between">
        <BankDetail
          name="correspondentAccount"
          className={bem('input')}
          message={t('editingCompanyInfo.correspondentAccount')}
          label={t('detailedInfoContent.correspondentAccount')}
          maxLength={20}
          typeNumber
        />
        <BankDetail
          name="bic"
          className={bem('input', ['margin-left'])}
          message={t('editingCompanyInfo.bic')}
          label={t('detailedInfoContent.bic')}
          maxLength={9}
          typeNumber
        />
      </Row>
      <h3 className={bem('title')}>
        {t('detailedInfoContent.bankDetailsInternational')}:
      </h3>
      <Row justify="space-between">
        <BankDetail
          name="AccountNameEn"
          className={bem('input')}
          message={t('editingCompanyInfo.accountName')}
          label={t('detailedInfoContent.accountName')}
        />
        <BankDetail
          name="AccountNumber"
          className={bem('input', ['margin-left'])}
          message={t('editingCompanyInfo.accountNumber')}
          label={t('detailedInfoContent.accountNumber')}
        />
      </Row>
      <Row justify="space-between">
        <BankDetail
          name="Iban"
          className={bem('input')}
          message={t('editingCompanyInfo.iban')}
          label={t('detailedInfoContent.iban')}
        />
        <BankDetail
          name="Aba"
          className={bem('input', ['margin-left'])}
          message={t('editingCompanyInfo.aba')}
          label={t('detailedInfoContent.aba')}
        />
      </Row>
      <Row justify="space-between">
        <BankDetail
          name={isRussian(formLanguage) ? 'bankNameRu' : 'bankNameEn'}
          className={bem('input')}
          message={t('editingCompanyInfo.bankName')}
          label={t('detailedInfoContent.bankName')}
        />
        <BankDetail
          name="BankAddressEn"
          className={bem('input', ['margin-left'])}
          message={t('editingCompanyInfo.bankAddress')}
          label={t('detailedInfoContent.bankAddress')}
        />
      </Row>
      <Row justify="space-between">
        <BankDetail
          name="UsdCorrespondentBank"
          className={bem('input')}
          message={t('editingCompanyInfo.usdCorrespondentBank')}
          label={t('detailedInfoContent.usdCorrespondentBank')}
        />
        <BankDetail
          name="SwiftCode"
          className={bem('input', ['margin-left'])}
          message={t('editingCompanyInfo.swiftCode')}
          label={t('detailedInfoContent.swiftCode')}
        />
      </Row>
      <Row justify="space-between">
        <BankDetail
          name="AbaFedwire"
          className={bem('input')}
          message={t('editingCompanyInfo.abaFedwire')}
          label={t('detailedInfoContent.abaFedwire')}
        />
        <BankDetail
          name="Reference"
          className={bem('input', ['margin-left'])}
          message={t('editingCompanyInfo.reference')}
          label={t('detailedInfoContent.reference')}
        />
      </Row>
      <h4 className={bem('title')}>
        {t('detailedInfoContent.contactDetails')}:
      </h4>
      <Row justify="space-between">
        <Col span={12}>
          <Form.Item
            name="Email"
            rules={[{ required: true, message: t('editingCompanyInfo.email') }]}
          >
            <InputFloatingLabel
              type="email"
              label={t('detailedInfoContent.email')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="Phone">
            <PhoneInputFloatingLabel
              label={t('detailedInfoContent.phoneNumber')}
              value={form.getFieldValue('Phone')}
              onChange={(value: string) =>
                form.setFieldsValue({ Phone: value })
              }
              containerClassName={bem('input', ['margin-left'])}
              inputClassName="phone-input"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Button
          htmlType="reset"
          customType="cancel"
          type="primary"
          className={bem('button-reset-styles')}
          title={t('editingModal.buttons.cancelText')}
          onClick={close}
        />
        <Button
          className={bem('button-submit-styles')}
          customType="send"
          htmlType={!!editableData ? 'submit' : 'button'}
          title={t('editingModal.buttons.okText')}
          type="primary"
        />
      </Row>
    </Form>
  );
};
