import React, { useEffect, useState } from 'react';
import { Button, Form, InputNumber, Modal, Select, Table, Upload } from 'antd';
import { useCoverageHistoryModal } from 'pages/InsurancePage/components/CoverageHistoryModal/useCoverageHistoryModal';
import { InsuranceCoverageItem } from 'types/insuranceContracts';
import { SpinWrapper } from 'shared/components';
import { useForm } from 'antd/es/form/Form';
import './useCoverageHistoryModal';
import { useBem } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import Title from 'antd/es/typography/Title';
import { getReinsuranceContractStatus } from 'shared/utils/getReinsuranceContractStatus';
import './CoverageHistoryModal.sass';

type CoverageHistoryModalProps = {
  contract: InsuranceCoverageItem;
  closeModal: () => void;
  refresh: () => void;
};

export const CoverageHistoryModal: React.FC<CoverageHistoryModalProps> = ({
  contract,
  closeModal,
  refresh,
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const {
    isLoading,
    availableStatuses,
    statusHistory,
    onSubmit,
    title,
    columns,
  } = useCoverageHistoryModal(contract, closeModal, refresh);
  const bem = useBem('CoverageHistoryModal');
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [form] = useForm();

  const getValueFromUpload = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (statusHistory) {
      const { Share, Premium } = statusHistory[0];
      form.setFieldsValue({ Share, Premium });
    }
  }, [form, statusHistory]);

  return (
    <Modal
      visible
      width={900}
      title={title}
      onCancel={closeModal}
      footer={null}
    >
      <SpinWrapper isLoading={isLoading}>
        <Form
          form={form}
          layout="horizontal"
          onFinish={(values: any) => {
            refresh();
            onSubmit(values);
          }}
          id="reinsurerStatusForm"
        >
          <Form.Item label={t('coverageHistoryModal.currentStatus')}>
            <Title level={4}>
              {getReinsuranceContractStatus(contract.Status, language)}
            </Title>
          </Form.Item>
          <Form.Item
            name="Status"
            label={t('coverageHistoryModal.changeStatus')}
            rules={[
              { required: true, message: t('reinsurerPage.messages.status') },
            ]}
          >
            <Select
              onSelect={() => setIsDisabled(false)}
              options={availableStatuses?.map(status => ({
                value: status,
                label: getReinsuranceContractStatus(status, language),
              }))}
            />
          </Form.Item>
          <Form.Item
            name="Share"
            label={t('coverageHistoryModal.reinsurersShare')}
            rules={[
              {
                required: true,
                message: t('reinsurerPage.messages.reinsurerShare'),
              },
            ]}
          >
            <InputNumber
              min={0}
              addonAfter="%"
              className={bem('input-number')}
            />
          </Form.Item>
          <Form.Item
            name="Premium"
            label={t('coverageHistoryModal.premiumSize')}
            rules={[
              {
                required: true,
                message: t('reinsurerPage.messages.premiumSize'),
              },
            ]}
          >
            <InputNumber
              min={0}
              addonAfter="$"
              className={bem('input-number')}
            />
          </Form.Item>
          <Form.Item
            name="File"
            valuePropName="fileList"
            getValueFromEvent={getValueFromUpload}
          >
            <Upload
              maxCount={1}
              customRequest={({ onSuccess }: any) => {
                onSuccess('ok');
              }}
            >
              <Button>{t('coverageHistoryModal.uploadDocument')}</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button
              disabled={isDisabled}
              type="primary"
              htmlType="submit"
              className={bem('button')}
            >
              {t('coverageHistoryModal.buttons.okText')}
            </Button>
          </Form.Item>
        </Form>
        <Table columns={columns} dataSource={statusHistory} />
      </SpinWrapper>
    </Modal>
  );
};
