import { useInsurers } from 'shared/hooks/useInsurers';
import { InsuranceContractUpdateRequest } from 'types/insuranceContracts';
import { api } from 'api';
import { useState } from 'react';
import { handleError } from 'shared/utils/validation';

const putInsuranceContract = async (
  id: string,
  contract: InsuranceContractUpdateRequest,
) => {
  await api.put(`InsuranceContracts/${id}`, contract);
};

export const useGeneralDetailsForm = (id: string, refreshData: () => void) => {
  const { insurerList, isFetching: insurersFetching } = useInsurers();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const isLoading = insurersFetching || isFetching;

  const onSubmit = async (contract: InsuranceContractUpdateRequest) => {
    try {
      setIsFetching(true);
      await putInsuranceContract(id, contract);
      await refreshData();
    } catch (e) {
      handleError(e);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    onSubmit,
    isLoading,
    insurerList,
  };
};
