import { FC, ReactElement } from 'react';
import { Row, Table } from 'antd';

import { SearchInput } from 'shared/components';
import { PROFILE_USERS } from 'api/endpoints';
import { useUserTable } from './useUserTable';
import { useSearch } from 'shared/hooks/useSearch';

// FIXME: Исправить dataSource, когда будет изменено dto
export const UsersTable: FC = (): ReactElement => {
  const { data: userData, isFetching } = useSearch(PROFILE_USERS);
  const { columns } = useUserTable();

  return (
    <>
      <Row>
        <SearchInput url={PROFILE_USERS} />
      </Row>
      <Table
        className="mt-20"
        loading={isFetching}
        dataSource={userData as any}
        columns={columns}
      />
    </>
  );
};
