import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { search } from 'store';
import { SearchQueryParams } from 'types/common';

export const useSearchInput = (url: string) => {
  const dispatch = useDispatch();
  const { data: searchData, isFetching } = useSelector(search.selector.state);

  const searchValue = useCallback(
    (queryParams: SearchQueryParams) => {
      dispatch(search.thunk.request({ url, queryParams }));
    },
    [dispatch, url],
  );

  useEffect(() => {
    dispatch(search.thunk.request({ url }));
  }, [dispatch, url]);

  return {
    searchValue,
    isFetching,
  };
};
