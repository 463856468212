import { CompanyData } from '../../types/company';
import { ReduxState } from '../store';
import {
  getFetchedRequestState,
  getFetchingRequestState,
  getInitialRequestState,
  MergeRequestState,
  ResponseApiError,
} from '../utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CounterpartyType } from '../../types/counterparties';
import { api } from '../../api';

export interface InsurersState
  extends MergeRequestState<Array<CompanyData>, ResponseApiError> {}

const selector = {
  state: (state: ReduxState) => state.insurers,
  isFetching: (state: ReduxState) => state.insurers.isFetching,
  data: (state: ReduxState) => state.insurers.data,
  error: (state: ReduxState) => state.insurers.error,
};

const initialState: InsurersState = {
  ...getInitialRequestState(),
  error: null,
  data: null,
};

const SLICE_NAME = `insurers`;

const requestThunk = createAsyncThunk(
  `${SLICE_NAME}/request`,
  async (params: { type: CounterpartyType }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<Array<CompanyData>>('/Counterparties', {
        params,
      });

      return data;
    } catch (e) {
      rejectWithValue(e);
    }
  },
  {
    condition: (payload, { getState }) =>
      !selector.isFetching(getState() as ReduxState),
  },
);

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(requestThunk.pending, state => ({
        ...state,
        ...getFetchingRequestState(),
      }))
      .addCase(requestThunk.fulfilled, (state, action) => ({
        ...state,
        ...getFetchedRequestState(),
        data: action.payload as Array<CompanyData>,
        error: null,
      }))
      .addCase(requestThunk.rejected, (state, action) => ({
        ...state,
        ...initialState,
        error: action.payload as ResponseApiError,
      }));
  },
});

interface Insurers {
  action: typeof actions;
  thunk: {
    request: typeof requestThunk;
  };
  reducer: typeof reducer;
  selector: typeof selector;
}

export const insurers: Insurers = {
  action: actions,
  thunk: {
    request: requestThunk,
  },
  reducer,
  selector,
};
