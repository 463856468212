import { PropsWithChildren, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { SelectProps } from 'antd/es/select';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { nameByLanguage } from '../../utils/nameByLanguage';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<any[]>;
  debounceTimeout?: number;
  passDataToHandler?: boolean;
  language: string;
}

export const useDebounceSelect = ({
  language,
  fetchOptions,
  debounceTimeout = 800,
  onChange,
  passDataToHandler,
}: PropsWithChildren<DebounceSelectProps>) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>();
  const fetchRef = useRef(0);

  const filterOption = (
    value: string,
    option: OptionData | OptionGroupData | undefined,
  ) => {
    return (
      option?.children?.toLowerCase()?.localeCompare(value?.toLowerCase()) ??
      option?.label
        ?.toString()
        ?.toLowerCase()
        ?.localeCompare(value.toLowerCase())
    );
  };

  const onChangeDebounceSelect = ({
    value,
    label,
  }: {
    value: string;
    label: any;
  }) => {
    if (passDataToHandler) {
      return onChange?.(
        options?.find(option => option.Id === value),
        { value, label },
      );
    } else {
      return onChange?.({ value, label }, { value, label });
    }
  };

  const optionList = options?.map(
    ({ Id, Name, NameRu, NameEn, TypeName, TypeId, RegisterNumber }) => ({
      value: TypeId ?? Id,
      label:
        Name ??
        nameByLanguage(NameRu, NameEn, language) ??
        `${TypeName} (${RegisterNumber ?? '-'})`,
    }),
  );

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      if (value) {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setIsFetching(true);

        fetchOptions(value).then(newOptions => {
          if (fetchId !== fetchRef.current) {
            return;
          }

          setOptions(newOptions);
          setIsFetching(false);
        });
      }
      setIsFetching(false);
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return {
    filterOption,
    debounceFetcher,
    optionList,
    isFetching,
    onChangeDebounceSelect,
  };
};
