import { FC, ReactElement } from 'react';
import { useParams } from 'react-router';

import { Params } from 'types/common';
import { useAircraftStatistic } from './useAircraftStatistic';
import { EditableStatisticTable, SpinWrapper } from 'shared/components';
import { InsuranceTabProps } from 'types/insuranceContracts';

export const AircraftStatistic: FC<InsuranceTabProps> = ({
  contractStatus,
}): ReactElement => {
  const { requestId } = useParams<Params>();

  const {
    isLoading,
    aircraftStatistic,
    columns,
    onAircraftStatisticSave,
  } = useAircraftStatistic(requestId, contractStatus);

  return (
    <SpinWrapper isLoading={isLoading}>
      <EditableStatisticTable
        onSave={onAircraftStatisticSave}
        dataSourceProps={aircraftStatistic!}
        columns={columns}
      />
    </SpinWrapper>
  );
};
