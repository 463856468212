import { FC, ReactElement, useState } from 'react';
import { Typography, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  SearchInput,
  EditableTable,
  TableForm,
  columnsToFormFields,
} from 'shared/components';
import { PROFILE_LOSSES } from 'api/endpoints';
import { useLossesPage } from './useLossesPage';
import { useParams } from 'react-router-dom';
import { Params } from '../../types/common';

export type Loss = {
  CounterpartyId: number;
  Date: string;
  Theme: string;
  Details: string;
  Amount: number;
  StatusTypeId: number | null;
  StatusNameRu: string;
  StatusNameEn: string;
  Id: number;
  CreatedDate?: string;
  DateString?: string;
};

type LossesPageProps = {
  showTitle?: boolean;
  lossesData?: Loss[];
  isEditable?: boolean;
};

export const LossesPage: FC<LossesPageProps> = ({
  showTitle,
}): ReactElement => {
  const {
    lossesData,
    isFetching,
    refreshSearch,
    columns,
    onSubmit,
  } = useLossesPage(showTitle);
  const { requestId } = useParams<Params>();
  const { Title } = Typography;
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState<boolean>(false);

  const closeForm = () => {
    setShowForm(false);
  };

  return (
    <>
      {showTitle && <Title level={2}>{t('lossesPage.title')}</Title>}
      <Row justify="space-between">
        {!requestId && (
          <>
            <SearchInput
              url={
                showTitle
                  ? PROFILE_LOSSES
                  : `/InsuranceContracts/${requestId}/materiallose`
              }
            />
            <Button
              onClick={() => setShowForm(true)}
              className="adding-button"
              type="primary"
            >
              {t('add')}
            </Button>
          </>
        )}
      </Row>
      {showForm && (
        <TableForm
          fields={columns.map(columnsToFormFields)}
          closeForm={closeForm}
          onSubmit={onSubmit}
        />
      )}
      <EditableTable
        saveEndpoint="/MaterialLosses"
        loading={isFetching}
        dataSourceProps={lossesData?.Items ?? (lossesData as any)}
        columns={columns}
        afterSave={refreshSearch}
        totalItems={lossesData?.TotalItems}
      />
    </>
  );
};

LossesPage.defaultProps = {
  showTitle: true,
};
