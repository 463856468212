import { FC, ReactElement, useState } from 'react';
import { Button, Menu, Row, Select, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GlobalOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { useBem, useInsuranceContracts } from 'shared/hooks';
import { CreateContractModal } from 'pages/InsurancePage';
import { useSidebar } from './useSidebar';
import { isAirline, isReinsurer } from 'shared/utils/checkUserRole';

import './Sidebar.sass';

const { SubMenu } = Menu;
const { Option } = Select;

export const Sidebar: FC = (): ReactElement => {
  const [showContractModal, setShowContractModal] = useState(false);
  const { counterPartyType, onLogout, renderMenuItem } = useSidebar();
  const {
    contracts,
    refreshInsuranceContracts,
    isFetching,
  } = useInsuranceContracts();
  const { t, i18n } = useTranslation();
  const bem = useBem('Sidebar');
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const requestId = pathname.split('=')[1];

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    refreshInsuranceContracts();
  };

  return (
    <div className={bem('wrapper')}>
      {!counterPartyType || isFetching ? (
        <Spin style={{ marginTop: 20 }} />
      ) : (
        <Menu
          selectedKeys={[requestId ?? pathname.split('/')[1]]}
          mode="inline"
        >
          {isAirline(counterPartyType) && (
            <SubMenu key="airline" title={t('sidebar.company')}>
              <Menu.Item key="about-company">
                <Link to="/about-company">
                  {t('sidebar.companyItems.about')}
                </Link>
              </Menu.Item>
              <Menu.Item key="flot">
                <Link to="/flot/page=1">{t('sidebar.companyItems.flot')}</Link>
              </Menu.Item>
              <Menu.Item key="routes">
                <Link to="/routes/page=1">
                  {t('sidebar.companyItems.routes')}
                </Link>
              </Menu.Item>
              <Menu.Item key="exploitation">
                <Link to="/exploitation/page=1">
                  {t('sidebar.companyItems.exploitation')}
                </Link>
              </Menu.Item>
              <Menu.Item key="pilots">
                <Link to="/pilots/page=1">
                  {t('sidebar.companyItems.pilots')}
                </Link>
              </Menu.Item>
              <Menu.Item key="losses">
                <Link to="/losses/page=1">
                  {t('sidebar.companyItems.losses')}
                </Link>
              </Menu.Item>
            </SubMenu>
          )}

          {!isAirline(counterPartyType) && (
            <Menu.Item>
              <Link to="/about-company">{t('sidebar.companyItems.about')}</Link>
            </Menu.Item>
          )}
          {isReinsurer(counterPartyType) && (
            <Menu.Item key="coverage-requests">
              <Link to="/coverage-requests">
                {t('sidebar.companyItems.reinsurerCoverage')}
              </Link>
            </Menu.Item>
          )}
          {!isReinsurer(counterPartyType) && (
            <SubMenu key="insurance" title={t('sidebar.insurance.title')}>
              {contracts?.map(renderMenuItem)}
            </SubMenu>
          )}
        </Menu>
      )}
      <Row
        className={bem('bottom-action-container')}
        justify="space-around"
        align="middle"
      >
        {isAirline(counterPartyType) && (
          <Row className={bem('create-button-container')}>
            <div className={bem('create-button')}>
              <Button
                type="link"
                onClick={() => {
                  setShowContractModal(true);
                }}
              >
                {t('sidebar.insurance.add')}
              </Button>
            </div>
          </Row>
        )}
        <div className="sidebar-footer">
          <p className="action-string" onClick={() => onLogout(dispatch)}>
            {t('logout')}
          </p>
          <div className={bem('translate')}>
            <GlobalOutlined style={{ fontSize: 18 }} />
            <Select
              className={bem('select-input')}
              defaultValue={t('userlanguage')}
              onChange={changeLanguage}
              bordered={false}
            >
              <Option value="eng">English</Option>
              <Option value="ru">Русский</Option>
            </Select>
          </div>
        </div>
      </Row>
      {showContractModal && (
        <CreateContractModal onClose={() => setShowContractModal(false)} />
      )}
    </div>
  );
};
