import { Popconfirm, Tag } from 'antd';
import { api } from 'api';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isRussian } from 'shared/utils/isRussian';
import { Training } from './TrainingPage';
import { isNumber, isString } from 'lodash-es';
import { handleError } from 'shared/utils/validation';

const createTraining = async (createdTrainingData: Training) => {
  try {
    await api.post('/Profile/Trainings', createdTrainingData);
  } catch (e) {
    handleError(e);
  }
};

const updateTraining = async (updatedTrainingData: Training) => {
  try {
    const preparedData = {
      ...updatedTrainingData,
      PlaneTypeIds: updatedTrainingData.Planes.map(plane => {
        if (isNumber(plane)) {
          return plane;
        }
        return plane.Id;
      }),
    };
    await api.put(`/Profile/Trainings/${updatedTrainingData.Id}`, preparedData);
  } catch (e) {
    handleError(e);
  }
};

export const useTrainingPage = (refresh: () => void, isEditable?: boolean) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const onTrainingSave = useCallback(
    (record: Training) => {
      if (record.Id) {
        updateTraining(record).then(refresh);
      } else {
        createTraining(record).then(refresh);
      }
    },
    [refresh],
  );

  const renderPlanes = useCallback(
    (planeList: number, { Planes }: Training) => {
      if (isString(Planes)) {
        return Planes.split(', ')?.map(name => (
          <Tag key={name + new Date()}>{name}</Tag>
        ));
      }
      if (!Planes || !Planes?.length) {
        return '-';
      }
      return Planes?.map(({ Id, Name }) => <Tag key={Id}>{Name}</Tag>);
    },
    [],
  );

  const onConfirm = useCallback(
    (id: number) => {
      deleteRow(id).then(refresh);
    },
    [refresh],
  );

  const columns = useMemo(
    () => [
      {
        title: t('trainingPage.company'),
        dataIndex: 'Company',
        key: 'Company',
        width: '25%',
        isRequired: true,
        editable: isEditable,
        sorter: true,
      },
      {
        title: t('trainingPage.country'),
        dataIndex: 'CountryId',
        key: 'CountryId',
        width: '10%',
        selectType: 'country',
        typeCell: 'select',
        isRequired: true,
        editable: isEditable,
        sorter: true,
        render: (
          dataIndex: number,
          { CountryNameRu, CountryNameEn }: Training,
        ) => (isRussian(language) ? CountryNameRu : CountryNameEn),
      },
      {
        title: t('trainingPage.typeOfShips'),
        dataIndex: 'PlaneTypeIds',
        key: 'Planes',
        typeCell: 'multiSelect',
        width: '15%',
        isRequired: true,
        editable: isEditable,
        render: renderPlanes,
      },
      {
        title: t('trainingPage.trainingName'),
        dataIndex: 'Name',
        key: 'Name',
        width: '30%',
        isRequired: true,
        editable: isEditable,
        sorter: true,
        render: (Name: string) => Name ?? '-',
      },
      {
        title: t('trainingPage.periodicity'),
        dataIndex: 'PeriodicyId',
        key: 'PeriodicyId',
        selectType: 'periodicity',
        typeCell: 'select',
        isRequired: true,
        editable: isEditable,
        sorter: true,
        render: (
          dataIndex: number,
          { PeriodicyNameRu, PeriodicyNameEn }: Training,
        ) => (isRussian(language) ? PeriodicyNameRu : PeriodicyNameEn),
      },
      ...[
        isEditable
          ? {
              title: '',
              dataIndex: 'actions',
              width: '5%',
              render: (_: undefined, { Id }: Training) => {
                return (
                  <Popconfirm
                    title={t('deleteRow')}
                    onConfirm={() => onConfirm(Id)}
                    okText={t('deleteOk')}
                    cancelText={t('deleteCancel')}
                  >
                    <p className="custom-cell">
                      {t('usersTable.deleteAction')}
                    </p>
                  </Popconfirm>
                );
              },
            }
          : [],
      ],
    ],
    [isEditable, language, onConfirm, renderPlanes, t],
  );

  const deleteRow = async (rowId: number) => {
    try {
      await api.delete(`/Trainings/${rowId}`);
    } catch (e) {
      handleError(e);
    }
  };

  return {
    createTraining,
    onTrainingSave,
    columns,
  };
};
