export enum AircraftStatisticTypes {
  PlanesAmount = 0,
  PlacesAmount = 1,
  StartVal = 2,
  AvgVal = 3,
  EndVal = 4,
  StartDate = 'StartDate',
  EndDate = 'EndDate',
}

export type TAircraftStatistic = {
  StartDate: { [key: number]: number | string; diff: number };
  EndDate: { [key: number]: number | string; diff: number };
  PlanesAmount: { [key: number]: number | string; diff: number };
  PlacesAmount: { [key: number]: number | string; diff: number };
  StartVal: { [key: number]: number | string; diff: number };
  EndVal: { [key: number]: number | string; diff: number };
  AvgVal: { [key: number]: number | string; diff: number };
};
