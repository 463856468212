import { ReinsuranceContractStatus } from 'types/insuranceContracts';

const contractStatusRu: Record<ReinsuranceContractStatus, string> = {
  [ReinsuranceContractStatus.Review]: 'На рассмотрении',
  [ReinsuranceContractStatus.Refused]: 'Отказано',
  [ReinsuranceContractStatus.Correction]: 'Требуется уточнить',
  [ReinsuranceContractStatus.Withdrawn]: 'Отозвано',
  [ReinsuranceContractStatus.Agreed]: 'Согласовано',
  [ReinsuranceContractStatus.Completed]: 'Выбрано',
};

const contractStatusEn: Record<ReinsuranceContractStatus, string> = {
  [ReinsuranceContractStatus.Review]: 'Review',
  [ReinsuranceContractStatus.Refused]: 'Refused',
  [ReinsuranceContractStatus.Correction]: 'Correction',
  [ReinsuranceContractStatus.Withdrawn]: 'Withdrawn',
  [ReinsuranceContractStatus.Agreed]: 'Agreed',
  [ReinsuranceContractStatus.Completed]: 'Completed',
};

export const getReinsuranceContractStatus = (
  contractStatus: ReinsuranceContractStatus,
  lang: string,
) =>
  lang === 'ru'
    ? contractStatusRu[contractStatus]
    : contractStatusEn[contractStatus];
