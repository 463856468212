import axios from 'axios';

import { isRussian } from 'shared/utils/isRussian';
import { getAuthToken } from 'shared/utils/getAuthToken';

export const api = axios.create({
  baseURL: '/api',
  withCredentials: true,
});

api.interceptors.request.use(config => {
  const userLanguage = localStorage.getItem('i18nextLng') ?? '';

  config.headers = {
    ...getAuthToken(),
    'Accept-language': isRussian(userLanguage) ? 'ru' : 'en',
  };

  return config;
});
