import { DatePicker, Form, InputNumber, Row } from 'antd';
import { api } from 'api';
import { range } from 'lodash-es';
import moment from 'moment';
import { Exploitation } from 'pages/ExploitationPage/ExploitationPage';
import { useCallback } from 'react';
import DatepickerPrefix from 'shared/images/datepicker_prefix.svg';
import { handleError } from 'shared/utils/validation';
import { CreatedRoute } from 'types/route';

const createRoute = async (routeData: any) => {
  try {
    await api.post('/Profile/routes', routeData);
  } catch (e) {
    handleError(e);
  }
};

export const useCreateRoutesModal = (
  refresh: () => void,
  close: () => void,
) => {
  const labelList = [
    'Период',
    'Рейсов',
    'Пассажиров',
    'Налет, ч',
    'Загрузка %',
  ];

  const renderFormItems = () => {
    return [...Array(4)].map((_: undefined, index: number) => (
      <Row>
        <Form.Item
          label={index === 0 && labelList[0]}
          name={`${index}-Period`}
          key={`${index}-Period`}
        >
          <DatePicker
            picker="quarter"
            format={(date: any) =>
              `${moment(date).quarter()} кв ${moment(date).year()}`
            }
            placeholder="Период"
            suffixIcon={<img src={DatepickerPrefix} alt="" />}
          />
        </Form.Item>
        <Form.Item
          label={index === 0 && labelList[1]}
          name={`${index}-FlightsAmount`}
          key={`${index}-FlightsAmount`}
        >
          <InputNumber placeholder="Рейсов" controls={false} />
        </Form.Item>
        <Form.Item
          label={index === 0 && labelList[2]}
          name={`${index}-PassengersAmount`}
          key={`${index}-PassengersAmount`}
        >
          <InputNumber placeholder="Пассажиров" controls={false} />
        </Form.Item>
        <Form.Item
          label={index === 0 && labelList[3]}
          name={`${index}-FlyingHours`}
          key={`${index}-FlyingHours`}
        >
          <InputNumber placeholder="Налет" controls={false} />
        </Form.Item>
        <Form.Item
          label={index === 0 && labelList[4]}
          name={`${index}-Loading`}
          key={`${index}-Loading`}
        >
          <InputNumber placeholder="Загрузка" controls={false} />
        </Form.Item>
      </Row>
    ));
  };

  const prepareExploitationList = (values: any) => {
    const keys = [
      'Period',
      'PassengersAmount',
      'FlightsAmount',
      'FlyingHours',
      'Loading',
    ];

    return range(4).reduce(
      (acc: { [key: string]: number | string }[], index) => {
        const item = keys.reduce(
          (acc: { [key: string]: number | string }, key) => {
            const value = values[`${index}-${key}`];
            if (value) {
              if (key === 'Period') {
                acc.Year = moment(value).year();
                acc.Quarter = moment(value).quarter();
              } else {
                acc[key] = value;
              }
            }
            return acc;
          },
          {},
        );

        if (!!Object.keys(item).length) {
          acc.push(item);
        }
        return acc;
      },
      [],
    ) as Exploitation[];
  };

  const onSubmit = useCallback(
    (values: any) => {
      const preparedData: CreatedRoute = {
        TypeId: values.TypeId,
        StartPointId: values.StartPointId,
        EndPointId: values.EndPointId,
        ExploitationList: prepareExploitationList(values),
      };
      createRoute(preparedData).then(() => {
        close();
        refresh();
      });
    },
    [close, refresh],
  );

  return { renderFormItems, onSubmit };
};
