import { useSearch } from 'shared/hooks/useSearch';
import { PROFILE_ROUTES } from 'api/endpoints';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { handleError } from 'shared/utils/validation';
import { nameByLanguage } from 'shared/utils/nameByLanguage';
import moment from 'moment';
import { isNaN } from 'lodash';
import { quarterString } from 'shared/utils/quarterString';
import { Routes } from './RoutesPage';
import { useTranslation } from 'react-i18next';
import { isRussian } from 'shared/utils/isRussian';
import { useParams } from 'react-router-dom';
import { Params } from 'types/common';

type RouteStatistic = {
  Internal: number;
  International: number;
};

const getRoutes = async (insuranceContractId: string) => {
  try {
    const { data } = await api.get<Routes[]>(
      `/InsuranceContracts/${insuranceContractId}/routes/points`,
    );
    return data;
  } catch (e) {
    handleError(e);
  }
};

const requestStatistic = async () => {
  try {
    const { data } = await api.get('/Routes/statistic');
    return data;
  } catch (e) {
    handleError(e);
  }
};

const createRoute = async (createdRoute: Routes) => {
  try {
    await api.post('/Routes', createdRoute);
  } catch (e) {
    handleError(e);
  }
};

const deleteRoute = async (id: number) => {
  try {
    await api.delete(`/Routes/${id}`);
  } catch (e) {
    handleError(e);
  }
};

export const useRoutesPage = (showTitle?: boolean) => {
  const { requestId, page } = useParams<Params>();
  const [routes, setRoutes] = useState<Routes[]>();
  const { data: routesData, refreshSearch, isFetching } = useSearch(
    PROFILE_ROUTES,
  );
  const [statistic, setStatistic] = useState<RouteStatistic>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const totalItems = requestId ? routes?.length : routesData?.TotalItems;
  const dataSource = useMemo(() => {
    if (requestId) {
      return routes;
    }
    return routesData?.Items ?? (routesData as any);
  }, [requestId, routes, routesData]);

  const refresh = useCallback(
    (config?: {}) => {
      requestStatistic().then(data => {
        setStatistic(data);
        refreshSearch(config);
      });
    },
    [refreshSearch],
  );

  useEffect(() => {
    if (requestId) {
      getRoutes(requestId).then(setRoutes);
    } else {
      refresh({ page });
    }
  }, [page, refresh, requestId]);

  const onSubmit = useCallback(
    (createdRoute: Routes) => {
      createRoute(createdRoute).then(() => refresh({ page }));
    },
    [page, refresh],
  );

  const columns = useMemo(
    () => [
      {
        title: t('routesPage.table.firstPoint'),
        dataIndex: 'StartNameRu',
        key: 'StartNameRu',
        point: 'first',
        selectType: 'point',
        typeCell: 'select',
        isRequired: true,
        sorter: true,
        render: (x: undefined, { StartNameRu, StartNameEn }: Routes) => {
          const startName = nameByLanguage(StartNameRu, StartNameEn, language);
          return startName ?? '-';
        },
      },
      {
        title: t('routesPage.table.secondPoint'),
        dataIndex: 'EndNameRu',
        key: 'EndNameRu',
        point: 'second',
        selectType: 'point',
        typeCell: 'select',
        isRequired: true,
        sorter: true,
        render: (x: undefined, { EndNameRu, EndNameEn }: Routes) => {
          const endName = nameByLanguage(EndNameRu, EndNameEn, language);
          return endName ?? '-';
        },
      },
      {
        title: t('routesPage.table.type'),
        dataIndex: 'TypeId',
        key: 'TypeId',
        typeCell: 'select',
        isRequired: true,
        sorter: true,
        render: (_: undefined, { TypeNameRu, TypeNameEn }: Routes) =>
          isRussian(language) ? TypeNameRu : TypeNameEn,
      },
      {
        title: t('routesPage.table.period'),
        dataIndex: 'period',
        key: 'date',
        typeCell: 'quarter',
        isRequired: true,
        sorter: true,
        render: (_: undefined, { Year, Quarter, date }: any) => {
          if (date) {
            if (!isNaN(moment(date).quarter())) {
              return quarterString(
                moment(date).quarter(),
                moment(date).year(),
                language,
              );
            }
            return date;
          }
          return quarterString(Quarter, Year, language);
        },
      },
      {
        title: t('routesPage.table.flights'),
        dataIndex: 'FlightCount',
        key: 'FlightCount',
        typeCell: 'inputNumber',
        minNumCount: 0,
        width: '10%',
        isRequired: true,
        sorter: true,
      },
      {
        title: 'Пассажиров',
        dataIndex: 'PassengersAmount',
        key: 'PassengersAmount',
        sorter: true,
        type: 'inputNumber',
        width: '10%',
        render: (_: undefined, { Exploitation }: any) =>
          Exploitation?.PassengersAmount ?? '-',
      },
      {
        title: 'Налет, ч',
        dataIndex: 'Exploitation.FlyingHours',
        key: 'FlyingHours',
        width: '10%',
        type: 'inputNumber',
        sorter: true,
        render: (_: undefined, { Exploitation }: any) =>
          Exploitation?.FlyingHours ?? '-',
      },
      {
        title: 'Загрузка',
        dataIndex: 'Loading',
        key: 'Loading',
        width: '10%',
        type: 'inputNumber',
        sorter: true,
        render: (_: undefined, { Exploitation }: any) =>
          Exploitation?.Loading ?? '-',
      },
    ],
    [language, t],
  );

  return {
    routesData: dataSource,
    totalItems,
    columns,
    statistic,
    refresh,
    isFetching,
    onSubmit,
  };
};
