export const prepareStatisticData = (columnsByKey: any, statisticData: any) => {
  let result: any = [];

  if (statisticData) {
    const entries = Object.entries(statisticData);
    entries?.forEach(([key, value]: any) => {
      const i = columnsByKey[key];
      result[i] = { ...value, key };
    });
  }

  return result;
};

export const prepareDataSource = (data: any[]) => {
  return data;
};

export const getDiffs = (data: any[]) => {
  const copyData: any = { ...data };
  delete copyData.StartDate;
  delete copyData.EndDate;

  return data
    .filter((item: any) => item.Diff)
    .map((item: any) => {
      return item[0].Diff;
    });
};
