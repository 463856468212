import { Upload, Modal, Form, Button, notification } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { FC, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TDocument } from 'types/InsuredDocuments';
import { api } from 'api';
import { useParams } from 'react-router-dom';
import { Params } from 'types/common';
import { normFile } from 'shared/utils/normFile';

const { Dragger } = Upload;

type EditingContractsDocumentModalProps = {
  visible: boolean;
  close: () => void;
  defaultValues?: TDocument;
  refreshDocuments: () => void;
};

export const EditingContractsDocumentModal: FC<EditingContractsDocumentModalProps> = ({
  visible,
  close,
  defaultValues,
  refreshDocuments,
}): ReactElement => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { requestId } = useParams<Params>();

  const customRequest = ({ onSuccess }: any) => {
    onSuccess('ok');
  };

  const closeModal = () => {
    close();
    form.resetFields();
  };

  const onFinishEditing = async ({ File }: any) => {
    try {
      if (!Object.values(File).includes(undefined)) {
        const formData = new FormData();
        formData.append('Name', defaultValues?.Name ?? '');
        formData.append('Agreed', String(defaultValues?.Agreed));
        formData.append('File', File[0]?.originFileObj);
        formData.append('Type', String(defaultValues?.Type));
        formData.append('SigningDate', new Date().toISOString());
        await api.put(
          `/InsuranceContracts/${requestId}/documents/${defaultValues?.Id}`,
          formData,
        );
        closeModal();
        refreshDocuments();
      }
    } catch (e) {
      notification.error({ message: e?.response?.data?.Message });
      throw e;
    }
  };

  useEffect(() => {
    return () => form.resetFields();
  }, [form]);

  return (
    <Modal
      title={t('uploadingFile.title')}
      visible={visible}
      onCancel={closeModal}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinishEditing}>
        <Form.Item
          rules={[{ required: true, message: t('error.emptyFile') }]}
          label={t('uploadingFile.title')}
          name="File"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Dragger customRequest={customRequest} maxCount={1}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p
              style={{ display: 'inline-block', width: '90%' }}
              className="ant-upload-text"
            >
              {t('uploadingFile.message')}
            </p>
          </Dragger>
        </Form.Item>
        <Button htmlType="submit" type="primary">
          {t('editingModal.buttons.okText')}
        </Button>
      </Form>
    </Modal>
  );
};
