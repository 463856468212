import { FC, ReactElement, useMemo } from 'react';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Result,
  Row,
  Select,
  Typography,
} from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useRegistrationPage } from './useRegistrationPage';
import { ReactComponent as PlaneSvg } from 'shared/images/Plane.svg';
import { isRussian } from 'shared/utils/isRussian';
import { useBem } from 'shared/hooks';

import './RegistrationPage.sass';

export const RegistrationPage: FC = (): ReactElement => {
  const history = useHistory();
  const { Title } = Typography;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const bem = useBem('RegistrationPage');
  const [form] = Form.useForm();
  const { isSuccessfulRequest, createUser } = useRegistrationPage();

  if (isSuccessfulRequest) {
    return (
      <Result
        icon={<PlaneSvg style={{ width: 100, height: 100, fill: 'blue' }} />}
        title={t('register.info')}
      />
    );
  }

  return (
    <>
      <div className="go-back">
        <Button onClick={history.goBack} type="text" shape="round">
          <Row>
            <LeftOutlined className={bem('back-button')} />
            <p>{t('signUpPage.back')}</p>
          </Row>
        </Button>
      </div>
      <div className="form-wrapper">
        <Title>{t('signUpPage.title')}</Title>
        <div className="form-container">
          <Form
            form={form}
            onFinish={createUser}
            layout="vertical"
            className="form"
          >
            <Row justify="space-between">
              <Form.Item
                name="FirstName"
                label={t('signUpPage.firstName')}
                rules={[
                  {
                    required: true,
                    message: t('register.empty.firstname'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="LastName"
                label={t('signUpPage.lastName')}
                rules={[
                  {
                    required: true,
                    message: t('register.empty.lastname'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="MiddleName" label={t('signUpPage.middleName')}>
                <Input />
              </Form.Item>
            </Row>
            <Form.Item
              name="Email"
              label={t('signUpPage.email')}
              rules={[
                {
                  required: true,
                  message: t('register.empty.email'),
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              name="Password"
              label={t('signUpPage.password')}
              rules={[
                {
                  required: true,
                  message: t('register.empty.password'),
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              name="PasswordConfirmation"
              label={t('signUpPage.confirmPassword')}
              rules={[
                {
                  required: true,
                  message: t('register.empty.password'),
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Row justify="space-between">
              <Form.Item
                name="Type"
                rules={[
                  {
                    required: true,
                    message: t('register.empty.role'),
                  },
                ]}
                label={t('roles.label')}
              >
                <Select className={bem('select-field')}>
                  <Select.Option value={0}>{t('roles.airline')}</Select.Option>
                  <Select.Option value={1}>{t('roles.broker')}</Select.Option>
                  <Select.Option value={2}>{t('roles.insurer')}</Select.Option>
                  <Select.Option value={3}>{t('roles.insured')}</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="Residence"
                label={t('signUpPage.residence')}
                rules={[
                  {
                    required: true,
                    message: t('register.empty.country'),
                  },
                ]}
              >
                <Select className={bem('select-field')}>
                  <Select.Option value={0}>
                    {t('countries.england')}
                  </Select.Option>
                  <Select.Option value={1}>
                    {t('countries.russia')}
                  </Select.Option>
                  <Select.Option value={2}>
                    {t('countries.other')}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="TIN"
                label={t('signUpPage.inn')}
                rules={[
                  {
                    required: true,
                    message: t('register.empty.tin'),
                  },
                ]}
              >
                {isRussian(language) ? (
                  <InputNumber
                    maxLength={12}
                    min={0}
                    controls={false}
                    stringMode
                  />
                ) : (
                  <Input maxLength={30} />
                )}
              </Form.Item>
            </Row>
            <Button
              className={bem('submit-button')}
              htmlType="submit"
              type="primary"
            >
              {t('signUpPage.button')}
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};
