import { useDispatch, useSelector } from 'react-redux';
import { search } from 'store';
import { useCallback } from 'react';

export const useSearch = (url: string) => {
  const dispatch = useDispatch();
  const { data, isFetching } = useSelector(search.selector.state);

  const refreshSearch = useCallback(
    (queryParams?: any) => {
      dispatch(search.thunk.request({ url, queryParams }));
    },
    [dispatch, url],
  );

  return {
    data,
    refreshSearch,
    isFetching,
  };
};
