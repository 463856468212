import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { api } from 'api';
import { Pilots } from './PilotsPage';
import { PilotsStatistic } from 'types/pilot';
import { handleError } from 'shared/utils/validation';
import { isRussian } from 'shared/utils/isRussian';
import { useSearch } from 'shared/hooks/useSearch';
import { PROFILE_PILOTS } from 'api/endpoints';
import { useParams } from 'react-router-dom';
import { Params } from '../../types/common';

const getStatistic = async () => {
  try {
    const { data } = await api.get('/Pilots/statistics');
    return data;
  } catch (e) {
    handleError(e);
  }
};

const createPilotData = async (pilotData: Pilots) => {
  try {
    await api.post('/Pilots', pilotData);
  } catch (e) {
    handleError(e);
  }
};

const updatePilotData = async (pilotData: Pilots) => {
  try {
    await api.put(`/Pilots/${pilotData.Id}`, pilotData);
  } catch (e) {
    handleError(e);
  }
};

export const usePilotPage = () => {
  const { page } = useParams<Params>();
  const { data: pilotsData, refreshSearch, isFetching } = useSearch(
    PROFILE_PILOTS,
  );
  const [statistic, setStatistic] = useState<PilotsStatistic>();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const refresh = useCallback(
    ({ page }) => {
      refreshSearch({ page });
    },
    [refreshSearch],
  );

  const deletePilot = useCallback(async (rowId: number) => {
    try {
      await api.delete(`/Pilots/${rowId}`);
    } catch (e) {
      handleError(e);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        title: t('pilotsPage.table.number'),
        dataIndex: 'Number',
        key: 'number',
        width: '5%',
        sorter: true,
      },
      {
        title: t('pilotsPage.table.position'),
        dataIndex: 'TypeId',
        key: 'TypeId',
        width: '20%',
        selectType: 'position',
        typeCell: 'select',
        isRequired: true,
        editable: true,
        sorter: true,
        render: (TypeId: number, { TypeNameRu, TypeNameEn }: Pilots) =>
          isRussian(language) ? TypeNameRu : TypeNameEn,
      },
      {
        title: t('pilotsPage.table.aircraftModel'),
        dataIndex: 'PlaneTypeId',
        key: 'PlaneTypeId',
        width: '20%',
        selectType: 'models',
        typeCell: 'select',
        isRequired: true,
        editable: true,
        sorter: true,
        render: (_: number, { PlaneName }: { PlaneName: string }) => PlaneName,
      },
      {
        title: t('pilotsPage.table.totalPlaque'),
        dataIndex: 'CommonFlightHours',
        key: 'CommonFlightHours',
        typeCell: 'inputNumber',
        width: '10%',
        isRequired: true,
        editable: true,
        sorter: true,
      },
      {
        title: t('pilotsPage.table.aircraftPlaque'),
        dataIndex: 'FlightByModelHours',
        key: 'FlightByModelHours',
        typeCell: 'inputNumber',
        width: '10%',
        isRequired: true,
        editable: true,
        sorter: true,
      },
      {
        title: t('pilotsPage.table.captainPlaque'),
        dataIndex: 'CapitanFlightHours',
        key: 'CapitanFlightHours',
        typeCell: 'inputNumber',
        width: '10%',
        isRequired: true,
        editable: true,
        sorter: true,
      },
    ],
    [deletePilot, language, page, refresh, t],
  );

  const onPilotSave = useCallback(
    (record: Pilots) => {
      if (record.Id) {
        updatePilotData(record).then(() => refresh({ page }));
      } else {
        createPilotData(record).then(() => refresh({ page }));
      }
    },
    [page, refresh],
  );

  useEffect(() => {
    refresh({ page });
  }, [page, refresh]);

  useEffect(() => {
    getStatistic().then(setStatistic);
  }, [pilotsData]);

  return {
    statistic,
    pilotsData,
    isFetching,
    refresh,
    columns,
    onPilotSave,
  };
};
