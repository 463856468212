import { getDiff } from 'shared/utils/getDiff';
import {
  canAirlineEdit,
  canBrokerEdit,
  isBroker,
} from 'shared/utils/checkUserRole';
import { AircraftStatisticTypes } from 'types/aircraftStatistic';
import { api } from 'api';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { TAircraftStatistic } from 'types/aircraftStatistic';
import { useTranslation } from 'react-i18next';
import { renderColumnTitle } from '../../utils';
import { formatMoney } from 'shared/utils/formatMoney';
import { handleError } from 'shared/utils/validation';
import { InsuranceContractType } from 'types/insuranceContracts';

const requestStatistic = async (id: string) => {
  try {
    const { data } = await api.get<TAircraftStatistic[]>(
      `/InsuranceContracts/${id}/fleet/statistic`,
    );
    return data;
  } catch (e) {
    handleError(e);
  }
};

const createStatisticItem = async (recordId: string, updatedDate: any) => {
  try {
    await api.post(
      `/InsuranceContracts/${recordId}/fleet/statistic`,
      updatedDate,
    );
  } catch (e) {
    handleError(e);
  }
};

const updateStatisticItem = async (recordId: string, updatedDate: any) => {
  try {
    await api.put(
      `/InsuranceContracts/${recordId}/fleet/statistic`,
      updatedDate,
    );
  } catch (e) {
    handleError(e);
  }
};

export const useAircraftStatistic = (id: string, contractStatus?: number) => {
  const [aircraftStatistic, setAircraftStatistic] = useState<any>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { t } = useTranslation();
  const counterpartyType = localStorage.getItem('CounterpartyType');

  const columnsByKey: any = useMemo(
    () => ({
      PlanesAmount: [AircraftStatisticTypes.PlanesAmount],
      PlacesAmount: [AircraftStatisticTypes.PlacesAmount],
      StartVal: [AircraftStatisticTypes.StartVal],
      AvgVal: [AircraftStatisticTypes.AvgVal],
      EndVal: [AircraftStatisticTypes.EndVal],
      StartDate: [AircraftStatisticTypes.StartDate],
      EndDate: [AircraftStatisticTypes.EndDate],
    }),
    [],
  );

  const prepareData = useCallback((columnsByKey: any, statisticData: any) => {
    let result: any = [];

    if (statisticData) {
      const entries = Object.entries(statisticData);
      entries?.forEach(([key, value]: any) => {
        const i = columnsByKey[key];
        result[i] = { ...value, key };
      });
    }

    return result;
  }, []);

  const refreshAircraftStatistic = useCallback(() => {
    setIsFetching(true);
    requestStatistic(id)
      .then(data => {
        const preparedData = prepareData(columnsByKey, data);
        setAircraftStatistic(preparedData ?? []);
      })
      .finally(() => setIsFetching(false));
  }, [columnsByKey, id, prepareData]);

  useEffect(() => {
    refreshAircraftStatistic();
  }, [refreshAircraftStatistic]);

  const descriptionColumn = useMemo(
    () => [
      t('aircraftStatistic.description.planeAmount'),
      t('aircraftStatistic.description.placeAmount'),
      t('aircraftStatistic.description.startVal'),
      t('aircraftStatistic.description.avgVal'),
      t('aircraftStatistic.description.endVal'),
    ],
    [t],
  );

  const checkIsEditableColumns = useCallback(
    (columnId: number) => {
      return (
        [1, 2, 3, 4].includes(columnId) &&
        contractStatus &&
        (canAirlineEdit(counterpartyType, contractStatus) ||
          (isBroker(counterpartyType) &&
            contractStatus === InsuranceContractType.Review))
      );
    },
    [contractStatus, counterpartyType],
  );

  const columns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'Description',
        key: 'Description',
        render: (_: undefined, x: TAircraftStatistic, index: number) =>
          descriptionColumn[index],
      },
      ...Array(5)
        .fill(0)
        .map((_, index) => {
          return {
            editable: checkIsEditableColumns(index),
            title: renderColumnTitle(
              aircraftStatistic?.StartDate[index],
              aircraftStatistic?.EndDate[index],
            ),
            key: `insurancePeriod${index}`,
            typeCell: 'inputNumber',
            dataIndex: index,
            render: (text: string, record: any, i: number) =>
              [2, 3, 4].includes(i)
                ? formatMoney(record[index])
                : record[index],
          };
        })
        .reverse(),
      {
        title: 'Изменения за период',
        dataIndex: 'diff',
        key: 'diff',
        width: '20%',
        render: (diff: number) => getDiff(diff),
      },
    ],
    [
      aircraftStatistic?.EndDate,
      aircraftStatistic?.StartDate,
      checkIsEditableColumns,
      descriptionColumn,
    ],
  );

  const isLoading = isFetching || !aircraftStatistic || !contractStatus;

  const onAircraftStatisticSave = useCallback(
    (record: any) => {
      if (!aircraftStatistic) {
        return;
      }
      const updatedData = { ...record.record, ...record.values };
      const editingPeriod: string = Object.keys(record.values)[0];
      const changedData = { ...aircraftStatistic, ...updatedData };
      const dataForSave: any = {
        StartDate: changedData.StartDate[editingPeriod],
        EndDate: changedData.EndDate[editingPeriod],
        StartVal:
          aircraftStatistic[AircraftStatisticTypes.StartVal][
            Number(editingPeriod)
          ] ?? '',
        AvgVal:
          aircraftStatistic[AircraftStatisticTypes.AvgVal][
            Number(editingPeriod)
          ] ?? '',
        EndVal:
          aircraftStatistic[AircraftStatisticTypes.EndVal][
            Number(editingPeriod)
          ] ?? '',
      };

      dataForSave[updatedData.key] = Object.values(record.values)[0];
      if (aircraftStatistic) {
        updateStatisticItem(id, dataForSave).then(refreshAircraftStatistic);
      } else {
        createStatisticItem(id, dataForSave).then(refreshAircraftStatistic);
      }
    },
    [aircraftStatistic, id, refreshAircraftStatistic],
  );

  return {
    aircraftStatistic,
    onAircraftStatisticSave,
    isLoading,
    columns,
  };
};
