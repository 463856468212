type Country = {
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  NameRu: string;
  ShortNameRu: string;
  NameEn: string;
  ShortNameEn: string;
};

export type InsuranceContract = {
  CountriesNameEn: string;
  CountriesNameRu: string;
  InsuredCounterpartyNameEn: string | null;
  InsuredCounterpartyNameRu: string | null;
  InsurerCounterpartyNameEn: null;
  InsurerCounterpartyNameRu: null;
  InsuredCounterpartyId: number;
  InsurerCounterpartyId: number | null;
  CountryList: Country[];
  CountryIds: number[];
  Period: number;
  MaxVal: number;
  OnboardkitVal: number;
  ToolkitVal: number;
  PersonalInjuryVal: number;
  FranchiseType: number;
  FranchisePlaneVal: string | null;
  FranchiseOnboardkitVal: string | null;
  FranchiseToolkitVal: string | null;
  Status: number;
  StartDate: string;
  EndDate: string;
  Id: number;
};

export enum InsurancePilotType {
  Captain = 0,
  CoPilot = 1,
  Instructor = 2,
  MainPilot = 3,
}

export enum InsuranceContractType {
  Canceled = 0,
  Blank = 1,
  Review = 2,
  Correction = 3,
  Agreed = 4,
  DocumentApproval = 5,
  DocumentCorrection = 6,
}

export enum InsurancePeriodType {
  HalfYear = 0,
  Year = 1,
  Quarter = 2,
}

export type InsuranceContractBlank = {
  StartDate: string;
};

export type InsuranceContractCreateRequest = {
  CountryIds: Array<number>;
  Period: InsurancePeriodType;
  StartDate: string;
};

export enum InsurerFranchiseType {
  Absent = 0,
  Conditional = 1,
  Unconditional = 2,
}

export enum InsuranceRouteType {
  Total = 0,
  Inner = 1,
  International = 2,
}

export enum InsuranceExploitationType {
  Passengers = 0,
  DepartureCount = 1,
  FlyingHours = 2,
  AvgFlightHours = 3,
  Loading = 4,
}

export type InsuranceRouteItem = {
  Id?: number | null;
  Period: number;
  FlightsAmount: number;
  Type?: InsuranceRouteType;
};

export type InsuranceRouteResponse = {
  Type: InsuranceRouteType;
  Items: Array<InsuranceRouteItem>;
};

export type InsuranceContractUpdateRequest = {
  InsurerCounterpartyId: number;
  MaxVal: number;
  OnboardkitVal: number;
  ToolkitVal: number;
  PersonalInjuryVal: number;
  FranchiseType: InsurerFranchiseType;
  FranchisePlaneVal: number;
  FranchiseOnboardkitVal: number;
  FranchiseToolkitVal: number;
};

export enum ReinsuranceContractStatus {
  Review = 0,
  Refused = 1,
  Correction = 2,
  Withdrawn = 3,
  Agreed = 4,
  Completed = 5,
}

export type InsuranceCoverageItem = {
  Id: number;
  ReinsurerId: number;
  ReinsurerNameRu: string;
  ReinsurerNameEn: string;
  DocumentExtension: string;
  Share?: number;
  Premium?: number;
  Status: ReinsuranceContractStatus;
  Selected: boolean;
};

export type ReinsuranceStatusDto = {
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  ReinsuranceContractId: number;
  UserId: number;
  UserName: string;
  PrevStatus?: ReinsuranceContractStatus;
  CurrStatus?: ReinsuranceContractStatus;
  DocumentExtension?: string;
  DocumentVersion?: string;
  Share?: number;
  Premium?: number;
  Comment?: string;
};

export type ReinsuranceContractDto = {
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  InsuranceContractId: number;
  InsuranceStartDate: string;
  InsuranceEndDate: string;
  InsuredId: number;
  InsuredNameEn?: string;
  InsuredNameRu?: string;
  PlanesCount: number;
  DocumentExtension?: string;
  Share?: number;
  Premium?: number;
  Status: ReinsuranceContractStatus;
};

export type InsuranceTabProps = {
  contractStatus: number | undefined;
};
