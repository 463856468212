import { api } from 'api';
import { Button, Checkbox, Popconfirm, Row } from 'antd';
import { TDocument, NewDocument, DocumentType } from 'types/InsuredDocuments';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { downloadFile } from 'shared/utils/fileSaver';
import { getDocumentTypeTranslated } from 'shared/utils/getDocumentType';
import { isRussian } from 'shared/utils/isRussian';
import { canBrokerEdit } from 'shared/utils/checkUserRole';
import { isNull } from 'lodash-es';
import { handleError } from 'shared/utils/validation';

const requestDocuments = async (id: string): Promise<TDocument[]> => {
  try {
    const { data } = await api.get<TDocument[]>(
      `/InsuranceContracts/${id}/documents`,
    );
    return data;
  } catch (e) {
    throw handleError(e);
  }
};

export const useDocumentsTable = (
  id: string,
  selectDocument: (id: number) => void,
  openModal: () => void,
  contractStatus?: number,
) => {
  const [documentsData, setDocumentsData] = useState<TDocument[]>([]);
  const [allDocumentsData, setAllDocumentsData] = useState<TDocument[]>([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState<number>();
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [showAddingDocumentModal, setShowAddingDocumentModal] = useState<
    boolean
  >(false);
  const [showEditingDocumentModal, setShowEditingDocumentModal] = useState<
    boolean
  >(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const counterpartyType = useMemo(
    () => localStorage.getItem('CounterpartyType'),
    [],
  );

  const refreshDocumentsTable = useCallback(async () => {
    setIsFetching(true);
    const data = await requestDocuments(id);
    setDocumentsData(data);
    setAllDocumentsData(data);
    setIsFetching(false);
  }, [id]);

  const updatedDocument = async (id: string, updatedDocument: any) => {
    try {
      await api.put(
        `/InsuranceContracts/${id}/documents/${updatedDocument.Id}`,
        updatedDocument,
      );
    } catch (e) {
      handleError(e);
    }
  };

  const createDocument = async (id: string, newDocument: NewDocument) => {
    try {
      await api.post(`/InsuranceContracts/${id}/documents`, newDocument);
    } catch (e) {
      handleError(e);
    }
  };

  const onFinishAdding = async (values: any): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append('Name', values.Name);
      formData.append('File', values.Files[0]?.originFileObj);
      formData.append('Type', values.Type);
      if (values.DateOfSigning) {
        formData.append(
          'Date',
          moment(values.DateOfSigning).format('YYYY-MM-DD'),
        );
      }
      const { data } = await api.post(
        `/InsuranceContracts/${id}/documents`,
        formData,
      );
      setDocumentsData([data, ...documentsData]);
      setShowAddingDocumentModal(false);
    } catch (e) {
      handleError(e);
    }
  };

  const onFinishEditing = useCallback(
    async (values: any) => {
      const selectedDocument = documentsData.find(({ Id }) => Id === values.Id);
      try {
        if (!Object.values(values).includes(undefined) && selectedDocument) {
          const formData = new FormData();
          formData.append('Name', selectedDocument.Name);
          values?.File?.file.originFileObj &&
            formData.append('File', values.File.file.originFileObj);
          formData.append('Type', String(selectedDocument.Type));
          formData.append('Agreed', String(values?.Agreed));
          formData.append('LoadingDate', new Date().toISOString());
          await api.put(
            `/InsuranceContracts/${id}/documents/${selectedDocument.Id}`,
            formData,
          );
          refreshDocumentsTable();
          setShowEditingDocumentModal(false);
        }
      } catch (e) {
        handleError(e);
      }
    },
    [documentsData, id, refreshDocumentsTable],
  );

  useEffect(() => {
    refreshDocumentsTable();
  }, [refreshDocumentsTable]);

  const deleteRow = useCallback(
    async (id: string, documentId: number) => {
      try {
        await api.delete(`/InsuranceContracts/${id}/documents/${documentId}`);
        refreshDocumentsTable();
      } catch (e) {
        handleError(e);
      }
    },
    [refreshDocumentsTable],
  );

  const columns = useMemo(() => {
    return [
      {
        title: t('documentsTable.name'),
        dataIndex: 'Name',
        key: 'Name',
        sorter: (a: TDocument, b: TDocument) => a?.Name?.localeCompare(b?.Name),
        render: (Name: string, record: TDocument) => (
          <p
            className="action-string"
            onClick={() => {
              downloadFile(
                id,
                record.Name,
                record.Extension ?? 'txt',
                record.Id,
              );
            }}
          >
            {record.Name} ({record.Extension ?? 'txt'})
          </p>
        ),
      },
      {
        title: t('coatingStatistic.version'),
        dataIndex: 'Version',
        key: 'Version',
        sorter: (a: TDocument, b: TDocument) => a?.Version - b?.Version,
        render: (Version: number, { Id }: TDocument) => (
          <Button
            type="link"
            onClick={() => {
              openModal();
              selectDocument(Id);
            }}
          >
            {Version}
          </Button>
        ),
      },
      {
        title: t('documentsTable.dateOfSigning'),
        dataIndex: 'SigningDate',
        key: 'SigningDate',
        width: '15%',
        sorter: (a: TDocument, b: TDocument) =>
          a?.LoadingDate?.localeCompare(b?.LoadingDate),
        render: (SigningDate: string) =>
          isNull(SigningDate) || SigningDate === '0001-01-01T00:00:00'
            ? '-'
            : moment(SigningDate).format('DD.MM.YYYY'),
      },
      {
        title: t('documentsTable.updateDate'),
        dataIndex: 'UpdateDate',
        key: 'Date',
        width: '15%',
        sorter: (a: TDocument, b: TDocument) =>
          a?.LoadingDate?.localeCompare(b?.LoadingDate),
        render: (UpdateDate: string) => {
          return UpdateDate ? moment(UpdateDate).format('DD.MM.YYYY') : '-';
        },
      },
      {
        title: t('documentsTable.type'),
        dataIndex: 'Type',
        key: 'Type',
        render: (type: DocumentType) =>
          getDocumentTypeTranslated(type, language),
      },
      {
        title: t('documentsTable.agreed'),
        dataIndex: 'Agreed',
        key: 'Agreed',
        width: '5%',
        sorter: (a: TDocument, b: TDocument) =>
          Number(!a.Agreed) - Number(!b.Agreed),
        render: (Agreed: boolean, record: TDocument) => {
          return (
            <Checkbox
              disabled={!canBrokerEdit(counterpartyType, contractStatus)}
              onChange={() => {
                onFinishEditing({ ...record, Agreed: !Agreed }).then(
                  refreshDocumentsTable,
                );
              }}
              checked={Agreed}
            />
          );
        },
      },
      ...[
        canBrokerEdit(counterpartyType, contractStatus)
          ? {
              title: '',
              dataIndex: 'actions',
              key: 'actions',
              width: isRussian(language) ? '15%' : '10%',
              render: (_: undefined, { Id }: TDocument) => {
                return (
                  <Row justify="space-around">
                    <p
                      className="custom-cell"
                      onClick={() => {
                        setSelectedDocumentId(Id);
                        setShowEditingDocumentModal(true);
                      }}
                    >
                      {t('usersTable.editAction')}
                    </p>
                    <Popconfirm
                      title={t('deleteRow')}
                      onConfirm={() => deleteRow(id, Id)}
                      okText={t('deleteOk')}
                      cancelText={t('deleteCancel')}
                    >
                      <p className="custom-cell">
                        {t('usersTable.deleteAction')}
                      </p>
                    </Popconfirm>
                  </Row>
                );
              },
            }
          : [],
      ],
    ];
  }, [
    contractStatus,
    counterpartyType,
    deleteRow,
    id,
    language,
    onFinishEditing,
    openModal,
    refreshDocumentsTable,
    selectDocument,
    t,
  ]);

  const openAddingModal = () => setShowAddingDocumentModal(true);
  const closeAddingModal = () => setShowAddingDocumentModal(false);

  const openEditingModal = () => setShowEditingDocumentModal(true);
  const closeEditingModal = () => setShowEditingDocumentModal(false);

  const isLoading = !columns || isFetching;

  const onDocumentsTableSave = useCallback(
    (record: TDocument) => {
      if (!documentsData) {
        return;
      }

      updatedDocument(id, record).then(refreshDocumentsTable);
    },
    [documentsData, id, refreshDocumentsTable],
  );

  return {
    isLoading,
    columns,
    createDocument,
    allDocumentsData,
    setAllDocumentsData,
    options,
    setOptions,
    documentsData,
    setDocumentsData,
    onDocumentsTableSave,
    showAddingDocumentModal,
    openAddingModal,
    closeAddingModal,
    showEditingDocumentModal,
    openEditingModal,
    closeEditingModal,
    onFinishAdding,
    onFinishEditing,
    selectedDocumentId,
    selectDocument: (id: number) => setSelectedDocumentId(id),
    refreshDocumentsTable,
  };
};
