import { FC, ReactElement, useMemo } from 'react';
import { Select } from 'antd';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { useTranslation } from 'react-i18next';

import { useCustomSelect } from './useCustomSelect';
import { CustomSelectProps, SelectTypes } from 'types/select';
import { DebounceSelect } from '../DebounceSelect';
import { useBem } from 'shared/hooks';

import './CustomSelect.sass';

export const CustomSelect: FC<CustomSelectProps> = ({
  record,
  dataIndex,
  type,
  onSave,
  toggleEdit,
}): ReactElement => {
  const {
    selectOptions,
    renderLabelName,
    fetchOptionsBySelectType,
  } = useCustomSelect(type);
  const { t } = useTranslation();
  const bem = useBem('CustomSelect');
  const changedDataIndex = dataIndex === 'planeType' ? 'TypeId' : dataIndex;

  const searchProps = useMemo(
    () =>
      type &&
      [SelectTypes.COUNTRY, SelectTypes.SEARCH, SelectTypes.MODELS].includes(
        type,
      )
        ? {
            showSearch: true,
            filterOption: (
              input: string,
              option: OptionData | OptionGroupData | undefined,
            ) =>
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
              0,
          }
        : {},
    [type],
  );

  if (SelectTypes.POINT === type) {
    return (
      <DebounceSelect
        className={bem()}
        showSearch
        autoFocus
        fetchOptions={fetchOptionsBySelectType[type as string]}
        onChange={({ value }: { value: string }) => {
          onSave(value, changedDataIndex);
        }}
        onBlur={() => toggleEdit?.()}
      />
    );
  }

  return (
    <Select
      className={bem()}
      {...searchProps}
      autoFocus
      defaultValue={record[changedDataIndex]}
      onChange={(value: string) => {
        onSave(value, changedDataIndex);
      }}
      onBlur={() => toggleEdit?.()}
    >
      {type ? (
        selectOptions?.map((item: any) => (
          <Select.Option key={item.Id} value={item.Id}>
            {renderLabelName(item)}
          </Select.Option>
        ))
      ) : (
        <>
          <Select.Option key={1} value={1}>
            {t('routesPage.local')}
          </Select.Option>
          <Select.Option key={2} value={2}>
            {t('routesPage.international')}
          </Select.Option>
        </>
      )}
    </Select>
  );
};
