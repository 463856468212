import { FC, ReactElement } from 'react';
import { useBem } from 'shared/hooks';

import './Preloader.sass';

export const Preloader: FC = (): ReactElement => {
  const bem = useBem('Preloader');
  return (
    <div className={bem('wrapper')}>
      <div className="loader">
        <span />
      </div>
    </div>
  );
};
