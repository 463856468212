import { FC, ReactElement } from 'react';
import moment from 'moment';

import { Plane } from 'types/planes';
import { formatMoney } from 'shared/utils/formatMoney';
import { useTranslation } from 'react-i18next';
import { isRussian } from 'shared/utils/isRussian';

type GeneralInfoProps = {
  info?: Plane;
};

export const GeneralInfo: FC<GeneralInfoProps> = ({ info }): ReactElement => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="info-group">
        <p>
          {t('detailedAircraft.type')}:{' '}
          {isRussian(i18n.language) ? 'ВС' : 'Aircraft'}
        </p>
        <p>
          {t('detailedAircraft.model')}: {info?.TypeName}
        </p>
        <p>
          {t('detailedAircraft.planeNumber')}: {info?.RegisterNumber}
        </p>
        <p>
          {t('detailedAircraft.factoryNumber')}: {info?.FactoryNumber}
        </p>
      </div>
      <div className="info-group">
        <p>
          {t('detailedAircraft.dateOfDelivery')}:{' '}
          {moment(info?.DateOfDelivery).format('DD.MM.YYYY')}
        </p>
        <p>
          {t('detailedAircraft.dateOfDisposal')}:{' '}
          {info?.DateOfDisposal ? (
            moment(info?.DateOfDisposal).format('DD.MM.YYYY')
          ) : (
            <span className="empty-string">{t('emptyValues.value')}</span>
          )}
        </p>
        <p>
          {t('detailedAircraft.placeAmount')}: {info?.PlacesAmount}
        </p>
      </div>
      <div className="info-group">
        <p>
          {t('detailedAircraft.combinedLimit')}:{' '}
          {formatMoney(info?.CombineLimit ?? 0)}
        </p>
      </div>
    </>
  );
};
