import { FC, ReactElement } from 'react';
import { Spin, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import { useBem, useCountries } from 'shared/hooks';
import { Requisites } from 'shared/components';
import { EditingCompanyInfo, UsersTable } from './components';
import { Params } from 'types/common';
import { useAboutCompany } from './useAboutCompany';

import './AboutCompanyPage.sass';

const { TabPane } = Tabs;
const { Title } = Typography;

export const AboutCompanyPage: FC = (): ReactElement => {
  const { countryList } = useCountries();
  const { companyId } = useParams<Params>();
  const {
    companyData,
    showEditingForm,
    isFetching,
    openEditingForm,
    closeEditingForm,
  } = useAboutCompany(companyId);
  const { t } = useTranslation();
  const bem = useBem('AboutCompanyPage');

  const isLoading = !countryList || isFetching;

  if (isLoading) {
    return <Spin />;
  }

  if (showEditingForm) {
    return <EditingCompanyInfo close={closeEditingForm} />;
  }

  return (
    <>
      <div className={bem('title')}>
        <Title level={2}>{t('aboutCompanyPage.title')}</Title>
        {!companyId && (
          <button className="button" onClick={openEditingForm}>
            <EditOutlined className={bem('edit-icon')} />
          </button>
        )}
      </div>
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab={t('detailedInfoTabs.requisitesRu')} key="1">
          <Requisites
            countryList={countryList}
            data={companyData}
            language="ru"
          />
        </TabPane>
        <TabPane tab={t('detailedInfoTabs.requisitesEng')} key="2">
          <Requisites
            countryList={countryList}
            data={companyData}
            language="eng"
          />
        </TabPane>
        {!companyId && (
          <TabPane tab={t('detailedInfoTabs.users')} key="3">
            <UsersTable />
          </TabPane>
        )}
      </Tabs>
    </>
  );
};
