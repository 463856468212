import { Input, Form, InputNumber } from 'antd';
import { FC, useState, useRef, useContext, useEffect } from 'react';
import { EditableContext } from './EditableRow';

export const EditableCell: FC<any> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  onSave,
  typeCell,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing && typeCell !== 'inputNumber') {
      inputRef.current!.focus();
    }
  }, [editing, typeCell]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      onSave({ record, values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
          },
        ]}
      >
        {typeCell === 'inputNumber' ? (
          <InputNumber
            defaultValue={record[dataIndex].Value}
            controls={false}
            onPressEnter={save}
            onBlur={save}
            stringMode
            min={0}
          />
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
