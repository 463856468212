export const pForm = (count: number) => {
  const lastNumber = count % 10;
  const lastNumbers = count % 100;

  if (lastNumber === 1 && lastNumbers !== 11) return 'one';
  if (
    lastNumber > 1 &&
    lastNumbers < 5 &&
    (lastNumbers < 10 || lastNumbers > 20)
  ) {
    return 'few';
  }
  return 'many';
};
