import React from 'react';
import { useBem } from 'shared/hooks';
import { useReinsurerPage } from 'pages/ReinsurerPage/useReinsurerPage';
import { SpinWrapper } from 'shared/components';
import { Table } from 'antd';
import { ReinsuranceContractDto } from 'types/insuranceContracts';
import { ReinsurerRequestModal } from 'pages/ReinsurerPage/components/ReinsurerRequestModal';

export const ReinsurerPage: React.FC = () => {
  const bem = useBem('ReinsurerPage');
  const {
    contracts,
    isFetching,
    columns,
    modalData,
    closeModal,
    refreshReinsuranceContracts,
  } = useReinsurerPage();

  return (
    <div className={bem()}>
      <SpinWrapper isLoading={isFetching}>
        <Table
          rowKey="Id"
          columns={columns}
          dataSource={contracts as ReinsuranceContractDto[]}
        />
      </SpinWrapper>
      {!!modalData && (
        <ReinsurerRequestModal
          refreshContracts={refreshReinsuranceContracts}
          contract={modalData}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};
