import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { insuranceContracts } from 'store';

export const useInsuranceContracts = () => {
  const dispatch = useDispatch();

  const { data: contracts, isFetching, error } = useSelector(
    insuranceContracts.selector.state,
  );

  const refreshInsuranceContracts = useCallback(() => {
    dispatch(insuranceContracts.thunk.request());
  }, [dispatch]);

  const deleteInsuranceContract = useCallback(
    (insuranceContractId: string) => {
      dispatch(insuranceContracts.thunk.delete(insuranceContractId));
    },
    [dispatch],
  );

  const resetInsuranceContracts = useCallback(() => {
    dispatch(insuranceContracts.action.reset());
  }, [dispatch]);

  useEffect(() => {
    if (!contracts && !isFetching && !error) {
      dispatch(insuranceContracts.thunk.request());
    }
  }, [contracts, dispatch, isFetching, error]);

  return {
    refreshInsuranceContracts,
    deleteInsuranceContract,
    contracts,
    isFetching,
    resetInsuranceContracts,
  };
};
