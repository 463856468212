import { combineReducers } from '@reduxjs/toolkit';
import {
  authReducer,
  countries,
  insuranceContracts,
  insurers,
  reinsuranceContracts,
  search,
} from './slices';

export const rootReducer = combineReducers({
  authReducer,
  insuranceContracts: insuranceContracts.reducer,
  countries: countries.reducer,
  insurers: insurers.reducer,
  reinsuranceContracts: reinsuranceContracts.reducer,
  search: search.reducer,
});
