import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useUserTable = () => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        title: t('usersTable.login'),
        dataIndex: 'Email',
        key: 'Email',
        width: '15%',
      },
      {
        title: t('usersTable.fullname'),
        dataIndex: 'Name',
        key: 'Name',
        width: '50%',
      },
    ],
    [t],
  );

  return {
    columns,
  };
};
