import {
  ReinsuranceContractDto,
  ReinsuranceContractStatus,
  ReinsuranceStatusDto,
} from 'types/insuranceContracts';
import { api } from 'api';
import { Button, notification } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isRussian } from 'shared/utils/isRussian';
import moment from 'moment';
import { saveData } from 'shared/utils/fileSaver';
import { getReinsuranceContractStatus } from 'shared/utils/getReinsuranceContractStatus';
import { handleError } from 'shared/utils/validation';

const requestAvailableStatuses = async (id: number) => {
  try {
    const { data } = await api.get<ReinsuranceContractStatus[]>(
      `ReinsuranceContracts/${id}/status/available`,
    );

    return data;
  } catch (e) {
    notification.error({ message: e.response?.data?.Message });

    throw e;
  }
};

const postReinsurerContractStatus = async (id: number, data: FormData) => {
  try {
    await api.post(`ReinsuranceContracts/${id}/status`, data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    return data;
  } catch (e) {
    notification.error({ message: e.response?.data?.Message });

    throw e;
  }
};

export const useReinsurerRequestModal = (
  contract: ReinsuranceContractDto,
  closeModal: () => void,
  refreshContracts: () => void,
) => {
  const [availableStatuses, setAvailableStatuses] = useState<
    ReinsuranceContractStatus[]
  >();
  const [historyData, setHistoryData] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(true);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const requestHistory = useCallback(async (id: number) => {
    try {
      const { data } = await api.get(`/ReinsuranceContracts/${id}/status`);
      return data;
    } catch (e) {
      handleError(e);
    }
  }, []);

  const downloadDocument = (url: string, fileName: string) => {
    api.get(url, { responseType: 'blob' }).then(({ data }) => {
      const blob = new Blob([data]);
      saveData(blob, fileName);
    });
  };

  const generateDocumentLink = (
    reinsuranceContractId: number,
    statusId: string,
  ) =>
    `ReinsuranceContracts/${reinsuranceContractId}/status/${statusId}/download`;

  const columns = useMemo(
    () => [
      {
        title: t('changingContractStatusModal.table.prevState'),
        dataIndex: 'PrevStatus',
        key: 'PrevStatus',
        render: (value: ReinsuranceContractStatus) =>
          getReinsuranceContractStatus(value, language),
      },
      {
        title: t('changingContractStatusModal.table.currState'),
        dataIndex: 'CurrStatus',
        key: 'CurrStatus',
        render: (value: ReinsuranceContractStatus) =>
          getReinsuranceContractStatus(value, language),
      },
      {
        title: t('changingContractStatusModal.table.share'),
        dataIndex: 'Share',
        key: 'Share',
        render: (Share: number) => `${Share ?? 0} %`,
      },
      {
        title: t('changingContractStatusModal.table.premium'),
        dataIndex: 'Premium',
        key: 'Premium',
        render: (Premium: number) => `${Premium ?? 0} $`,
      },
      {
        title: t('changingContractStatusModal.table.comment'),
        dataIndex: 'Comment',
        key: 'Comment',
        render: (Comment: string) => Comment || '-',
      },
      {
        title: t('changingContractStatusModal.table.updatedDate'),
        dataIndex: 'UpdateDate',
        key: 'UpdateDate',
        render: (UpdateDate: string) =>
          UpdateDate ? moment(UpdateDate).format('DD.MM.YYYY') : '-',
      },
      {
        title: t('coatingStatistic.version'),
        dataIndex: 'DocumentVersion',
        key: 'DocumentVersion',
      },
      {
        title: t('coatingStatistic.documents'),
        key: 'download',
        render: (_: undefined, coverageItem: ReinsuranceStatusDto) => {
          const downloadUrl = generateDocumentLink(
            contract.Id,
            String(coverageItem.Id),
          );

          const fileName = `${coverageItem.UserName.replace(' ', '_')}${
            coverageItem.DocumentExtension
          }`;

          const onClick = () => downloadDocument(downloadUrl, fileName);

          return (
            <Button onClick={onClick}>
              {isRussian(language) ? 'Скачать' : 'Download'}
            </Button>
          );
        },
      },
    ],
    [contract, language, t],
  );

  useEffect(() => {
    requestHistory(contract.Id).then(setHistoryData);
    requestAvailableStatuses(contract.Id).then(data => {
      setAvailableStatuses(data);
      setLoading(false);
    });
  }, [contract, requestHistory]);

  const insuredNameField = useMemo((): keyof ReinsuranceContractDto => {
    const field = isRussian(language) ? 'InsuredNameRu' : 'InsuredNameEn';

    return field as keyof ReinsuranceContractDto;
  }, [language]);

  const onSubmit = useCallback(
    (data: any) => {
      const formData = new FormData();
      formData.append('Share', data.Share || '');
      formData.append('Premium', data.Premium || '');
      formData.append('Status', data.Status);
      formData.append('Comment', data.Comment || '');
      console.log(data);
      if (data.File?.[0]?.originFileObj) {
        formData.append('File', data.File[0]?.originFileObj);
      }

      setLoading(true);
      postReinsurerContractStatus(contract.Id, formData)
        .then(() => {
          setLoading(false);
          refreshContracts();
          closeModal();
        })
        .catch(e => handleError(e));
    },
    [refreshContracts, closeModal, contract.Id],
  );

  return {
    availableStatuses,
    insuredNameField,
    isLoading,
    onSubmit,
    historyData,
    columns,
  };
};
