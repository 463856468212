import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';

import {
  InsurancePage,
  AboutCompanyPage,
  AircraftTablePage,
  LossesPage,
  RoutesPage,
  DetailedAircraftInfoPage,
  PilotsPage,
  ExploitationPage,
  AuthorizationPage,
  RegistrationPage,
  ApproveRegisterPage,
} from './pages';
import { Sidebar } from 'shared/components';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store';
import { useEffect } from 'react';
import { getAuthToken } from 'shared/utils/getAuthToken';
import { authUser } from 'store/slices';
import { ReinsurerPage } from 'pages';

export const Routes = () => {
  const { isAuth } = useSelector((state: ReduxState) => state.authReducer);
  const dispatch = useDispatch();
  const { Sider, Content } = Layout;

  useEffect(() => {
    if (getAuthToken()) {
      dispatch(authUser(true));
    }
  }, [dispatch, isAuth]);

  if (!getAuthToken()) {
    return (
      <Switch>
        <Route exact path="/auth" component={AuthorizationPage} />
        <Route exact path="/register" component={RegistrationPage} />
        <Route
          exact
          path="/confirm=:confirmCode"
          component={ApproveRegisterPage}
        />
        <Route render={() => <Redirect push to="/auth" />} />
      </Switch>
    );
  }

  return (
    <Layout>
      <Sider width={300}>
        <Sidebar />
      </Sider>
      <Layout>
        <Content style={{ height: '100vh', overflowY: 'auto' }}>
          <Switch>
            <Route exact path="/auth">
              <Redirect push to="/about-company" />
            </Route>
            <Route exact path="/request=:requestId" component={InsurancePage} />
            <Route path="/flot/page=:page" component={AircraftTablePage} />
            <Route exact path="/losses/page=:page" component={LossesPage} />
            <Route exact path="/pilots/page=:page" component={PilotsPage} />
            <Route
              exact
              path="/flot/:planeId"
              component={DetailedAircraftInfoPage}
            />
            <Route exact path="/routes/page=:page" component={RoutesPage} />
            <Route
              exact
              path="/exploitation/page=:page"
              component={ExploitationPage}
            />
            <Route exact path="/about-company" component={AboutCompanyPage} />
            <Route
              exact
              path="/company=:companyId"
              component={AboutCompanyPage}
            />
            <Route exact path="/coverage-requests" component={ReinsurerPage} />
            <Route exact path="/">
              <Redirect to="/about-company" />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};
