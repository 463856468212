import { FC, ReactElement, useState } from 'react';
import { Button, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { SpinWrapper, UploadingModal } from 'shared/components';
import { useDocumentsTable } from './useDocumentsTable';

export const DocumentsTable: FC = (): ReactElement => {
  const [showUploadingModal, setShowUploadingModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const { documentsList, isLoading, columns, onFinish } = useDocumentsTable(t);

  return (
    <SpinWrapper isLoading={isLoading}>
      <Row justify="end">
        <Button onClick={() => setShowUploadingModal(true)} type="primary">
          {t('add')}
        </Button>
      </Row>
      <Table
        style={{ marginTop: 20 }}
        pagination={false}
        dataSource={documentsList}
        columns={columns}
      />
      <UploadingModal
        onFinish={onFinish}
        visible={showUploadingModal}
        close={() => setShowUploadingModal(false)}
        title={t('uploadDocumentTitle')}
      />
    </SpinWrapper>
  );
};
