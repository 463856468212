import { FC, ReactElement, useMemo, useState } from 'react';
import { Button, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import {
  SearchInput,
  EditableTable,
  TableForm,
  columnsToFormFields,
} from 'shared/components';
import { PROFILE_EXPLOITATION } from 'api/endpoints';
import { useExploitation } from './useExploitation';
import { useSearch } from '../../shared/hooks/useSearch';

const { Title } = Typography;

export type Exploitation = {
  Year: number;
  Quarter: number;
  PassengersAmount: number;
  FlightsAmount: number;
  FlyingHours: number;
  Loading: number;
  Id?: number;
  UpdateDate?: string;
  date?: string;
  DateString?: string;
  CreatedDate?: string;
};

export const ExploitationPage: FC = (): ReactElement => {
  const { data: exploitationData, refreshSearch, isFetching } = useSearch(
    PROFILE_EXPLOITATION,
  );
  const dataSource = useMemo(
    () => (isFetching ? [] : exploitationData?.Items),
    [exploitationData?.Items, isFetching],
  );
  const { t } = useTranslation();
  const { columns, onSubmit } = useExploitation(refreshSearch);
  const [showForm, setShowForm] = useState<boolean>(false);

  const closeForm = () => {
    setShowForm(false);
  };

  return (
    <>
      <Title level={2}>
        <Trans i18nKey="exploitationPage.title">Exploitation</Trans>
      </Title>
      <Row justify="space-between">
        <SearchInput url={PROFILE_EXPLOITATION} />
        <Button
          className="adding-button"
          onClick={() => setShowForm(true)}
          type="primary"
        >
          {t('add')}
        </Button>
      </Row>
      {showForm && (
        <TableForm
          fields={columns.map(columnsToFormFields)}
          closeForm={closeForm}
          onSubmit={values => onSubmit(values, closeForm)}
        />
      )}
      <EditableTable
        loading={isFetching}
        saveEndpoint="/Exploitation"
        dataSourceProps={dataSource}
        columns={columns}
        afterSave={refreshSearch}
        totalItems={exploitationData?.TotalItems}
      />
    </>
  );
};
