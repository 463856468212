import { useDispatch, useSelector } from 'react-redux';
import { insurers } from 'store';
import { useEffect } from 'react';
import { CounterpartyType } from 'types/counterparties';

export const useInsurers = () => {
  const dispatch = useDispatch();

  const { data: insurerList, isFetching } = useSelector(
    insurers.selector.state,
  );

  useEffect(() => {
    if (!insurerList && !isFetching) {
      dispatch(insurers.thunk.request({ type: CounterpartyType.Insurer }));
    }
  }, [insurerList, isFetching, dispatch]);

  return {
    insurerList,
    isFetching,
  };
};
