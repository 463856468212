import { FC, ReactElement } from 'react';

import { useParams } from 'react-router';
import { Params } from 'types/common';
import { EditableStatisticTable, SpinWrapper } from 'shared/components';
import { useRoutesStatistic } from './useRoutesStatistic';
import { InsuranceTabProps } from 'types/insuranceContracts';

export const RoutesStatistic: FC<InsuranceTabProps> = ({
  contractStatus,
}): ReactElement => {
  const { requestId } = useParams<Params>();

  const { columns, routeData, isLoading, onRouteSave } = useRoutesStatistic(
    requestId,
    contractStatus,
  );

  return (
    <SpinWrapper isLoading={isLoading}>
      <EditableStatisticTable
        onSave={onRouteSave}
        dataSourceProps={routeData}
        columns={columns}
      />
    </SpinWrapper>
  );
};
