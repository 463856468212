export const PROFILE = '/Profile';
export const PROFILE_PLANES = `${PROFILE}/planes/paginated`;
export const PROFILE_PILOTS = `${PROFILE}/pilots/paginated`;
export const PROFILE_ROUTES = `${PROFILE}/routes/paginated`;
export const PROFILE_EXPLOITATION = `${PROFILE}/exploitation/paginated`;
export const PROFILE_USERS = `/Users`;
export const PROFILE_SERVICE = `${PROFILE}/maintenance/paginated`;
export const PROFILE_TRAININGS = `${PROFILE}/trainings/paginated`;
export const PROFILE_LOSSES = `${PROFILE}/losses/paginated`;

export const insuranceUrls = (
  contractId: string | undefined,
  entity: string,
  id?: number | string,
) => {
  if (contractId && entity) {
    if (id) {
      return `/InsuranceContracts/${contractId}/${entity}/${id}`;
    }
    return `/InsuranceContracts/${contractId}/${entity}`;
  }
};
