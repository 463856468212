import { FC, ReactElement, useEffect, useState } from 'react';
import { Button, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import {
  columnsToFormFields,
  EditableTable,
  SearchInput,
  TableForm,
} from 'shared/components';
import { useTrainingPage } from './useTrainingPage';
import { PROFILE_TRAININGS } from 'api/endpoints';
import { useSearch } from 'shared/hooks/useSearch';
import { Params, TypeItem } from 'types/common';
import { useParams } from 'react-router-dom';
import { api } from 'api';
import { handleError } from 'shared/utils/validation';

export type Training = {
  Id: number;
  Company: string;
  CountryId: number;
  CountryNameRu?: string;
  CountryNameEn?: string;
  Planes: TypeItem[];
  PlanesNames?: string;
  Name: string;
  PeriodicyId: number;
  PeriodicyNameRu: string | null;
  PeriodicyNameEn: string | null;
};

type TrainingPageProps = {
  showTitle?: boolean;
  isEditable?: boolean;
};

const { Title } = Typography;

const getTrainings = async (insuranceContractId: string) => {
  try {
    const { data } = await api.get<Training[]>(
      `/InsuranceContracts/${insuranceContractId}/trainings`,
    );
    return data;
  } catch (e) {
    handleError(e);
  }
};

export const TrainingPage: FC<TrainingPageProps> = ({
  showTitle,
  isEditable,
}): ReactElement => {
  const { requestId } = useParams<Params>();
  const [trainings, setTrainings] = useState<Training[]>();
  const { data: trainingData, refreshSearch, isFetching } = useSearch(
    PROFILE_TRAININGS,
  );
  const [showForm, setShowForm] = useState<boolean>(false);
  const { t } = useTranslation();
  const { columns, onTrainingSave } = useTrainingPage(
    refreshSearch,
    isEditable,
  );

  useEffect(() => {
    if (requestId) {
      getTrainings(requestId).then(setTrainings);
    } else {
      refreshSearch();
    }
  }, [refreshSearch, requestId]);

  const dataSource = requestId
    ? trainings
    : trainingData?.Items ?? (trainingData as any);

  const openForm = () => {
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  return (
    <>
      {showTitle && (
        <Title level={2}>
          <Trans i18nKey="trainingPage.title">Trainings</Trans>
        </Title>
      )}
      <Row justify="space-between">
        {!requestId && (
          <>
            <SearchInput
              url={
                requestId
                  ? `/InsuranceContracts/${requestId}/trainings`
                  : PROFILE_TRAININGS
              }
            />
            <Button onClick={openForm} className="adding-button" type="primary">
              {t('add')}
            </Button>
          </>
        )}
      </Row>
      {showForm && (
        <TableForm
          fields={columns.map(columnsToFormFields)}
          closeForm={closeForm}
          onSubmit={onTrainingSave}
        />
      )}
      <EditableTable
        saveEndpoint="/Trainings"
        loading={isFetching}
        dataSourceProps={dataSource}
        columns={columns}
        onSave={onTrainingSave}
        afterSave={refreshSearch}
        totalItems={trainingData?.TotalItems}
      />
    </>
  );
};

TrainingPage.defaultProps = {
  showTitle: true,
  isEditable: true,
};
