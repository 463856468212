import { FC, ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { Params } from 'types/common';
import { useExploitationStatistic } from './useExploitation';
import { EditableStatisticTable, SpinWrapper } from 'shared/components';
import { InsuranceTabProps } from 'types/insuranceContracts';

export const ExploitationStatistic: FC<InsuranceTabProps> = ({
  contractStatus,
}): ReactElement => {
  const { requestId } = useParams<Params>();

  const {
    columns,
    exploitationData,
    isLoading,
    onExploitationSave,
  } = useExploitationStatistic(requestId, contractStatus);

  return (
    <SpinWrapper isLoading={isLoading}>
      <EditableStatisticTable
        onSave={onExploitationSave}
        dataSourceProps={exploitationData}
        columns={columns}
      />
    </SpinWrapper>
  );
};
