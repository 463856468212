import { api } from 'api';
import { useEffect, useState, useCallback } from 'react';
import { CombineLimit } from 'types/generalDetails';
import { handleError } from 'shared/utils/validation';

const requestCombineLimits = async (id: string) => {
  try {
    const { data } = await api.get(`/InsuranceContracts/${id}/combineLimits`);
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const useGeneralDetails = (id: string) => {
  const [combineLimits, setCombineLimits] = useState<CombineLimit[]>();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const isLoading = !combineLimits || isFetching;

  const refreshGeneralDetails = useCallback(() => {
    requestCombineLimits(id)
      .then(setCombineLimits)
      .finally(() => setIsFetching(false));
  }, [id]);

  useEffect(() => {
    setIsFetching(true);
    refreshGeneralDetails();
  }, [refreshGeneralDetails]);

  return {
    combineLimits,
    refreshGeneralDetails,
    isLoading,
  };
};
