import { FC, ReactElement, useMemo, useState } from 'react';
import { Button, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { EditableTable, SpinWrapper, UploadingModal } from 'shared/components';
import { Params } from 'types/common';
import { useDocumentsTable } from './useDocumentsTable';
import { EditingContractsDocumentModal } from 'shared/components';
import { InsuranceTabProps } from 'types/insuranceContracts';
import { canBrokerEdit } from 'shared/utils/checkUserRole';
import { VersionHistoryModal } from '../VersionHistoryModal';

export const DocumentsTable: FC<InsuranceTabProps> = ({
  contractStatus,
}): ReactElement => {
  const [showVersionsModal, setShowVersionsModal] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<number>();

  const openVersionModal = () => {
    setShowVersionsModal(true);
  };

  const closeVersionModal = () => {
    setSelectedDocument(undefined);
    setShowVersionsModal(false);
  };

  const selectDocument = (id: number) => {
    setSelectedDocument(id);
  };

  const { requestId } = useParams<Params>();
  const { t } = useTranslation();
  const counterpartyType = localStorage.getItem('CounterpartyType');
  const {
    isLoading,
    columns,
    documentsData,
    onDocumentsTableSave,
    showAddingDocumentModal,
    openAddingModal,
    closeAddingModal,
    showEditingDocumentModal,
    closeEditingModal,
    onFinishAdding,
    selectedDocumentId,
    refreshDocumentsTable,
  } = useDocumentsTable(
    requestId,
    selectDocument,
    openVersionModal,
    contractStatus,
  );

  const url = useMemo(() => {
    if (requestId) {
      return `/InsuranceContracts/${requestId}/documents`;
    }
  }, [requestId]);

  return (
    <>
      <SpinWrapper isLoading={isLoading}>
        <Row>
          {canBrokerEdit(counterpartyType, contractStatus) && (
            <Button
              onClick={openAddingModal}
              className="adding-button"
              type="primary"
            >
              {t('add')}
            </Button>
          )}
        </Row>
        <EditableTable
          onSave={onDocumentsTableSave}
          dataSourceProps={documentsData}
          columns={columns}
        />
        <UploadingModal
          isContract
          onFinish={onFinishAdding}
          visible={showAddingDocumentModal}
          close={closeAddingModal}
          title={t('uploadDocumentTitle')}
        />
        <EditingContractsDocumentModal
          visible={showEditingDocumentModal}
          close={closeEditingModal}
          refreshDocuments={refreshDocumentsTable}
          defaultValues={documentsData?.find(
            ({ Id }) => Id === selectedDocumentId,
          )}
        />
        <VersionHistoryModal
          documentId={selectedDocument}
          showModal={showVersionsModal}
          closeModal={closeVersionModal}
        />
      </SpinWrapper>
    </>
  );
};
