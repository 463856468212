import { CombineLimit } from './../../../../types/generalDetails';
import { InsurerFranchiseType } from 'types/insuranceContracts';

export const getFranchiseType = (
  type: InsurerFranchiseType,
  t: (text: string) => string,
) =>
  ({
    [InsurerFranchiseType.Absent]: t('franchise.absent'),
    [InsurerFranchiseType.Conditional]: t('franchise.conditional'),
    [InsurerFranchiseType.Unconditional]: t('franchise.unconditional'),
  }[type]);

export const flotCount = (combineLimits?: CombineLimit[]) =>
  combineLimits?.length
    ? combineLimits
        ?.map(({ PlanesAmount }) => PlanesAmount)
        .reduce((a, b) => a + b)
    : 0;
