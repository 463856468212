import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { useBem } from 'shared/hooks';
import { EditingForm } from '../EditingForm';
import { api } from 'api';
import { CompanyData } from 'types/company';

import './EditingCompanyInfo.sass';
import { isRussian } from 'shared/utils/isRussian';
import { handleError } from 'shared/utils/validation';
import PhotoCompany from 'shared/images/PhotoCompany.svg';
import { Breadcrumb } from 'shared/components';

type EditingCompanyInfoProps = {
  close: () => void;
};

const routes = [
  {
    path: '/about-company',
    breadcrumbName: 'Профиль авиакомпании',
  },
];

export const EditingCompanyInfo: FC<EditingCompanyInfoProps> = ({
  close,
}): ReactElement => {
  const [editableData, setEditableData] = useState<CompanyData>();
  const [formLanguage, setFormLanguage] = useState<string>('ru');
  const [formData, setFormData] = useState<any>();
  const [step, setStep] = useState<number>(1);
  const bem = useBem('EditingCompanyInfo');
  const { t } = useTranslation();
  const history = useHistory();

  const saveData = (values: CompanyData) => {
    setStep(2);
    const savedData = isRussian(formLanguage)
      ? { ru: values }
      : { eng: values };
    setFormData({ ...formData, ...savedData });
    setFormLanguage(isRussian(formLanguage) ? 'eng' : 'ru');
  };

  const onFinish = async (values: any, IsAddressEqual?: boolean) => {
    if (step === 2) {
      const combinedValues = { ...values, ...formData.ru };
      console.log(combinedValues);
      const submittedData = {
        NameRu: combinedValues.fullNameRu,
        FullNameRu: combinedValues.fullNameRu,
        NameEn: combinedValues.fullNameEn,
        FullNameEn: combinedValues.fullNameEn,
        Email: combinedValues.Email,
        Phone: combinedValues.Phone,
        Residence: editableData?.Residence,
        IsAddressEqual,
        LegalAddress: {
          CountryId: combinedValues.legalCountry,
          PostCode: combinedValues.legalIndex,
          CityRu: combinedValues.legalCityRu,
          CityEn: combinedValues.legalCityEn,
          StreetRu: combinedValues.legalStreetRu,
          StreetEn: combinedValues.legalStreetEn,
          BuildingRu: combinedValues.legalBuildingRu,
          BuildingEn: combinedValues.legalBuildingEn,
        },
        PostAddress: {
          CountryId: combinedValues.mailingCountry,
          PostCode: combinedValues.mailingIndex,
          CityRu: combinedValues.mailingCityRu,
          CityEn: combinedValues.mailingCityEn,
          StreetRu: combinedValues.mailingStreetRu,
          StreetEn: combinedValues.mailingStreetEn,
          BuildingRu: combinedValues.mailingBuildingRu,
          BuildingEn: combinedValues.mailingBuildingEn,
        },
        BankDetail: {
          BankNameRu: combinedValues.bankNameRu,
          BankNameEn: combinedValues.bankNameEn,
          AccountNumber: combinedValues.checkingAccount,
          AccountNameEn: combinedValues.AccountNameEn,
          CorrespondentAccount: combinedValues.correspondentAccount,
          Bic: combinedValues.bic,
          Inn: combinedValues.inn,
          Kpp: combinedValues.kpp,
          Ogrn: combinedValues.ogrn,
          Iban: combinedValues.Iban,
          Aba: combinedValues.Aba,
          BankAddressEn: combinedValues.BankAddressEn,
          UsdCorrespondentBank: combinedValues.UsdCorrespondentBank,
          SwiftCode: combinedValues.SwiftCode,
          AbaFedwire: combinedValues.AbaFedwire,
          Reference:
            combinedValues.Reference ?? editableData?.BankDetail.Reference,
          Text: combinedValues.Text ?? editableData?.BankDetail.Text,
        },
      };
      api
        .put('/Counterparties/profile', submittedData)
        .then(() => {
          history.push('/');
          notification.success({
            message: t('aboutCompanyPage.successfullyMessage'),
          });
        })
        .catch(handleError);
    } else {
      saveData(values);
    }
  };

  const changeLanguage = useCallback(async () => {
    setFormLanguage(isRussian(formLanguage) ? 'eng' : 'ru');
  }, [formLanguage]);

  useEffect(() => {
    api
      .get<CompanyData>('/Counterparties/profile')
      .then(({ data }) => setEditableData(data))
      .catch(handleError);
  }, []);

  return (
    <div className={bem('wrapper')}>
      <Breadcrumb routes={routes} />
      <div className={bem('info-company')}>
        <img src={PhotoCompany} alt="photo" />
        <p className={bem('title-company')}>{editableData?.FullNameRu}</p>
      </div>
      <EditingForm
        changeLanguage={changeLanguage}
        formLanguage={formLanguage}
        editableData={editableData}
        onFinish={onFinish}
        close={close}
      />
    </div>
  );
};
