import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { Result } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { SpinWrapper } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { Params } from 'types/common';
import { api } from 'api';
import { isBoolean, isUndefined } from 'lodash-es';
import { handleError } from '../../shared/utils/validation';

export const ApproveRegisterPage: FC = (): ReactElement => {
  const [isSuccessfulConfirmed, setIsSuccessfulConfirmed] = useState<boolean>();
  const { t } = useTranslation();
  const history = useHistory();
  const { confirmCode } = useParams<Params>();

  const requestConfirm = useCallback(async () => {
    try {
      await api.post(`/Account/confirm/${confirmCode}`);
      setIsSuccessfulConfirmed(true);
    } catch (e) {
      setIsSuccessfulConfirmed(false);
      handleError(e);
    }
  }, [confirmCode]);

  useEffect(() => {
    requestConfirm();
  }, [requestConfirm]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (isBoolean(isSuccessfulConfirmed) && isSuccessfulConfirmed) {
      timer = setTimeout(() => {
        history.push('/auth');
      }, 5000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [history, isSuccessfulConfirmed]);

  return (
    <SpinWrapper isLoading={isUndefined(isSuccessfulConfirmed)}>
      {isSuccessfulConfirmed ? (
        <Result
          status="success"
          title={t('register.successful.title')}
          subTitle={t('register.successful.description')}
        />
      ) : (
        <Result
          status="error"
          title={t('register.error.title')}
          subTitle={t('register.error.description')}
        />
      )}
    </SpinWrapper>
  );
};
