export type AdditionalProp = {
  Id: number;
  Value: number;
  Diff: number;
};

type ExploitationItem = {
  additionalProp1: AdditionalProp | string;
  additionalProp2: AdditionalProp | string;
  additionalProp3: AdditionalProp | string;
};

export type TExploitation = {
  PassengerCount: ExploitationItem;
  DepartureCount: ExploitationItem;
  FlyingHours: ExploitationItem;
  AvgFlightHours: ExploitationItem;
  Loading: ExploitationItem;
  StartDate: ExploitationItem;
  EndDate: ExploitationItem;
};

export enum ExploitationTypeNames {
  PassengerCount = 0,
  DepartureCount = 1,
  FlyingHours = 2,
  AvgFlightHours = 3,
  Loading = 4,
  StartDate = 5,
  EndDate = 6,
}
