import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { CustomError } from 'types/common';
import { notification } from 'antd';

export const sendErrorCode = (errorMessage: string) => {
  throw new Error(errorMessage);
};

export const isPositiveNumber = (
  t: (text: string) => string,
  rule: RuleObject,
  value: StoreValue,
  callback: (error?: string | undefined) => void,
) => {
  try {
    if (Number(value) > 0) {
      callback();
    } else {
      sendErrorCode(t('error.positiveNumber'));
    }
  } catch (err) {
    callback(err);
  }
};

export const isUploadedFile = (
  t: (text: string) => string,
  rule: RuleObject,
  values: StoreValue,
  callback: (error?: string | undefined) => void,
) => {
  try {
    if (!values.File) {
      callback();
    } else {
      sendErrorCode(t('error.emptyFile'));
    }
  } catch (e) {
    callback(e);
  }
};

export const prepareErrorMessage = (error: CustomError) => {
  if (error?.response) {
    const { Message, errors } = error?.response?.data;
    return Message ?? Object.values(errors).join('; ');
  }
};

export const handleError = (e: CustomError): never => {
  notification.error({ message: prepareErrorMessage(e) });
  throw e;
};
