import { FC, ReactElement, useState } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useBem } from 'shared/hooks';
import {
  EditableTable,
  columnsToFormFields,
  Breadcrumb,
  AddingModal,
  Button,
} from 'shared/components';
import { usePilotPage } from './usePilotPage';

import './PilotsPage.sass';
import { PlusOutlined } from '@ant-design/icons';

export type Pilots = {
  Id: number;
  CounterpartyId: number;
  PlaneTypeId: number;
  TypeId: number;
  TypeNameRu: string;
  TypeNameEn: string;
  PlaneName: string;
  CommonFlightHours: number;
  FlightByModelHours: number;
  CapitanFlightHours: number;
  CreatedDate?: string;
  Number: number;
};

const routes = [
  {
    path: '/about-company',
    breadcrumbName: 'Профиль',
  },
  {
    path: '/pilots',
    breadcrumbName: 'Пилоты',
  },
];

export const PilotsPage: FC = (): ReactElement => {
  const bem = useBem('PilotsPage');
  const { Title } = Typography;
  const { t } = useTranslation();

  const {
    statistic,
    pilotsData,
    isFetching,
    refresh,
    columns,
    onPilotSave,
  } = usePilotPage();
  const dataSource = isFetching ? [] : pilotsData?.Items;

  const [showAddingModal, setShowAddingModal] = useState<boolean>(false);

  return (
    <>
      <Breadcrumb routes={routes} />
      <div className={bem('info-container')}>
        <div className={bem('title-container')}>
          <p className={bem('title-pilots')}>{t('pilots')}</p>
          <p className={bem('plane-count')}>0</p>
        </div>
        <div className={bem('button-container')}>
          <Button
            className={bem('button-styles')}
            type="primary"
            customType="send"
            htmlType="submit"
            title="Добавить"
            onClick={() => setShowAddingModal(true)}
            icon={<PlusOutlined className={bem('plus-image')} />}
          />
        </div>
      </div>
      <EditableTable
        saveEndpoint="/Pilots"
        loading={isFetching}
        dataSourceProps={dataSource}
        columns={columns}
        onSave={onPilotSave}
        afterSave={refresh}
        totalItems={pilotsData?.TotalItems}
      />

      <AddingModal
        visible={showAddingModal}
        close={() => setShowAddingModal(false)}
        onSubmit={onPilotSave}
        fields={columns.map(columnsToFormFields)}
      />
    </>
  );
};
