import { FC, ReactElement, useState, useEffect } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { api } from 'api';
import { Params } from 'types/common';
import { isRussian } from 'shared/utils/isRussian';

export const PilotStatistic: FC = (): ReactElement => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t, i18n } = useTranslation();
  const { requestId } = useParams<Params>();
  const { language } = i18n;

  const columns: any = [
    {
      title: t('pilotsStatistic.table.position'),
      dataIndex: isRussian(language) ? 'TypeNameRu' : 'TypeNameEn',
      key: 'Type',
      render: (dataIndex: string | undefined, { TypeId }: { TypeId: number }) =>
        !TypeId ? t('lastRow') : dataIndex,
    },
    {
      title: t('pilotsStatistic.table.count'),
      dataIndex: 'Amount',
      key: 'Amount',
    },
    {
      title: t('pilotsStatistic.table.minPlaque'),
      dataIndex: 'MinFlyingHours',
      key: 'MinFlyingHours',
    },
    {
      title: t('pilotsStatistic.table.averagePlaque'),
      dataIndex: 'AvgFlyingHours',
      key: 'AvgFlyingHours',
    },

    {
      title: t('pilotsStatistic.table.maxPlaque'),
      dataIndex: 'MaxFlyingHours',
      key: 'MaxFlyingHours',
    },
    {
      title: t('pilotsStatistic.table.captainsPlaque'),
      dataIndex: 'CaptainFlyingHours',
      key: 'CaptainFlyingHours',
      render: (CaptainFlyingHours: number, { TypeId }: { TypeId: number }) => (
        <p className="cell-string">
          {renderCaptainPlaque(TypeId, CaptainFlyingHours)}
        </p>
      ),
    },
  ];

  const renderCaptainPlaque = (position: number, captainPlaque: number) => {
    if (position === 4 && captainPlaque) {
      return captainPlaque;
    }
    return '-';
  };

  useEffect(() => {
    api
      .get(`/InsuranceContracts/${requestId}/pilots`)
      .then(({ data }) => {
        const dataAmount = data.map(({ Amount }: { Amount: number }) => Amount);
        if (dataAmount.length) {
          dataAmount?.reduce((a: number, b: number) => a + b);
          setDataSource([
            ...data,
            {
              Type: 4,
              Amount: dataAmount?.reduce((a: number, b: number) => a + b),
            },
          ]);
        }
      })
      .finally(() => setIsLoading(false));
  }, [requestId]);

  return (
    <Table
      loading={isLoading}
      pagination={false}
      dataSource={dataSource}
      columns={columns}
    />
  );
};
