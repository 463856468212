import React from 'react';
import { BreadcrumbProps } from 'antd';
import { Link } from 'react-router-dom';
import { Breadcrumb as AntBreadcrumb } from 'antd';

import './Breadcrumb.sass';

interface Route {
  path: string;
  breadcrumbName: string;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  ...props
}: BreadcrumbProps) => {
  const itemRender = (route: Route, params: object, routes: Array<Route>) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={route.path}>{route.breadcrumbName}</Link>
    );
  };

  return <AntBreadcrumb separator="—" itemRender={itemRender} {...props} />;
};
