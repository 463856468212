import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSearch } from 'shared/hooks/useSearch';
import { PROFILE_LOSSES } from 'api/endpoints';
import { formatMoney } from 'shared/utils/formatMoney';
import { isRussian } from 'shared/utils/isRussian';
import { api } from 'api';
import { handleError } from 'shared/utils/validation';
import { Loss } from './LossesPage';
import { useParams } from 'react-router-dom';
import { Params } from 'types/common';

const getLosses = async (insuranceContractId: string) => {
  try {
    const { data } = await api.get<Loss[]>(
      `/InsuranceContracts/${insuranceContractId}/materiallose`,
    );
    return data;
  } catch (e) {
    handleError(e);
  }
};

const createLosses = async (createdLosses: Loss) => {
  try {
    await api.post('/Profile/losses/', createdLosses);
  } catch (e) {
    handleError(e);
  }
};

const deleteLoss = async (id: number) => {
  try {
    await api.delete(`/Profile/losses/${id}`);
  } catch (e) {
    handleError(e);
  }
};

export const useLossesPage = (showTitle?: boolean) => {
  const { requestId, page } = useParams<Params>();
  const [losses, setLosses] = useState<Loss[]>();
  const { data: lossesData, refreshSearch, isFetching } = useSearch(
    requestId
      ? `/InsuranceContracts/${requestId}/materiallose`
      : PROFILE_LOSSES,
  );

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const refresh = useCallback(
    (page: string | undefined) => {
      refreshSearch({ page });
    },
    [refreshSearch],
  );

  useEffect(() => {
    if (requestId) {
      getLosses(requestId).then(setLosses);
    } else {
      refresh(page);
    }
  }, [page, refresh, requestId]);

  const columns = useMemo(
    () => [
      {
        title: t('lossesPage.table.date'),
        dataIndex: 'Date',
        key: 'Date',
        width: '10%',
        isRequired: true,
        editable: !requestId,
        typeCell: 'datePicker',
        sorter: true,
        render: (Date: string) => moment(Date).format('DD.MM.YYYY'),
      },
      {
        title: t('lossesPage.table.theme'),
        dataIndex: 'Theme',
        key: 'Theme',
        width: '30%',
        isRequired: true,
        editable: !requestId,
        sorter: true,
      },
      {
        title: t('lossesPage.table.sum'),
        dataIndex: 'Amount',
        key: 'Amount',
        typeCell: 'inputNumber',
        width: '15%',
        isDecimal: true,
        isRequired: true,
        editable: !requestId,
        sorter: true,
        render: (Amount: number) => formatMoney(Amount),
      },
      {
        title: t('lossesPage.table.details'),
        dataIndex: 'Details',
        key: 'Details',
        width: '30%',
        isRequired: true,
        editable: !requestId,
        sorter: true,
      },
      {
        title: t('lossesPage.table.status'),
        dataIndex: 'StatusTypeId',
        key: 'StatusTypeId',
        typeCell: 'select',
        selectType: 'status',
        width: '15%',
        isRequired: true,
        editable: !requestId,
        sorter: true,
        render: (
          dataIndex: number | null,
          { StatusNameRu, StatusNameEn }: Loss,
        ) => {
          return isRussian(language) ? StatusNameRu : StatusNameEn;
        },
      },
      ...[
        showTitle
          ? {
              title: '',
              dataIndex: 'actions',
              width: '5%',
              key: 'actions',
              render: (_: undefined, { Id }: Loss) => {
                return (
                  <Popconfirm
                    title={t('deleteRow')}
                    onConfirm={() => deleteLoss(Id).then(() => refresh(page))}
                    okText={t('deleteOk')}
                    cancelText={t('deleteCancel')}
                  >
                    <p className="custom-cell">
                      {t('usersTable.deleteAction')}
                    </p>
                  </Popconfirm>
                );
              },
            }
          : {},
      ],
    ],
    [language, page, refresh, requestId, showTitle, t],
  );

  const onSubmit = useCallback(
    (createdLoss: Loss) => {
      createLosses(createdLoss).then(() => refresh(page));
    },
    [page, refresh],
  );

  useEffect(() => {
    refresh(page);
  }, [page, refresh]);

  return {
    lossesData,
    columns,
    onSubmit,
    refreshSearch,
    isFetching,
  };
};
