import { FormInstance, Select } from 'antd';
import { api } from 'api';
import { FC, ReactElement, useEffect, useMemo, useState } from 'react';

import { TypeItem } from 'types/common';
import { useTranslation } from 'react-i18next';
import { useBem } from 'shared/hooks';

import './CustomMultiselect.sass';

type CustomMultiselectProps = {
  record: any;
  dataIndex: string;
  save: any;
  multiType: string;
  toggleEdit?: () => void;
  form?: FormInstance | null;
  adding?: boolean;
};

export const CustomMultiselect: FC<CustomMultiselectProps> = ({
  record,
  dataIndex,
  save,
  multiType,
  form,
  adding,
}): ReactElement => {
  const [result, setResult] = useState<number[]>();
  const [planeTypesList, setPlaneTypesList] = useState<TypeItem[]>([]);
  const [serviceTypesList, setServiceTypesList] = useState<TypeItem[]>([]);
  const { t } = useTranslation();
  const { Option } = Select;
  const bem = useBem('CustomMultiselect');

  useEffect(() => {
    api.get('/PlaneTypes').then(({ data }) => {
      setPlaneTypesList([...data]);
    });
    api.get('/ServiceTypes').then(({ data }) => setServiceTypesList(data));
  }, []);

  const keyTypes: { [key: string]: string } = useMemo(
    () => ({
      PlaneTypeIds: 'Planes',
      ServiceTypeIds: 'Services',
    }),
    [],
  );

  const preparedData = useMemo(() => {
    return record[keyTypes[dataIndex]]?.map(({ Id }: TypeItem) => Id);
  }, [dataIndex, keyTypes, record]);

  useEffect(() => {
    if (preparedData) {
      form?.setFieldsValue({ [dataIndex]: preparedData });
    }
  }, [dataIndex, form, preparedData]);

  return (
    <Select
      className={bem()}
      autoFocus
      mode="multiple"
      placeholder={t('selectType')}
      defaultValue={preparedData}
      optionFilterProp="children"
      onChange={values => {
        setResult(values);
      }}
      onBlur={() => {
        if (adding) {
          save(result, dataIndex);
        } else {
          save(result, multiType === 'work' ? 'Services' : 'Planes');
        }
      }}
    >
      {multiType !== 'work'
        ? planeTypesList.map(({ Id, Name }) => (
            <Option value={Id}>{Name}</Option>
          ))
        : serviceTypesList.map(({ Id, Name }) => (
            <Option value={Id}>{Name}</Option>
          ))}
    </Select>
  );
};
