import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { Params } from 'types/common';
import { DocumentType, TDocument } from 'types/InsuredDocuments';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'shared/utils/fileSaver';
import { handleError } from 'shared/utils/validation';

const requestVersions = async (contractId: string, documentId?: number) => {
  try {
    const { data } = await api.get(
      `/InsuranceContracts/${contractId}/documents/${documentId}/versions`,
    );
    return data;
  } catch (e) {
    handleError(e);
  }
};

export const useVersionHistoryModal = (
  closeModal: () => void,
  documentId?: number,
) => {
  const [versionData, setVersionsData] = useState<any>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { requestId } = useParams<Params>();
  const { t } = useTranslation();

  const documentTypes: { [key: number]: string } = useMemo(
    () => ({
      [DocumentType.DebetNote]: t('lossesPage.options.debetNote'),
      [DocumentType.CoverNote]: t('lossesPage.options.coverNote'),
      [DocumentType.InsuranceContract]: t(
        'lossesPage.options.insuranceContract',
      ),
      [DocumentType.Other]: t('lossesPage.options.other'),
    }),
    [t],
  );

  const refreshVersionModal = useCallback(() => {
    requestVersions(requestId, documentId)
      .then(data => {
        setVersionsData(data);
      })
      .finally(() => setIsFetching(false));
  }, [documentId, requestId]);

  useEffect(() => {
    if (documentId) {
      setIsFetching(true);
      refreshVersionModal();
    }
  }, [documentId, refreshVersionModal]);

  const columns = [
    {
      title: t('versionHistoryModal.type'),
      dataIndex: 'Type',
      key: 'Type',
      render: (Type: number) => documentTypes[Type],
    },
    {
      title: t('versionHistoryModal.version'),
      dataIndex: 'Version',
      key: 'Version',
    },
    {
      title: t('versionHistoryModal.name'),
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: t('versionHistoryModal.action'),
      dataIndex: 'action',
      key: 'action',
      render: (
        _: undefined,
        { Name, Extension, Version }: Omit<TDocument, 'Agreed'>,
      ) => (
        <Button
          onClick={() =>
            downloadFile(requestId, Name, Extension, documentId, Version)
          }
        >
          {t('download')}
        </Button>
      ),
    },
  ];

  const isLoading = isFetching || !versionData;

  return {
    versionData,
    columns,
    isLoading,
  };
};
