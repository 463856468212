import { useHistory } from 'react-router-dom';
import { useCountries, useInsuranceContracts } from 'shared/hooks';
import { api } from 'api';
import {
  InsuranceContractBlank,
  InsuranceContractCreateRequest,
  InsurancePeriodType,
} from 'types/insuranceContracts';
import { useEffect, useState } from 'react';
import { InsuranceContractCreateForm } from './CreateContractModal';
import moment from 'moment';
import { handleError } from 'shared/utils/validation';

const requestContractBlank = async () => {
  const { data } = await api.get<InsuranceContractBlank>(
    'InsuranceContracts/blank',
  );

  return data;
};

const postInsuranceContract = async (
  contract: InsuranceContractCreateRequest,
) => {
  try {
    const { data } = await api.post('InsuranceContracts', contract);
    return data;
  } catch (e) {
    handleError(e);
  }
};

export const useCreateContractModal = (closeModal: () => void) => {
  const { countryList, isFetching: isCountriesFetching } = useCountries();
  const [contractBlank, setContractBlank] = useState<InsuranceContractBlank>();
  const { refreshInsuranceContracts } = useInsuranceContracts();
  const [isLoading, setIsLoading] = useState(true);
  const [initialValue, setInitialValue] = useState<
    InsuranceContractCreateForm
  >();
  const history = useHistory();

  useEffect(() => {
    requestContractBlank().then(data => setContractBlank(data));
  }, []);

  useEffect(() => {
    setIsLoading(isCountriesFetching || !contractBlank);
  }, [isCountriesFetching, contractBlank]);

  useEffect(() => {
    if (countryList && contractBlank) {
      setInitialValue({
        CountryIds: [11],
        Period: InsurancePeriodType.Year,
        StartDate: moment(contractBlank.StartDate),
      });
    }
  }, [countryList, contractBlank]);

  const onSubmit = ({
    CountryIds,
    Period,
    StartDate,
  }: InsuranceContractCreateForm) => {
    const contract: InsuranceContractCreateRequest = {
      CountryIds,
      Period,
      StartDate: moment(StartDate).format('YYYY-MM-DD'),
    };
    setIsLoading(true);

    postInsuranceContract(contract)
      .then(({ Id }: { Id: number }) => {
        refreshInsuranceContracts();
        closeModal();
        history.push(`/request=${Id}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    initialValue,
    countryList,
    onSubmit,
  };
};
