import { FC, ReactElement } from 'react';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { useDebounceSelect } from './useDebounceSelect';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<any[]>;
  debounceTimeout?: number;
  passDataToHandler?: boolean;
}

export const DebounceSelect: FC<DebounceSelectProps> = (
  props,
): ReactElement => {
  const {
    i18n: { language },
  } = useTranslation();
  const {
    debounceFetcher,
    filterOption,
    isFetching,
    optionList,
    onChangeDebounceSelect,
  } = useDebounceSelect({ ...props, language });

  return (
    <Select
      labelInValue
      filterOption={filterOption}
      onSearch={debounceFetcher}
      notFoundContent={isFetching ? <Spin size="small" /> : null}
      onChange={onChangeDebounceSelect}
      {...props}
      options={optionList}
    />
  );
};
