import { CompanyData } from 'types/company';

export const setFormFields = (
  form: any,
  editableData: CompanyData,
  oldFormData: any,
) => {
  const obj = {
    fullNameRu: editableData?.FullNameRu,
    nameRu: editableData?.FullNameRu,
    fullNameEn: editableData?.FullNameEn,
    nameEn: editableData?.FullNameEn,
    legalCountry: editableData?.LegalAddress.CountryId,
    legalIndex: editableData?.LegalAddress.PostCode,
    legalCityRu: editableData?.LegalAddress.CityRu,
    legalCityEn: editableData?.LegalAddress.CityEn,
    legalStreetRu: editableData?.LegalAddress.StreetRu,
    legalStreetEn: editableData?.LegalAddress.StreetEn,
    legalBuildingRu: editableData?.LegalAddress.BuildingRu,
    legalBuildingEn: editableData?.LegalAddress.BuildingEn,

    mailingCountry: editableData?.PostAddress.CountryId,
    mailingIndex: editableData?.PostAddress.PostCode,
    mailingCityRu: editableData?.PostAddress.CityRu,
    mailingCityEn: editableData?.PostAddress.CityEn,
    mailingStreetRu: editableData?.PostAddress.StreetRu,
    mailingStreetEn: editableData?.PostAddress.StreetEn,
    mailingBuildingRu: editableData?.PostAddress.BuildingRu,
    mailingBuildingEn: editableData?.PostAddress.BuildingEn,

    bankNameRu: editableData?.BankDetail.BankNameRu,
    bankNameEn: editableData?.BankDetail.BankNameEn,
    checkingAccount:
      oldFormData?.checkingAccount || editableData?.BankDetail.AccountNumber,
    correspondentAccount:
      oldFormData?.correspondentAccount ||
      editableData?.BankDetail.CorrespondentAccount,
    bic: oldFormData?.bic || editableData?.BankDetail.Bic,
    inn: oldFormData?.inn || editableData?.BankDetail.Inn,
    kpp: oldFormData?.kpp || editableData?.BankDetail.Kpp,
    ogrn: oldFormData?.ogrn || editableData?.BankDetail.Ogrn,

    AccountNumber: editableData?.BankDetail.AccountNumber,
    AccountNameEn: editableData?.BankDetail.AccountNameEn,
    CorrespondentAccount: editableData?.BankDetail.CorrespondentAccount,
    Iban: editableData?.BankDetail.Iban,
    Aba: editableData?.BankDetail.Aba,
    BankAddressEn: editableData?.BankDetail.BankAddressEn,
    UsdCorrespondentBank: editableData?.BankDetail.UsdCorrespondentBank,
    SwiftCode: editableData?.BankDetail.SwiftCode,
    AbaFedwire: editableData?.BankDetail.AbaFedwire,
    Reference: editableData?.BankDetail.Reference,
    Text: editableData?.BankDetail.Text,
  };

  Object.entries(obj).forEach(([key, value]: any) => {
    form.setFieldsValue({ [key]: value });
  });
};
