import { api } from 'api';
import {
  InsuranceContractType,
  InsuranceCoverageItem,
  ReinsuranceContractStatus,
} from 'types/insuranceContracts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox } from 'antd';
import { ColumnType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { saveData } from 'shared/utils/fileSaver';
import { getReinsuranceContractStatus } from 'shared/utils/getReinsuranceContractStatus';
import { formatMoney } from 'shared/utils/formatMoney';
import { isRussian } from 'shared/utils/isRussian';
import { isBroker } from 'shared/utils/checkUserRole';
import { handleError } from 'shared/utils/validation';

const requestContractCoverage = async (id: string) => {
  try {
    const { data } = await api.get<InsuranceCoverageItem[]>(
      `InsuranceContracts/${id}/coverage`,
    );

    return data;
  } catch (e) {
    handleError(e);
  }
};

const updateContractCoverage = async (
  insuranceContractId: string,
  reinsuranceContractId: number,
  Selected: boolean,
) => {
  try {
    await api.put(
      `/InsuranceContracts/${insuranceContractId}/coverage/${reinsuranceContractId}`,
      { Selected },
    );
  } catch (e) {
    handleError(e);
  }
};

const generateDocumentLink = (
  contractId: string,
  reinsuranceContractId: string,
) =>
  `InsuranceContracts/${contractId}/coverage/${reinsuranceContractId}/download`;

const downloadDocument = (url: string, fileName: string) => {
  api.get(url, { responseType: 'blob' }).then(({ data }) => {
    const blob = new Blob([data]);
    saveData(blob, fileName);
  });
};

export const useCoverageTable = (
  contractId: string,
  contractStatus?: number,
) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<InsuranceCoverageItem[]>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [modalData, setModalData] = useState<InsuranceCoverageItem>();
  const counterpartyType = localStorage.getItem('CounterpartyType');

  useEffect(() => {
    return () => setModalData(undefined);
  }, []);

  const isDisabled = useMemo(() => {
    return (
      !isBroker(counterpartyType) ||
      contractStatus !== InsuranceContractType.Review
    );
  }, [contractStatus, counterpartyType]);

  const refreshCoverage = useCallback(() => {
    setLoading(true);
    requestContractCoverage(contractId)
      .then(data => {
        setData(data);
      })
      .finally(() => setLoading(false));
  }, [contractId]);

  useEffect(() => {
    refreshCoverage();
  }, [contractId, refreshCoverage]);

  const openStatusModal = (contract: InsuranceCoverageItem) => {
    setModalData(contract);
  };

  const closeStatusModal = () => {
    setModalData(undefined);
  };

  const reinsurerNameField = useMemo(
    () => (isRussian(language) ? 'ReinsurerNameRu' : 'ReinsurerNameEn'),
    [language],
  );

  const columns = useMemo(
    (): ColumnType<InsuranceCoverageItem>[] => [
      {
        title: t('coatingStatistic.selected'),
        dataIndex: 'Selected',
        key: 'Selected',
        sorter: (a: InsuranceCoverageItem, b: InsuranceCoverageItem) =>
          Number(a?.Selected) - Number(b?.Selected),
        render: (_: undefined, { Selected, Id }) => (
          <Checkbox
            disabled={isDisabled}
            onChange={() => {
              updateContractCoverage(contractId, Id, !Selected).then(
                refreshCoverage,
              );
            }}
            defaultChecked={Selected}
          />
        ),
      },
      {
        title: t('coatingStatistic.name'),
        dataIndex: reinsurerNameField,
        key: reinsurerNameField,
        sorter: (a: InsuranceCoverageItem, b: InsuranceCoverageItem) =>
          isRussian(language)
            ? a?.ReinsurerNameRu?.localeCompare(b?.ReinsurerNameRu)
            : a?.ReinsurerNameEn?.localeCompare(b?.ReinsurerNameEn),
      },
      {
        title: t('coatingStatistic.reinsurersShare'),
        dataIndex: 'Share',
        key: 'Share',
        sorter: (a: InsuranceCoverageItem, b: InsuranceCoverageItem) =>
          a?.Share! - b?.Share!,
        render: (Share: number) => `${Share ?? 0} %`,
      },
      {
        title: t('coatingStatistic.premiumSize'),
        dataIndex: 'Premium',
        key: 'Premium',
        sorter: (a: InsuranceCoverageItem, b: InsuranceCoverageItem) =>
          a?.Premium! - b?.Premium!,
        render: (Premium: number) => formatMoney(Premium) ?? '-',
      },
      {
        title: t('coatingStatistic.status'),
        dataIndex: 'Status',
        key: 'Status',
        sorter: (a: InsuranceCoverageItem, b: InsuranceCoverageItem) =>
          a?.Status - b?.Status,
        render: (value: ReinsuranceContractStatus, record) =>
          isBroker(counterpartyType) ? (
            <Button type="link" onClick={() => openStatusModal(record)}>
              {getReinsuranceContractStatus(value, language)}
            </Button>
          ) : (
            getReinsuranceContractStatus(value, language)
          ),
      },
      {
        title: t('coatingStatistic.documents'),
        key: 'download',
        render: (_: undefined, coverageItem: InsuranceCoverageItem) => {
          const downloadUrl = generateDocumentLink(
            contractId,
            String(coverageItem.Id),
          );
          const fileName = `${coverageItem[reinsurerNameField]}${coverageItem.DocumentExtension}`;

          const onClick = () => downloadDocument(downloadUrl, fileName);

          return (
            <Button type="link" onClick={onClick}>
              {fileName}
            </Button>
          );
        },
      },
    ],
    [
      t,
      reinsurerNameField,
      isDisabled,
      contractId,
      refreshCoverage,
      language,
      counterpartyType,
    ],
  );

  return {
    data,
    isLoading,
    columns,
    closeStatusModal,
    modalData,
    refreshCoverage,
  };
};
