import { api } from 'api';
import { useCallback, useEffect, useState } from 'react';
import { Popconfirm } from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { saveData } from 'shared/utils/fileSaver';
import { Document, Params } from 'types/common';
import { ColumnsType } from 'antd/es/table';
import { handleError } from 'shared/utils/validation';

const requestDocuments = async (id?: string) => {
  if (id) {
    try {
      const { data } = await api.get<Document[]>(`/Planes/${id}/documents`);
      return data;
    } catch (error) {
      handleError(error);
    }
  }
};

const createDocument = async (formData: FormData, planeId?: string) => {
  try {
    await api.post(`/Planes/${planeId}/documents`, formData);
  } catch (error) {
    handleError(error);
  }
};

const deleteDocument = async (documentId: number, planeId?: string) => {
  try {
    await api.delete(`/Planes/${planeId}/documents/${documentId}`);
  } catch (error) {
    handleError(error);
  }
};

const downloadFile = async (
  rowId: number,
  name: string,
  extension: string,
  id?: string,
) => {
  try {
    const { data } = await api.get(
      `/Planes/${id}/documents/${rowId}/download`,
      { responseType: 'blob' },
    );
    const blob = new Blob([data]);
    saveData(blob, `${name}${extension}`);
  } catch (error) {
    handleError(error);
  }
};

export const useDocumentsTable = (t: (text: string) => string) => {
  const [documentsList, setDocumentsList] = useState<Document[]>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { planeId } = useParams<Params>();

  const isLoading = isFetching || !documentsList;

  const refreshDocumentsTable = useCallback(() => {
    setIsFetching(true);
    requestDocuments(planeId)
      .then(setDocumentsList)
      .finally(() => setIsFetching(false));
  }, [planeId]);

  useEffect(() => {
    refreshDocumentsTable();
  }, [refreshDocumentsTable]);

  const onFinish = useCallback(
    async (values: any) => {
      const formData = new FormData();
      formData.append('Name', values.Name);
      formData.append('Files', values.Files[0].originFileObj);
      if (values.DateOfSigning) {
        formData.append(
          'Date',
          moment(values.DateOfSigning).format('YYYY-MM-DD'),
        );
      }
      await createDocument(formData, planeId);
      refreshDocumentsTable();
    },
    [planeId, refreshDocumentsTable],
  );

  const columns: ColumnsType<any> = [
    {
      title: t('documentsTable.name'),
      dataIndex: 'Name',
      sorter: (a: Document, b: Document) => a?.Name?.localeCompare(b?.Name),
      render: (
        fileName: string,
        {
          Id,
          Name,
          Extension,
        }: { Id: number; Name: string; Extension: string },
      ) => (
        <p
          className="action-string"
          onClick={() => downloadFile(Id, Name, Extension, planeId)}
        >
          {fileName}
        </p>
      ),
    },
    {
      title: t('documentsTable.dateOfSigning'),
      dataIndex: 'Date',
      key: 'Date',
      width: '20%',
      sorter: (a: Document, b: Document) => a?.Date?.localeCompare(b?.Date),
      render: (_: undefined, { Date }: { Date: string }) =>
        Date ? moment(Date).format('DD.MM.YYYY') : '-',
    },
    {
      title: t('documentsTable.updateDate'),
      dataIndex: 'UpdateDate',
      key: 'Date',
      width: '20%',
      sorter: (a: Document, b: Document) =>
        a?.UpdateDate?.localeCompare(b?.UpdateDate),
      render: (_: undefined, { UpdateDate }: { UpdateDate: string }) => {
        return new Date(UpdateDate).toLocaleString();
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '5%',
      render: (_: undefined, { Id }: Document) => {
        return (
          <Popconfirm
            title={t('deleteDocument')}
            onConfirm={() => deleteDocument(Id, planeId)}
            okText={t('deleteOk')}
            cancelText={t('deleteCancel')}
          >
            <p className="custom-cell">{t('usersTable.deleteAction')}</p>
          </Popconfirm>
        );
      },
    },
  ];

  return {
    documentsList,
    isLoading,
    columns,
    onFinish,
  };
};
