import React, { FC, ReactElement, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import en_US from 'antd/lib/locale/en_US';

import { store } from './store';
import { Routes } from './Routes';
import './i18n';

// Styles
import 'antd/dist/antd.less';
import 'shared/styles/common.sass';
import { Preloader } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { isRussian } from 'shared/utils/isRussian';

const appContainer = document.getElementById('root');

const App: FC = (): ReactElement => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider locale={isRussian(language) ? ru_RU : en_US}>
          <Routes />
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <Suspense fallback={<Preloader />}>
    <App />
  </Suspense>,
  appContainer,
);
