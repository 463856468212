import { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectTypes, UnionSelectType } from 'types/select';
import { api } from 'api';
import { useTranslation } from 'react-i18next';
import { isRussian } from 'shared/utils/isRussian';
import { handleError } from 'shared/utils/validation';

const requestOptions = async (endpoint: string) => {
  try {
    const { data } = await api.get<UnionSelectType[]>(endpoint);
    return data;
  } catch (error) {
    handleError(error);
  }
};

const requestModels = async (value: string) => {
  try {
    const { data } = await api.get(`/Planes/search/${value}`);
    return data;
  } catch (error) {
    handleError(error);
  }
};

const requestPoint = async (searchValue: string) => {
  try {
    const { data } = await api.get(`/RoutePoints/search/${searchValue}`);
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const useCustomSelect = (selectType?: string) => {
  const [selectOptions, setSelectOption] = useState<UnionSelectType[]>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const endpointByType: { [key: string]: string } = useMemo(
    () => ({
      [SelectTypes.COUNTRY]: '/Countries',
      [SelectTypes.PERIODICITY]: '/TrainingPeriodicies',
      [SelectTypes.SEARCH]: '/PlaneTypes',
      [SelectTypes.MODELS]: '/PlaneTypes',
    }),
    [],
  );

  const fetchOptionsBySelectType: {
    [key: string]: (value: string) => Promise<any>;
  } = useMemo(
    () => ({
      [SelectTypes.MODELS]: requestModels,
      [SelectTypes.POINT]: requestPoint,
    }),
    [],
  );

  const materialLossesStatusLabels = useMemo(
    () => [
      {
        Id: 1,
        Name: t('lossesPage.statuses.onReview'),
      },
      {
        Id: 2,
        Name: t('lossesPage.statuses.onPayment'),
      },
      {
        Id: 3,
        Name: t('lossesPage.statuses.reimbursed'),
      },
    ],
    [t],
  );

  const positionsLabels = useMemo(
    () => [
      {
        Id: 4,
        Name: t('pilotsPage.roles.captain'),
      },
      {
        Id: 5,
        Name: t('pilotsPage.roles.coPilot'),
      },
      {
        Id: 6,
        Name: t('pilotsPage.roles.instructor'),
      },
      {
        Id: 8,
        Name: t('pilotsPage.roles.mainPilot'),
      },
    ],
    [t],
  );

  const renderLabelName = useCallback(
    (item: { Name?: string; NameRu?: string; NameEn?: string }) => {
      if (item.NameRu || item.NameEn) {
        return isRussian(language) ? item.NameRu : item.NameEn ?? item?.Name;
      } else {
        return item?.Name;
      }
    },
    [language],
  );

  useEffect(() => {
    if (selectType) {
      switch (selectType) {
        case SelectTypes.POSITION:
          setSelectOption(positionsLabels);
          return;
        case SelectTypes.STATUS:
          setSelectOption(materialLossesStatusLabels);
          return;
        default:
          const endpoint = endpointByType[selectType];
          if (endpoint) {
            requestOptions(endpoint).then((data: any) => {
              if (selectType === SelectTypes.COUNTRY) {
                setSelectOption(
                  data?.filter(
                    ({ NameEn }: { NameEn: string }) => NameEn !== 'Worldwide',
                  ),
                );
              } else {
                setSelectOption(data);
              }
            });
          }
      }
    }
  }, [
    endpointByType,
    materialLossesStatusLabels,
    positionsLabels,
    selectType,
    t,
  ]);

  return {
    selectOptions,
    renderLabelName,
    fetchOptionsBySelectType,
  };
};
