import { FC, ReactElement, SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EditableTable,
  Breadcrumb,
  Button,
  EditingModal,
  columnsToFormFields,
} from 'shared/components';
import { useRoutesPage } from './useRoutesPage';
import { CreateRoutesModal } from './components';
import { PlusOutlined } from '@ant-design/icons';
import { useBem } from 'shared/hooks';

import '../AircraftTablePage/AircraftTablePage.sass';

export type Routes = {
  Type: number;
  TypeNameRu?: string;
  TypeNameEn?: string;
  CounterPartyId: number;
  StartPointId: number;
  EndPointId: number;
  StartNameRu: string;
  StartNameEn: string;
  EndNameRu: string;
  EndNameEn: string;
  FlightCount: number;
  Year: number;
  Quarter: number;
  Id: number;
  CreatedDate?: string;
};

type RoutesPageProps = {
  showTitle?: boolean;
};

const routes = [
  {
    path: '/about-company',
    breadcrumbName: 'Анкета авиакомпании',
  },
  {
    path: '/flot/page=1',
    breadcrumbName: 'Флот',
  },
];

export const RoutesPage: FC<RoutesPageProps> = ({
  showTitle = true,
}): ReactElement => {
  const {
    routesData,
    totalItems,
    columns,
    statistic,
    refresh,
    isFetching,
  } = useRoutesPage(showTitle);
  const { t } = useTranslation();
  const bem = useBem('AircraftTablePage');
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showEditingForm, setShowEditingForm] = useState<boolean>(false);
  const [editingData, setEditingData] = useState<any>();

  const closeForm = () => {
    setShowForm(false);
  };

  const onRow = (event: SyntheticEvent, data: any) => {
    setShowEditingForm(true);
    setEditingData(data);
  };

  console.log('ROUTES DATA', routesData);

  return (
    <>
      {showTitle && (
        <>
          <Breadcrumb routes={routes} />
          <div className={bem('info-container')}>
            <div className={bem('title-container')}>
              <p className={bem('title-flot')}>{t('routes')}</p>
              <p className={bem('plane-count')}>
                {statistic?.Internal && statistic.International
                  ? statistic?.Internal + statistic?.International
                  : '0'}
              </p>
            </div>
            <div className={bem('button-container')}>
              <Button
                className={bem('button-styles')}
                type="primary"
                customType="send"
                htmlType="submit"
                title={'Добавить'}
                onClick={() => setShowForm(true)}
                icon={<PlusOutlined className={bem('plus-image')} />}
              />
            </div>
          </div>
        </>
      )}
      <EditableTable
        loading={isFetching}
        saveEndpoint="/Profile/Planes"
        dataSourceProps={routesData}
        columns={columns}
        afterSave={refresh}
        totalItems={totalItems}
        onRowClick={onRow}
      />

      {showEditingForm && (
        <EditingModal
          initialValues={editingData}
          close={() => setShowEditingForm(false)}
          visible={showEditingForm}
          refresh={refresh}
          fields={columns.map(columnsToFormFields)}
          onSubmit={console.log}
          onDelete={(id: number) => console.log('onDelete', id)}
        />
      )}

      {showForm && (
        <CreateRoutesModal
          visible={showForm}
          close={closeForm}
          refresh={refresh}
        />
      )}
    </>
  );
};

RoutesPage.defaultProps = {
  showTitle: true,
};
