import {
  FC,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Pagination, Table, TablePaginationConfig, TableProps } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { isString } from 'lodash-es';

import { EditableCell } from './EditableCell';
import { EditableRow } from './EditableRow';
import { api } from 'api';
import { handleError } from 'shared/utils/validation';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/es/table/interface';
import { changeDataIndexToKey } from 'shared/utils/prepareDataBeforeSubmit';
import { Params } from 'types/common';
import { useBem } from 'shared/hooks';
import './EditableTable.sass';

enum ActionTypes {
  Paginate = 'paginate',
  Sort = 'sort',
}

interface EditableTableProps extends TableProps<any> {
  dataSourceProps?: any[];
  columns: any;
  loading?: boolean;
  saveEndpoint?: string;
  onSave?: (record: any) => void;
  afterSave?: (config?: {}) => void;
  handleChange?: () => {};
  totalItems?: number;
  onRowClick?: (event: SyntheticEvent, data: any) => void;
}

export const EditableTable: FC<EditableTableProps> = ({
  dataSourceProps,
  columns,
  loading,
  saveEndpoint,
  onSave,
  afterSave,
  totalItems,
  onRowClick,
}): ReactElement => {
  const { page: pageParam } = useParams<Params>();
  const history = useHistory();
  const { pathname } = useLocation();
  const [dataSource, setDataSource] = useState<any>();
  const bem = useBem('EditableTable');

  const handleSave = (row: any) => {
    if (onSave) {
      onSave(row);

      return;
    }

    api
      .put(`${saveEndpoint}/${row.Id}`, row)
      .catch(e => {
        setDataSource(dataSource);
        handleError(e);
      })
      .finally(() => afterSave?.());
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columnsList = columns.map((column: any) => {
    if (!column) {
      return column;
    }

    return {
      ...column,
      onCell: (record: any) => ({
        record,
        selectType: column.selectType ?? '',
        multiType: column.multiType ?? '',
        maxLength: column.maxLength,
        maxNumCount: column.maxNumCount,
        minNumCount: column.minNumCount,
        typeCell: column.typeCell,
        searchType: column.searchType ?? '',
        point: column.poin ?? '',
        requestType: column.requestType ?? '',
        editable: column.editable && record.editable !== false,
        dataIndex: column.dataIndex,
        title: column.title,
        isDecimal: column.isDecimal,
        handleSave: handleSave,
      }),
    };
  });

  const isDescend = useCallback(
    (order: 'descend' | 'ascend' | undefined | null) => {
      if (order) {
        return order === 'descend';
      }
    },
    [],
  );

  const changePage = useCallback(
    (page: number) => {
      if (pageParam) {
        const path = pathname.slice(0, pathname.length - 1) + page;
        history.push(path);
      }
    },
    [history, pageParam, pathname],
  );

  const handleChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
      extra: TableCurrentDataSource<any>,
    ) => {
      const { action } = extra;

      if (action === ActionTypes.Sort || action === ActionTypes.Paginate) {
        if (!Array.isArray(sorter)) {
          const { order, field: sort } = sorter;
          const { current: page } = pagination;
          let config: any = { page };

          if (isString(order)) {
            config = {
              ...config,
              descend: isDescend(order),
              sort: changeDataIndexToKey(sort),
            };
          }

          afterSave?.(config);
        }
      }
    },
    [isDescend, afterSave],
  );

  useEffect(() => {
    setDataSource(dataSourceProps);
  }, [dataSourceProps]);

  return (
    <>
      <Table
        style={{ marginTop: 20 }}
        className={bem()}
        components={components}
        onChange={handleChange}
        rowClassName="editable-row"
        dataSource={dataSource}
        columns={columnsList}
        loading={loading}
        scroll={{ x: 1400 }}
        pagination={false}
        sticky
      />
      <div className={bem('pagination-container')}>
        <Pagination
          onChange={changePage}
          total={totalItems}
          current={Number(pageParam)}
        />
      </div>
    </>
  );
};
