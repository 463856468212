import { FC, ReactElement } from 'react';

import { useBem } from 'shared/hooks';

import './FloatingLabel.sass';

type FloatingLabelProps = {
  /** Текст для label */
  text: string;
  /** Уменьшенный вид label, должен быть true когда инпут не пустой или на нем есть focus */
  small: boolean;
  /** Добавляет красный * */
  required?: boolean;
  /** Делает label более бледным (не растространяется на *) */
  disabled?: boolean;
};

/**
 * "Парящий" label. Используется в составе кастомных инпутов.
 */
export const FloatingLabel: FC<FloatingLabelProps> = ({
  text,
  small,
  required = false,
  disabled = false,
}): ReactElement => {
  const bem = useBem('UIKitFloatingLabel');

  return (
    <div className={bem()}>
      <label
        className={bem('label', {
          float: small,
          disabled,
          required,
        })}
      >
        {text}
      </label>
    </div>
  );
};
