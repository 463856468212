import {
  InsuranceCoverageItem,
  ReinsuranceContractStatus,
  ReinsuranceStatusDto,
} from 'types/insuranceContracts';
import { api } from 'api';
import { Button } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Params } from 'types/common';
import { ColumnType } from 'antd/es/table';
import { getReinsuranceContractStatus } from 'shared/utils/getReinsuranceContractStatus';
import moment from 'moment';
import { getAuthToken } from 'shared/utils/getAuthToken';
import { saveData } from 'shared/utils/fileSaver';
import { isRussian } from 'shared/utils/isRussian';
import { handleError } from 'shared/utils/validation';

const requestContractStatusHistory = async (id: number) => {
  try {
    const { data } = await api.get<ReinsuranceStatusDto[]>(
      `ReinsuranceContracts/${id}/status`,
    );

    return data;
  } catch (e) {
    handleError(e);
  }
};

const requestAvailableStatuses = async (id: number) => {
  try {
    const { data } = await api.get<ReinsuranceContractStatus[]>(
      `ReinsuranceContracts/${id}/status/available`,
    );

    return data;
  } catch (e) {
    handleError(e);
  }
};

const postReinsurerContractStatus = async (
  id: number,
  data: FormData,
  requestId: string,
) => {
  try {
    await api.post(
      `InsuranceContracts/${requestId}/coverage/${id}/status`,
      data,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );

    return data;
  } catch (e) {
    handleError(e);
  }
};

const downloadDocument = (url: string, fileName: string) => {
  api
    .get(url, {
      responseType: 'blob',
      headers: getAuthToken(),
    })
    .then(({ data }) => {
      const blob = new Blob([data]);
      saveData(blob, fileName);
    });
};

const generateDocumentLink = (
  reinsuranceContractId: number,
  statusId: string,
) =>
  `ReinsuranceContracts/${reinsuranceContractId}/status/${statusId}/download`;

export const useCoverageHistoryModal = (
  contract: InsuranceCoverageItem,
  closeModal: () => void,
  refresh: () => void,
) => {
  const { requestId } = useParams<Params>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [statusHistory, setStatusHistory] = useState<ReinsuranceStatusDto[]>();
  const [availableStatuses, setAvailableStatuses] = useState<
    ReinsuranceContractStatus[]
  >();
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const refreshCoverage = useCallback(() => {
    requestContractStatusHistory(contract.Id).then(data => {
      setStatusHistory(data);
    });
  }, [contract.Id]);

  useEffect(() => {
    refreshCoverage();
  }, [refreshCoverage]);

  useEffect(() => {
    requestAvailableStatuses(contract.Id).then(data => {
      setAvailableStatuses(data);
    });
  }, [contract]);

  useEffect(() => {
    if (!!statusHistory && !!availableStatuses) {
      setLoading(false);
    }
  }, [setLoading, statusHistory, availableStatuses]);

  const reinsurerNameField = useMemo(
    () => (isRussian(language) ? 'ReinsurerNameRu' : 'ReinsurerNameEn'),
    [language],
  );

  const onSubmit = useCallback(
    (data: any) => {
      const formData = new FormData();
      formData.append('Share', data.Share);
      formData.append('Premium', data.Premium);
      formData.append('Status', data.Status);
      formData.append('Comment', data.Comment || '');
      formData.append('File', data.File?.[0].originFileObj);

      setLoading(true);
      postReinsurerContractStatus(contract.Id, formData, requestId)
        .then(() => {
          setLoading(false);
          refresh();
          closeModal();
        })
        .finally(closeModal);
    },
    [contract.Id, requestId, closeModal, refresh],
  );

  const columns = useMemo(
    (): ColumnType<ReinsuranceStatusDto>[] => [
      {
        title: t('coverageHistoryModal.table.previousStatus'),
        dataIndex: 'PrevStatus',
        key: 'PrevStatus',
        render: (value: ReinsuranceContractStatus) =>
          getReinsuranceContractStatus(value, language),
      },
      {
        title: t('coverageHistoryModal.table.newStatus'),
        dataIndex: 'CurrStatus',
        key: 'CurrStatus',
        render: (value: ReinsuranceContractStatus) =>
          getReinsuranceContractStatus(value, language),
      },
      {
        title: t('coverageHistoryModal.table.updatedAt'),
        dataIndex: 'UpdateDate',
        key: 'UpdateDate',
        render: (updateDate: string) =>
          updateDate ? moment(updateDate).format('DD.MM.YYYY') : '-',
      },
      {
        title: t('coverageHistoryModal.table.share'),
        dataIndex: 'Share',
        key: 'Share',
        render: (share: number) => `${share || 0} %`,
      },
      {
        title: t('coverageHistoryModal.premiumSize'),
        dataIndex: 'Premium',
        key: 'Premium',
        render: (premium: number) => `${premium || 0} $`,
      },
      {
        title: t('coverageHistoryModal.table.comment'),
        dataIndex: 'Comment',
        key: 'Comment',
      },
      {
        title: t('coatingStatistic.version'),
        dataIndex: 'DocumentVersion',
        key: 'DocumentVersion',
      },
      {
        title: t('coatingStatistic.documents'),
        key: 'download',
        render: (_: undefined, coverageItem: ReinsuranceStatusDto) => {
          const downloadUrl = generateDocumentLink(
            contract.Id,
            String(coverageItem.Id),
          );
          const fileName = `${coverageItem.UserName}.${coverageItem.DocumentExtension}`;

          const onClick = () => downloadDocument(downloadUrl, fileName);

          return (
            <Button onClick={onClick}>
              {isRussian(language) ? 'Скачать' : 'Download'}
            </Button>
          );
        },
      },
    ],
    [t, language, contract.Id],
  );

  return {
    isLoading,
    statusHistory,
    availableStatuses,
    title: contract[reinsurerNameField],
    onSubmit,
    refreshCoverage,
    columns,
  };
};
