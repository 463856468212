export const getAuthToken = () => {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        'auth_token'.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') +
        '=([^;]*)',
    ),
  );
  return matches
    ? { 'x-auth-token': decodeURIComponent(matches[1]) }
    : undefined;
};
