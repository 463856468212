import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Table,
  Upload,
} from 'antd';
import {
  ReinsuranceContractDto,
  ReinsuranceContractStatus,
} from 'types/insuranceContracts';
import { useReinsurerRequestModal } from 'pages/ReinsurerPage/components/ReinsurerRequestModal/useReinsurerRequestModal';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { getReinsuranceContractStatus } from 'shared/utils/getReinsuranceContractStatus';
import { SpinWrapper } from 'shared/components';
import { useBem } from 'shared/hooks';
import { normFile } from 'shared/utils/normFile';

import './ReinsurerRequestModal.sass';

type ReinsurerRequestModalProps = {
  contract: ReinsuranceContractDto;
  closeModal: () => void;
  refreshContracts: () => void;
};

export const ReinsurerRequestModal: React.FC<ReinsurerRequestModalProps> = ({
  contract,
  closeModal,
  refreshContracts,
}) => {
  const {
    availableStatuses,
    insuredNameField,
    isLoading,
    onSubmit,
    historyData,
    columns,
  } = useReinsurerRequestModal(contract, closeModal, refreshContracts);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const bem = useBem('ReinsurerRequestModal');
  const [form] = useForm();

  const [statusAgreed, setStatusAgreed] = useState<boolean>(false);

  const checkStatus = () => {
    if (form.getFieldValue('Status') === ReinsuranceContractStatus.Agreed) {
      setStatusAgreed(true);
    } else {
      setStatusAgreed(false);
    }
  };

  useEffect(() => {
    if (historyData?.length) {
      form.setFieldsValue({
        Share: historyData[0].Share,
        Premium: historyData[0].Premium,
      });
    }
  }, [form, historyData, historyData?.length]);

  useEffect(() => {
    form.validateFields();
  }, [form, statusAgreed]);

  return (
    <Modal
      visible
      width={1000}
      title={contract[insuredNameField]}
      onCancel={closeModal}
      okButtonProps={{ htmlType: 'submit', form: 'reinsurerContractForm' }}
      okText={t('reinsurerPage.modal.buttons.okText')}
      cancelText={t('reinsurerPage.modal.buttons.cancelText')}
    >
      <SpinWrapper isLoading={isLoading}>
        <Form
          onFieldsChange={checkStatus}
          form={form}
          onFinish={onSubmit}
          id="reinsurerContractForm"
          initialValues={{
            Status: availableStatuses?.[0],
            Share: historyData?.[0].Share || 0,
            Premium: historyData?.[0].Premium || 0,
          }}
        >
          <Form.Item
            name="Status"
            label={t('reinsurerPage.modal.changeStatus')}
            rules={[
              {
                required: true,
                message: t('reinsurerPage.modal.errors.selectStatus'),
              },
            ]}
          >
            <Select
              options={availableStatuses?.map(status => ({
                value: status,
                label: getReinsuranceContractStatus(status, language),
              }))}
            />
          </Form.Item>
          <Form.Item
            name="Share"
            rules={[
              {
                required: true,
                message: t('reinsurerPage.modal.errors.reinsurerShare'),
              },
            ]}
            label={t('reinsurerPage.modal.reinsurersShare')}
          >
            <InputNumber
              max={100}
              min={0}
              addonAfter="%"
              className={bem('input-number')}
            />
          </Form.Item>
          <Form.Item
            name="Premium"
            rules={[
              {
                required: true,
                message: t('reinsurerPage.modal.errors.premiumSize'),
              },
            ]}
            label={t('reinsurerPage.modal.premiumSize')}
          >
            <InputNumber
              min={0}
              addonAfter="$"
              className={bem('input-number')}
            />
          </Form.Item>
          <Form.Item name="Comment" label={t('reinsurerPage.modal.comment')}>
            <Input />
          </Form.Item>
          <Form.Item
            name="File"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              maxCount={1}
              customRequest={({ onSuccess }: any) => {
                onSuccess('ok');
              }}
            >
              <Button>{t('reinsurerPage.modal.uploadDocument')}</Button>
            </Upload>
          </Form.Item>
        </Form>
      </SpinWrapper>

      <Table dataSource={historyData} columns={columns} />
    </Modal>
  );
};
