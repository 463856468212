import { Country } from './company';
import { TypeItem } from './common';

export enum SelectTypes {
  COUNTRY = 'country',
  PERIODICITY = 'periodicity',
  SEARCH = 'search',
  MODELS = 'models',
  POSITION = 'position',
  POINT = 'point',
  STATUS = 'status',
}

type AirType = {
  Id: number;
  Name: string;
  CreateDate: string;
  UpdateDate: string;
};

export type CustomSelectProps = {
  record: any;
  dataIndex: string;
  type?: SelectTypes;
  onSave: (field: string | number, value: any) => void;
  toggleEdit?: () => void;
  inForm?: boolean;
};

export type UnionSelectType = Country | TypeItem | AirType;
