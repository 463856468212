export interface RequestState {
  isFetching: boolean;
  isFetched: boolean;
}

export interface MergeRequestState<D, E> extends RequestState {
  data: null | D;
  error: null | E;
}

export interface ValidationApiError {
  errors?: Record<string, Array<string>>;
  status: number;
  title: string;
}

export interface InternalApiError {
  Code: number;
  message: string;
}

export type ResponseApiError = ValidationApiError | InternalApiError;

export const getFetchingRequestState = (): Pick<
  RequestState,
  'isFetching'
> => ({
  isFetching: true,
});

export const getInitialRequestState = (): RequestState => ({
  isFetching: false,
  isFetched: false,
});

export const getFetchedRequestState = (): RequestState => ({
  isFetching: false,
  isFetched: true,
});
