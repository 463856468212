import { FC, ReactElement } from 'react';
import { useBem } from 'shared/hooks';
import { InputNumber as AntInputNumber, InputNumberProps } from 'antd';

interface Props extends InputNumberProps {}

export const InputNumber: FC<Props> = (props): ReactElement => {
  const bem = useBem('InputNumber');

  return <AntInputNumber className={bem()} {...props} />;
};
