import { FC, ReactElement } from 'react';
import { Button, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import {
  SearchInput,
  EditableTable,
  TableForm,
  columnsToFormFields,
} from 'shared/components';
import { Params, TypeItem } from 'types/common';
import { useServicePage } from './useServicePage';
import { useParams } from 'react-router-dom';
import { PROFILE_SERVICE } from 'api/endpoints';

export type Service = {
  Id: number;
  CounterpartyId: number;
  Company: string;
  CountryId: number;
  CountryNameRu: string;
  CountryNameEn: string;
  Planes: TypeItem[];
  Services: TypeItem[];
  PlanesNames?: string;
  ServicesNames?: string;
};

type ServicePageProps = {
  showTitle?: boolean;
};

export const ServicePage: FC<ServicePageProps> = ({
  showTitle,
}): ReactElement => {
  const { requestId } = useParams<Params>();
  const {
    serviceData,
    isFetching,
    refresh,
    columns,
    showForm,
    openForm,
    closeForm,
    onFormSubmit,
  } = useServicePage(requestId);

  const { Title } = Typography;
  const { t } = useTranslation();

  return (
    <>
      {showTitle && (
        <Title level={2}>
          <Trans i18nKey="servicePage.title">Fleet maintenance</Trans>
        </Title>
      )}
      <Row justify="space-between">
        {!requestId && (
          <>
            <SearchInput
              url={
                requestId
                  ? `/InsuranceContracts/${requestId}/fleet/maintenances`
                  : PROFILE_SERVICE
              }
            />
            <Button onClick={openForm} style={{ height: 40 }} type="primary">
              {t('add')}
            </Button>
          </>
        )}
      </Row>
      {showForm && (
        <TableForm
          fields={columns.map(columnsToFormFields)}
          closeForm={closeForm}
          onSubmit={onFormSubmit}
        />
      )}
      <EditableTable
        loading={isFetching}
        saveEndpoint="/Maintenances"
        dataSourceProps={isFetching ? [] : serviceData}
        columns={columns}
        afterSave={refresh}
        totalItems={serviceData?.TotalItems}
      />
    </>
  );
};

ServicePage.defaultProps = {
  showTitle: true,
};
