import { useDispatch, useSelector } from 'react-redux';
import { countries } from 'store';
import { useEffect } from 'react';

export const useCountries = () => {
  const dispatch = useDispatch();

  const { data: countryList, isFetching } = useSelector(
    countries.selector.state,
  );

  useEffect(() => {
    if (!countryList && !isFetching) {
      dispatch(countries.thunk.request());
    }
  }, [countryList, isFetching, dispatch]);

  return {
    countryList,
    isFetching,
  };
};
