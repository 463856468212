import { api } from 'api';
import { RegisterData } from 'types/register';
import { useState } from 'react';
import { handleError } from 'shared/utils/validation';

export const useRegistrationPage = () => {
  const [isSuccessfulRequest, setIsSuccessfulRequest] = useState<boolean>(
    false,
  );

  const createUser = async (values: RegisterData) => {
    try {
      const registerData: RegisterData = {
        ...values,
        Url: window.location.origin,
      };
      await api.post<{ Id: number }>('/Account/signup', registerData);
      setIsSuccessfulRequest(true);
    } catch (e) {
      handleError(e);
    }
  };

  return {
    isSuccessfulRequest,
    createUser,
  };
};
