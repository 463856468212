import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableRow } from './EditableRow';
import { EditableCell } from './EditableCell';
import { Table } from 'antd';
import { restrictEditingKeys } from './constants';

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  name: string;
  age: string;
  address: string;
}

interface EditableTableState {
  dataSourceProps: DataType[];
  count: number;
}

export const EditableStatisticTable: FC<any> = ({
  dataSourceProps,
  columns,
  onSave,
}) => {
  const [dataSource, setDataSource] = useState<any>();
  const { t } = useTranslation();

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columnsList = columns.map((column: any) => {
    if (!column) {
      return column;
    }

    const setEditable = (
      isColumnEditable: boolean,
      isRecordEditable: boolean,
      key: string,
    ) => {
      if (
        isColumnEditable &&
        isRecordEditable &&
        !restrictEditingKeys.includes(key)
      ) {
        return true;
      } else {
        return false;
      }
    };

    return {
      ...column,
      onCell: (record: any) => ({
        record,
        typeCell: column.typeCell,
        editable: setEditable(
          column.editable,
          record.editable !== false,
          record?.key,
        ),
        dataIndex: column.dataIndex,
        title: column.title,
        onSave,
      }),
    };
  });

  useEffect(() => {
    setDataSource(dataSourceProps);
  }, [dataSourceProps]);

  return (
    <>
      <Table
        style={{ marginTop: 20 }}
        components={components}
        rowClassName="editable-row"
        dataSource={dataSource}
        columns={columnsList}
        pagination={false}
      />
    </>
  );
};
