import { FC, ReactElement, useState } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  EditableTable,
  columnsToFormFields,
  AddingModal,
} from 'shared/components';
import { useAircraftTable } from './useAircraftTable';
import { Breadcrumb, Button } from 'shared/components';

import './AircraftTablePage.sass';
import { useBem } from 'shared/hooks';
import { PlusOutlined } from '@ant-design/icons';

import './AircraftTablePage.sass';

type AircraftTablePageProps = {
  showStatistic?: boolean;
};

const routes = [
  {
    path: '/about-company',
    breadcrumbName: 'Анкета авиакомпании',
  },
  {
    path: '/flot',
    breadcrumbName: 'Флот',
  },
];

export const AircraftTablePage: FC<AircraftTablePageProps> = ({
  showStatistic,
}): ReactElement => {
  const {
    statistic,
    aircraftData,
    columns,
    isFetching,
    onSubmit,
    refresh,
  } = useAircraftTable();
  const dataSource = isFetching ? [] : aircraftData?.Items;
  const [showAddingModal, setShowAddingModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const bem = useBem('AircraftTablePage');

  return (
    <>
      {showStatistic && (
        <>
          <Breadcrumb routes={routes} />
          <div className={bem('info-container')}>
            <div className={bem('title-container')}>
              <p className={bem('title-flot')}>{t('flot')}</p>
              <p className={bem('plane-count')}>
                {statistic?.PlaneCount ?? '0'}
              </p>
            </div>
            <div className={bem('button-container')}>
              <Button
                className={bem('button-styles')}
                type="primary"
                customType="send"
                htmlType="submit"
                title={'Добавить'}
                onClick={() => setShowAddingModal(true)}
                icon={<PlusOutlined className={bem('plus-image')} />}
              />
            </div>
          </div>
        </>
      )}
      <EditableTable
        loading={isFetching}
        saveEndpoint="/Profile/Planes"
        dataSourceProps={dataSource}
        columns={columns}
        afterSave={refresh}
        totalItems={aircraftData?.TotalItems}
      />

      <AddingModal
        visible={showAddingModal}
        close={() => setShowAddingModal(false)}
        onSubmit={onSubmit}
        fields={columns.map(columnsToFormFields)}
      />
    </>
  );
};

AircraftTablePage.defaultProps = {
  showStatistic: true,
};
