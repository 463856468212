import { Upload, Modal, Form, Button, Input, Select, DatePicker } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { FC, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { normFile } from 'shared/utils/normFile';

const { Dragger } = Upload;

type UploadingModalProps = {
  visible: boolean;
  close: () => void;
  onFinish: (values: any) => Promise<void>;
  isContract?: boolean;
  title?: string;
};

export const UploadingModal: FC<UploadingModalProps> = ({
  visible,
  close,
  onFinish,
  isContract,
  title,
}): ReactElement => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { Option } = Select;

  const customRequest = ({ onSuccess }: any) => {
    onSuccess('ok');
  };

  const closeModal = () => {
    form.resetFields();
    close();
  };

  const onSubmit = (values: any) => {
    onFinish(values).then(closeModal);
  };

  useEffect(() => form.resetFields(), [form]);

  return (
    <Modal
      title={title ?? t('uploadingFile.title')}
      visible={visible}
      onCancel={closeModal}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        {isContract && (
          <Form.Item
            rules={[{ required: true }]}
            label={t('lossesPage.table.type')}
            name="Type"
          >
            <Select>
              <Option value={0}>{t('lossesPage.options.coverNote')}</Option>
              <Option value={1}>{t('lossesPage.options.debetNote')}</Option>
              <Option value={2}>
                {t('lossesPage.options.insuranceContract')}
              </Option>
              <Option value={3}>{t('lossesPage.options.other')}</Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item
          rules={[{ required: true }]}
          label={t('documentsTable.name')}
          name="Name"
        >
          <Input placeholder={t('documentsTable.documentName')} />
        </Form.Item>
        <Form.Item
          label={t('documentsTable.dateOfSigning')}
          name="DateOfSigning"
        >
          <DatePicker
            style={{ width: '100%' }}
            inputReadOnly
            format={date => moment(date).format('DD.MM.YYYY')}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: t('error.emptyFile') }]}
          label={title ?? t('uploadingFile.title')}
          name="Files"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Dragger customRequest={customRequest} maxCount={1}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p
              style={{ display: 'inline-block', width: '90%' }}
              className="ant-upload-text"
            >
              {t('uploadingFile.message')}
            </p>
          </Dragger>
        </Form.Item>
        <Button htmlType="submit" type="primary">
          {t('add')}
        </Button>
      </Form>
    </Modal>
  );
};
