import { useForm } from 'antd/es/form/Form';
import { api } from 'api';
import { useEffect, useState } from 'react';
import { handleError } from 'shared/utils/validation';

type Reinsurer = {
  Id: 0;
  NameRu: string;
  FullNameRu: string;
  NameEn: string;
  FullNameEn: string;
  Email: string;
  Phone: string;
  Residence: number;
};

const requestReinsurer = async () => {
  try {
    const { data } = await api.get('/Counterparties?type=3');
    return data;
  } catch (e) {
    handleError(e);
  }
};

export const useCreateCoverageModal = (
  id: string,
  refreshCoverage: () => void,
) => {
  const [reinsurerList, setReinsurerList] = useState<Reinsurer[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = useForm();

  const customRequest = ({ onSuccess }: any) => {
    onSuccess('ok');
  };

  const onSubmit = async ({
    ReinsurerId,
    File,
    Share,
    Premium,
  }: {
    ReinsurerId: number;
    File: any;
    Share: number;
    Premium: number;
  }) => {
    try {
      const formData = new FormData();
      formData.append('ReinsurerId', String(ReinsurerId));
      formData.append('File', File[0]?.originFileObj);
      formData.append('Share', String(Share || 0));
      formData.append('Premium', String(Premium || 0));
      formData.append('Comment', '');
      await api.post(`/InsuranceContracts/${id}/coverage`, formData);
      form.resetFields();
      refreshCoverage();
      setShowModal(false);
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    requestReinsurer().then(setReinsurerList);
  }, [id]);

  return {
    form,
    reinsurerList,
    customRequest,
    onSubmit,
    showModal,
    openModal: () => setShowModal(true),
    close: () => setShowModal(false),
  };
};
