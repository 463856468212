import moment from 'moment';

export const renderColumnTitle = (
  startDate: string | undefined,
  endDate: string | undefined,
) => {
  const formattedStartDate = moment(startDate).format('DD.MM.YY');
  const formattedEndDate = moment(endDate).format('DD.MM.YY');

  if (formattedStartDate && formattedEndDate) {
    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  return formattedStartDate || formattedEndDate;
};
