import { useCallback, useMemo } from 'react';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

import { quarterString } from 'shared/utils/quarterString';
import { Exploitation } from './ExploitationPage';
import { api } from 'api';
import { handleError } from 'shared/utils/validation';
import { isNumber } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { Params } from '../../types/common';

const deleteItem = async (id: number) => {
  try {
    await api.delete(`/Profile/Exploitation/${id}`);
  } catch (e) {
    handleError(e);
  }
};

export const useExploitation = (refresh: (config?: {}) => void) => {
  const { page } = useParams<Params>();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const prepareData = useCallback((record: Exploitation): Exploitation => {
    return {
      ...record,
      FlyingHours: Number(String(record.FlyingHours).replace('.', '')),
      FlightsAmount: Number(String(record.FlightsAmount).replace('.', '')),
      PassengersAmount: Number(
        String(record.PassengersAmount).replace('.', ''),
      ),
    };
  }, []);

  const onSubmit = async (record: Exploitation, closeForm: () => void) => {
    try {
      await api.post<Exploitation>('/Exploitation', prepareData(record));
      closeForm();
      refresh({ page });
    } catch (e) {
      handleError(e);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: t('exploitationPage.table.period'),
        dataIndex: 'date',
        key: 'date',
        width: '15%',
        typeCell: 'quarter',
        isRequired: true,
        editable: true,
        sorter: true,
        render: (_: string, { Quarter, Year }: Exploitation) => {
          return quarterString(Quarter, Year, language);
        },
      },
      {
        title: t('exploitationPage.table.flights'),
        dataIndex: 'FlightsAmount',
        key: 'flights',
        typeCell: 'inputNumber',
        minNumCount: 0,
        width: '10%',
        isRequired: true,
        editable: true,
        sorter: true,
      },
      {
        title: t('exploitationPage.table.passengers'),
        dataIndex: 'PassengersAmount',
        key: 'passengers',
        typeCell: 'inputNumber',
        minNumCount: 0,
        isRequired: true,
        editable: true,
        sorter: true,
      },
      {
        title: t('exploitationPage.table.flightHours'),
        dataIndex: 'FlyingHours',
        key: 'flightHours',
        typeCell: 'inputNumber',
        minNumCount: 0,
        width: '20%',
        isRequired: true,
        editable: true,
        sorter: true,
      },
      {
        title: `${t('exploitationPage.table.loading')}, %`,
        dataIndex: 'Loading',
        key: 'loading',
        width: '10%',
        typeCell: 'inputNumber',
        isDecimal: true,
        maxNumCount: 100,
        minNumCount: 0,
        isRequired: true,
        editable: true,
        sorter: true,
        render: (Loading: number) => {
          if (isNumber(Loading)) {
            return Loading?.toFixed(2);
          }
        },
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: '5%',
        render: (_: undefined, { Id }: { Id: number }) => {
          return (
            <Popconfirm
              title={t('deleteRow')}
              onConfirm={() => deleteItem(Id).then(() => refresh({ page }))}
              okText={t('deleteOk')}
              cancelText={t('deleteCancel')}
            >
              <p className="custom-cell">{t('usersTable.deleteAction')}</p>
            </Popconfirm>
          );
        },
      },
    ],
    [language, page, refresh, t],
  );

  return {
    columns,
    onSubmit,
  };
};
