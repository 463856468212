import { api } from 'api';

export const getPointList = (value: string) => {
  return api.get(`/RoutePoints/search/${value}?limit=20`).then(({ data }) => {
    return data;
  });
};

export const getPlanesList = (value: string) => {
  return api.get(`/Planes/search/${value}?limit=20`).then(({ data }) => {
    return data;
  });
};
