import { FC, ReactElement } from 'react';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { Input, Button } from '../../shared/components';

import { useBem } from 'shared/hooks';
import { useAuthorizationPage } from './useAuthorizationPage';

import './AuthorizationPage.sass';
import { useTranslation } from 'react-i18next';

import Logo from 'shared/images/Logo.svg';
import Splash from 'shared/images/Splash.svg';

type AuthData = {
  Email: string;
  Password: string;
};

export const AuthorizationPage: FC = (): ReactElement => {
  const bem = useBem('AuthorizationPage');
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { onLogin } = useAuthorizationPage();

  return (
    <div className={bem('wrapper')}>
      <img className={bem('splash-image')} src={Splash} alt="Splash" />
      <img className={bem('logo-image')} src={Logo} alt="Logo" />
      <div className={bem('form-container')}>
        <div className={bem('form-wrapper')}>
          <Form
            requiredMark={false}
            className={bem('form')}
            form={form}
            onFinish={(values: AuthData) => onLogin(values, dispatch)}
            layout="vertical"
          >
            <h1 className={bem('entry-text')}>{t('authPage.entry')}</h1>
            <Form.Item
              label="Почта"
              name="Email"
              rules={[
                {
                  required: true,
                  message: t('authPage.errors.emailField'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('authPage.passwordField')}
              className={bem('field-password')}
              name="Password"
              rules={[
                {
                  required: true,
                  message: t('authPage.errors.passwordField'),
                },
              ]}
            >
              <Input customType="password" />
            </Form.Item>
            <Button
              customType="auth"
              type="primary"
              htmlType="submit"
              title={t('authPage.signIn')}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};
