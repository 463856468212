import { FC, ReactElement } from 'react';
import { Tabs, Typography } from 'antd';
import { useHistory } from 'react-router';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { GeneralInfo, DocumentsTable } from './components';
import { useDetailedAircraftInfo } from './useDetailedAircraftInfo';
import { SpinWrapper } from 'shared/components';

const { Title } = Typography;
const { TabPane } = Tabs;

export const DetailedAircraftInfoPage: FC = (): ReactElement => {
  const { planeData, isLoading } = useDetailedAircraftInfo();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <SpinWrapper isLoading={isLoading}>
      <Title level={2}>
        <button onClick={() => history.push('/flot')} className="button">
          <LeftOutlined className="back-arrow" />
        </button>
        {planeData?.TypeName}
      </Title>
      <Tabs type="card">
        <TabPane tab={t('tabs.generalInformation')} key="1">
          <GeneralInfo info={planeData} />
        </TabPane>
        <TabPane tab={t('tabs.documents')} key="2">
          <DocumentsTable />
        </TabPane>
      </Tabs>
    </SpinWrapper>
  );
};
