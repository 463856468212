import React from 'react';
import { Button, DatePicker, Form, Modal, Radio, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCreateContractModal } from './useCreateContractModal';
import { useForm } from 'antd/es/form/Form';
import { InsurancePeriodType } from 'types/insuranceContracts';
import { SpinWrapper } from 'shared/components';
import { Moment } from 'moment';
import { isRussian } from 'shared/utils/isRussian';

type CreateContractModalProps = {
  onClose: () => void;
};

export type InsuranceContractCreateForm = {
  CountryIds: Array<number>;
  Period: InsurancePeriodType;
  StartDate: Moment;
};

export const CreateContractModal: React.FC<CreateContractModalProps> = ({
  onClose,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    initialValue,
    countryList,
    isLoading,
    onSubmit,
  } = useCreateContractModal(onClose);
  const [form] = useForm<InsuranceContractCreateForm>();

  return (
    <Modal
      confirmLoading={isLoading}
      okButtonProps={{ htmlType: 'submit', form: 'createContractForm' }}
      onCancel={onClose}
      visible
      title={t('createContractModal.title')}
      footer={null}
    >
      <SpinWrapper isLoading={isLoading}>
        <Form
          id="createContractForm"
          onFinish={onSubmit}
          form={form}
          initialValues={initialValue}
        >
          <Form.Item
            name="StartDate"
            label={t('createContractModal.startDate')}
            rules={[
              {
                required: true,
                message: t('createContractModal.startDateError'),
              },
            ]}
          >
            <DatePicker inputReadOnly format="DD.MM.YYYY" allowClear={false} />
          </Form.Item>
          <Form.Item
            name="Period"
            label={t('createContractModal.period')}
            rules={[
              {
                required: true,
                message: t('createContractModal.periodError'),
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value={InsurancePeriodType.Year}>
                {t('insuranceContractPeriod.year')}
              </Radio.Button>
              <Radio.Button value={InsurancePeriodType.HalfYear}>
                {t('insuranceContractPeriod.halfYear')}
              </Radio.Button>
              <Radio.Button value={InsurancePeriodType.Quarter}>
                {t('insuranceContractPeriod.quarter')}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="CountryIds"
            label={t('createContractModal.countries')}
            rules={[
              {
                required: true,
                message: t('createContractModal.countriesError'),
              },
            ]}
          >
            <Select
              mode="multiple"
              optionFilterProp="label"
              options={countryList?.map(country => ({
                label: isRussian(language) ? country.NameRu : country.NameEn,
                value: country.Id,
              }))}
            />
          </Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              {t('editingModal.buttons.okText')}
            </Button>
            <Button onClick={onClose}>
              {t('editingModal.buttons.cancelText')}
            </Button>
          </Space>
        </Form>
      </SpinWrapper>
    </Modal>
  );
};
