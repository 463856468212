import { FC, ReactElement, createContext } from 'react';

import { Form, FormInstance } from 'antd';

type EditableRowProps = {
  index: string | number;
};

export const EditableContext = createContext<FormInstance | null>(null);

export const EditableRow: FC<EditableRowProps> = ({
  index,
  ...props
}): ReactElement => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
