import {
  FC,
  ReactElement,
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { DatePicker, Form, Input, InputNumber } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash-es';

import { EditableContext } from './EditableRow';
import { CustomSelect, CustomMultiselect } from 'shared/components';
import { quarterString } from 'shared/utils/quarterString';
import { isPositiveNumber } from 'shared/utils/validation';
import { finishFailed } from 'shared/utils/finishFailed';

export const EditableCell: FC<any> = ({
  title,
  editable,
  typeCell,
  selectType,
  point,
  multiType,
  requestType,
  maxNumCount,
  minNumCount,
  maxLength,
  children,
  dataIndex,
  record,
  handleSave,
  isDecimal,
  ...restProps
}): ReactElement => {
  const [editing, setEditing] = useState<boolean>(false);
  const [showEditingModal, setShowEditingModal] = useState<boolean>(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const toggleEdit = useCallback(
    (editingData?: string | null) => {
      setEditing(!editing);
      if (editingData && record) {
        editingData
          ? form?.setFieldsValue({ [dataIndex]: editingData })
          : form?.setFieldsValue({ [dataIndex]: record[dataIndex] });
      }
    },
    [dataIndex, editing, form, record],
  );

  const save = useCallback(
    async (editingData?: any, type?: any) => {
      try {
        let values = await form?.validateFields();

        if (!isUndefined(editingData) && type) {
          if (Array.isArray(editingData) && Array.isArray(type)) {
            const [Year, Quarter] = editingData;
            values = { ...record, Year, Quarter };
          } else {
            values = { ...record, [type]: editingData };
          }
        }

        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (error) {
        finishFailed(t, error.errorFields);
      }
    },
    [form, handleSave, record, t, toggleEdit],
  );

  const onBlur = useCallback(
    (value: string | number | undefined, record: any, dataIndex: string) => {
      if (value) {
        save(value, dataIndex);
        toggleEdit?.();
      } else {
        save(record[dataIndex], dataIndex);
      }
    },
    [save, toggleEdit],
  );

  const getInputByType = (record: any, dataIndex: string) => {
    switch (typeCell) {
      case 'select':
        return (
          <Form.Item className="form-item" name={dataIndex}>
            <CustomSelect
              record={record}
              dataIndex={dataIndex}
              type={selectType}
              toggleEdit={toggleEdit}
              onSave={save}
            />
          </Form.Item>
        );
      case 'multiSelect':
        return (
          <Form.Item
            className="form-item"
            name={dataIndex}
            rules={[{ required: true, message: t('requiredField') }]}
          >
            <CustomMultiselect
              toggleEdit={toggleEdit}
              record={record}
              dataIndex={dataIndex}
              save={save}
              multiType={multiType}
              form={form}
            />
          </Form.Item>
        );
      case 'quarter':
        return (
          <Form.Item className="form-item">
            <DatePicker
              picker="quarter"
              autoFocus
              allowClear={false}
              format={(date: any) =>
                quarterString(
                  moment(date).quarter(),
                  moment(date).year(),
                  language,
                )
              }
              onChange={value => {
                const year = moment(value).year();
                const quarter = moment(value).quarter();
                save([year, quarter], ['Year', 'Quarter']);
              }}
              defaultValue={moment()
                .quarter(record.Quarter)
                .year(record.Year)}
              onBlur={({ target: { value } }) => {
                save(value, dataIndex);
                toggleEdit(value);
              }}
            />
          </Form.Item>
        );
      case 'dateOfDisposal':
        return (
          <Form.Item className="form-item">
            <DatePicker
              autoFocus
              allowClear
              inputReadOnly
              format="DD.MM.YYYY"
              defaultValue={record[dataIndex] && moment(record[dataIndex])}
              onChange={(value: any) => {
                if (value) {
                  save(moment(value).format('YYYY-MM-DD'), dataIndex);
                } else {
                  save(null, dataIndex);
                }
              }}
              onBlur={({ target: { value } }) => {
                let formattedDate: string | undefined;
                if (value) {
                  formattedDate = moment(value, 'DD.MM.YYYY').format(
                    'YYYY-MM-DD',
                  );
                }
                onBlur(formattedDate, record, dataIndex);
              }}
            />
          </Form.Item>
        );
      case 'datePicker':
        return (
          <Form.Item className="form-item">
            <DatePicker
              autoFocus
              allowClear={false}
              inputReadOnly
              format="DD.MM.YYYY"
              defaultValue={moment(record[dataIndex])}
              onChange={(value: any) => {
                save(moment(value).format('YYYY-MM-DD'), dataIndex);
              }}
              onBlur={({ target: { value } }) => {
                let formattedDate: string | undefined;
                if (value) {
                  formattedDate = moment(value, 'DD.MM.YYYY').format(
                    'YYYY-MM-DD',
                  );
                }
                onBlur(
                  formattedDate === 'Invalid date' ? value : formattedDate,
                  record,
                  dataIndex,
                );
              }}
            />
          </Form.Item>
        );
      case 'inputNumber':
        return (
          <Form.Item
            className="form-item"
            name={dataIndex}
            rules={[
              {
                required: true,
                validator: (rule, value, callback) => {
                  isPositiveNumber(t, rule, value, callback);
                },
                message: t('requiredField'),
              },
            ]}
          >
            <InputNumber
              className="input"
              ref={inputRef}
              defaultValue={record[dataIndex]}
              onPressEnter={save}
              onBlur={({ target: { value } }) => {
                onBlur(value, record, dataIndex);
              }}
              step="1"
              controls={false}
              stringMode
              maxLength={maxLength}
              {...(isDecimal && { precision: 2 })}
              min="0"
            />
          </Form.Item>
        );
      default:
        return (
          <Form.Item
            className="form-item"
            name={dataIndex}
            rules={[
              {
                required: true,
                message: t('requiredField'),
              },
            ]}
          >
            <Input
              className="input"
              ref={inputRef}
              defaultValue={record[dataIndex]}
              onPressEnter={save}
              onBlur={({ target: { value } }) => {
                onBlur(value, record, dataIndex);
              }}
              maxLength={maxLength}
            />
          </Form.Item>
        );
    }
  };

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  return (
    <td {...restProps}>
      {editing && editable ? (
        getInputByType(record, dataIndex)
      ) : (
        <div className="cell-value-wrap" onClick={() => toggleEdit()}>
          {children}
        </div>
      )}
    </td>
  );
};
