import { ReactText } from 'react';
import { isRussian } from './isRussian';

const keysByDataIndex = {
  CountryId: ['CountryNameRu', 'CountryNameEn'],
  PeriodicyId: ['PeriodicyNameRu', 'PeriodicyNameEn'],
  EndPointId: ['EndNameRu', 'EndNameEn'],
  StartPointId: ['StartNameRu', 'StartNameEn'],
  TypeId: ['TypeNameRu', 'TypeNameEn'],
  date: 'Period',
  period: 'Period',
  planeType: 'TypeName',
};

export const changeDataIndexToKey = (
  dataIndex: string | number | readonly ReactText[] | undefined,
): string => {
  const language = localStorage.getItem('i18nextLng');
  const valuesList = Object.keys(keysByDataIndex);
  const indexByLanguage = language && isRussian(language) ? 0 : 1;
  let changedKey = String(dataIndex);

  for (let i = 0; i < valuesList.length; i++) {
    if (valuesList[i].includes(String(dataIndex))) {
      const key = Object.values(keysByDataIndex)[i];

      if (Array.isArray(key)) {
        changedKey = key[indexByLanguage];
      } else {
        changedKey = key;
      }
    }
  }

  return changedKey;
};
