import { ReduxState } from '../store';
import {
  getFetchedRequestState,
  getFetchingRequestState,
  getInitialRequestState,
  MergeRequestState,
  ResponseApiError,
} from '../utils';
import { api } from '../../api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Country } from 'types/company';

export interface CountriesState
  extends MergeRequestState<Country[], ResponseApiError> {}

const selector = {
  state: (state: ReduxState) => state.countries,
  isFetching: (state: ReduxState) => state.countries.isFetching,
  data: (state: ReduxState) => state.countries.data,
  error: (state: ReduxState) => state.countries.error,
};

const initialState: CountriesState = {
  ...getInitialRequestState(),
  error: null,
  data: null,
};

const SLICE_NAME = `countries`;

const requestThunk = createAsyncThunk(
  `${SLICE_NAME}/request`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await api.get<Country[]>('/Countries');

      return data;
    } catch (e) {
      rejectWithValue(e);
    }
  },
  {
    condition: (payload, { getState }) =>
      !selector.isFetching(getState() as ReduxState),
  },
);

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(requestThunk.pending, state => ({
        ...state,
        ...getFetchingRequestState(),
      }))
      .addCase(requestThunk.fulfilled, (state, action) => ({
        ...state,
        ...getFetchedRequestState(),
        data: action.payload as Country[],
        error: null,
      }))
      .addCase(requestThunk.rejected, (state, action) => ({
        ...state,
        ...initialState,
        error: action.payload as ResponseApiError,
      }));
  },
});

interface Countries {
  action: typeof actions;
  thunk: {
    request: typeof requestThunk;
  };
  reducer: typeof reducer;
  selector: typeof selector;
}

export const countries: Countries = {
  action: actions,
  thunk: {
    request: requestThunk,
  },
  reducer,
  selector,
};
