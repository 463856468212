import { api } from 'api';
import { getAuthToken } from 'shared/utils/getAuthToken';
import { handleError } from './validation';

export const saveData = (blob: Blob, fileName: string) => {
  let a = document.createElement('a');
  document.body.appendChild(a);
  //@ts-ignore
  a['style'] = 'display: none';

  var url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const downloadDocument = (url: string, fileName: string) => {
  api
    .get(url, {
      responseType: 'blob',
      headers: getAuthToken(),
    })
    .then(({ data }) => {
      const blob = new Blob([data]);
      saveData(blob, fileName);
    });
};

export const downloadFile = async (
  id: string,
  name: string,
  extension: string,
  documentId?: number,
  version?: number,
) => {
  try {
    const { data } = await api.get(
      `/InsuranceContracts/${id}/documents/${documentId}/download${
        version ? `?version=${version}` : ''
      }`,
      {
        responseType: 'blob',
      },
    );
    const blob = new Blob([data]);
    saveData(blob, `${name}${extension}`);
  } catch (e) {
    handleError(e);
  }
};
