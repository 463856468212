import { isAirline, isBroker } from 'shared/utils/checkUserRole';
import { NewRoute } from 'types/routeStatistic';
import { RouteTypes } from 'types/routeStatistic';
import { api } from 'api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isRussian } from 'shared/utils/isRussian';
import { getDiff } from 'shared/utils/getDiff';
import { renderColumnTitle } from '../../utils';
import { handleError } from 'shared/utils/validation';
import { isNull } from 'lodash-es';
import { InsuranceContractType } from 'types/insuranceContracts';

const requestExploitation = async (id: string) => {
  try {
    const { data } = await api.get(`/InsuranceContracts/${id}/routes`);
    return data;
  } catch (e) {
    handleError(e);
  }
};

const updateRoute = async (
  recordId: string,
  routeData: { TypeId: number; FlightsAmount: number },
  changedId: number,
) => {
  try {
    await api.put(
      `/InsuranceContracts/${recordId}/routes/${changedId}`,
      routeData,
    );
  } catch (e) {
    handleError(e);
  }
};

const createRoute = async (recordId: string, route: NewRoute) => {
  try {
    const { data } = await api.post(
      `/InsuranceContracts/${recordId}/routes`,
      route,
    );
    return data;
  } catch (e) {
    handleError(e);
  }
};

export const useRoutesStatistic = (id: string, contractStatus?: number) => {
  const [routeData, setRouteData] = useState<any>();
  const [responseData, setResponseData] = useState<any>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const counterpartyType = localStorage.getItem('CounterpartyType');

  const columnsByName: { [key: number]: string } = useMemo(
    () => ({
      Inner: [RouteTypes.Local],
      International: [RouteTypes.International],
      Total: [RouteTypes.Total],
    }),
    [],
  );

  const prepareRouteStatistic = useCallback(
    (columnsByKey: any, statisticData: any) => {
      let result: any = [];

      if (statisticData) {
        const entries = Object.entries(statisticData);
        entries?.forEach(([key, value]: any) => {
          const i = columnsByKey[key];
          result[i] = { ...value, key };
        });
      }

      return result.map(
        ({ FlightsAmount, key }: { FlightsAmount: any; key: string }) => {
          return { ...FlightsAmount, key };
        },
      );
    },
    [],
  );

  const refreshRoutes = useCallback(() => {
    requestExploitation(id).then(data => {
      if (!isNull(data)) {
        setRouteData([
          ...prepareRouteStatistic(columnsByName, data),
          { ...data.Inner?.Total, key: 'Total' },
        ]);
        setResponseData(data);
        setIsFetching(false);
      }
    });
  }, [columnsByName, id, prepareRouteStatistic]);

  useEffect(() => {
    refreshRoutes();
  }, [refreshRoutes]);

  const isEditable = useMemo(() => {
    return (
      (isAirline(counterpartyType) &&
        [
          InsuranceContractType.Blank,
          InsuranceContractType.Correction,
        ].includes(Number(contractStatus))) ||
      (isBroker(counterpartyType) &&
        contractStatus === InsuranceContractType.Review)
    );
  }, [contractStatus, counterpartyType]);

  const routeTypeNames: string[] = useMemo(
    () => [
      isRussian(language) ? 'Внутренние' : 'Local',
      isRussian(language) ? 'Международные' : 'International',
      isRussian(language) ? 'Всего' : 'Total',
    ],
    [language],
  );

  const columns = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'Period',
        key: 'type',
        width: '10%',
        render: (_: unknown, x: unknown, index: number) =>
          routeTypeNames[index],
      },
      ...Array(5)
        .fill(0)
        .map((_, index) => ({
          editable: isEditable,
          title: renderColumnTitle(
            responseData?.Inner?.StartDate[index],
            responseData?.Inner?.EndDate[index],
          ),
          key: `insurancePeriod${index}`,
          typeCell: 'inputNumber',
          dataIndex: index,
          render: (index: any) => index?.Value ?? index,
        }))
        .reverse(),
      {
        title: t('insuranceTableColumns.periodChanges'),
        dataIndex: 'Diff',
        key: 'PeriodChanges',
        render: (Diff: number | undefined, _: unknown, index: number) =>
          getDiff(Diff ?? routeData[index]?.[0]?.Diff),
      },
    ];
  }, [
    t,
    routeTypeNames,
    isEditable,
    responseData?.Inner?.StartDate,
    responseData?.Inner?.EndDate,
    routeData,
  ]);

  const isLoading = !columns || !routeData || isFetching;

  const onRouteSave = useCallback(
    ({ record, values }: { record: any; values: object }) => {
      if (!responseData || !routeData) {
        return;
      }
      const key = Number(Object.keys(values)[0]);
      const value = Object.values(values)[0];

      const changedKey = routeData.find(
        ({ key }: { key: string }) => key === record.key,
      )?.key;

      const dataForUpdating = {
        TypeId: changedKey === 'Inner' ? 1 : 2,
        FlightsAmount: value,
      };

      if (record[key]?.Id) {
        updateRoute(id, dataForUpdating, record[key]?.Id).then(refreshRoutes);
      } else {
        const newRoute: NewRoute = {
          Period: key,
          TypeId: changedKey === 'Inner' ? 1 : 2,
          FlightsAmount: value,
        };
        createRoute(id, newRoute).then((data: any) =>
          updateRoute(id, data, data?.Id).then(refreshRoutes),
        );
      }

      setIsFetching(true);
      setTimeout(() => setIsFetching(false), 2000);
    },
    [responseData, routeData, id, refreshRoutes],
  );

  return {
    isLoading,
    columns,
    routeData,
    onRouteSave,
  };
};
