import { FC, ReactElement } from 'react';
import { Button, Form, InputNumber, Modal, Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import { useCreateCoverageModal } from './useCreateCoverageModal';
import { useParams } from 'react-router-dom';
import { Params } from 'types/common';
import { isRussian } from 'shared/utils/isRussian';
import { useTranslation } from 'react-i18next';
import { isPositiveNumber } from 'shared/utils/validation';
import { normFile } from 'shared/utils/normFile';

type CreateCoverageModalProps = {
  visible: boolean;
  close: () => void;
  refreshCoverage: () => void;
};

export const CreateCoverageModal: FC<CreateCoverageModalProps> = ({
  visible,
  close,
  refreshCoverage,
}): ReactElement => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { requestId } = useParams<Params>();
  const {
    form,
    reinsurerList,
    customRequest,
    onSubmit,
  } = useCreateCoverageModal(requestId, refreshCoverage);

  return (
    <Modal
      title={t('uploadingFile.title')}
      visible={visible}
      onCancel={close}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={values => {
          onSubmit(values).then(close);
        }}
      >
        <Form.Item
          label={t('lossesPage.table.reinsurer')}
          name="ReinsurerId"
          rules={[
            { required: true, message: t('createCoverageModal.reinsurer') },
          ]}
        >
          <Select
            options={reinsurerList?.map(({ Id, FullNameEn, FullNameRu }) => ({
              value: Id,
              label: isRussian(language) ? FullNameRu : FullNameEn,
            }))}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: t('error.emptyFile') }]}
          label={t('uploadingFile.title')}
          name="File"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Dragger customRequest={customRequest} maxCount={1}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p
              style={{ display: 'inline-block', width: '90%' }}
              className="ant-upload-text"
            >
              {t('uploadingFile.message')}
            </p>
          </Dragger>
        </Form.Item>
        <Form.Item
          name="Share"
          label={t('coverageHistoryModal.reinsurersShare')}
          rules={[
            {
              required: true,
              validator: (rule, value, callback) => {
                isPositiveNumber(t, rule, value, callback);
              },
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} max={100} addonAfter="%" />
        </Form.Item>
        <Form.Item
          name="Premium"
          label={t('uploadingFile.premiumSize')}
          rules={[
            {
              required: true,
              validator: (rule, value, callback) => {
                isPositiveNumber(t, rule, value, callback);
              },
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} addonAfter="$" />
        </Form.Item>
        <Button htmlType="submit" type="primary">
          {t('add')}
        </Button>
      </Form>
    </Modal>
  );
};
