import { FC, ReactElement } from 'react';
import { Button, Form, Input, Modal, Select, Space, Table } from 'antd';
import { useChangingContractStatusModal } from './useChangingContractStatusModal';
import { useParams } from 'react-router-dom';
import { Params } from 'types/common';
import { useTranslation } from 'react-i18next';

type ChangingContractStatusModalProps = {
  visible: boolean;
  close: () => void;
};

export const ChangingContractStatusModal: FC<ChangingContractStatusModalProps> = ({
  visible,
  close,
}): ReactElement => {
  const { requestId } = useParams<Params>();
  const { t } = useTranslation();
  const {
    statusesList,
    form,
    onSave,
    historyData,
    columns,
  } = useChangingContractStatusModal(requestId);

  return (
    <Modal
      width={1000}
      title={t('changingContractStatusModal.title')}
      visible={visible}
      onCancel={close}
      footer={null}
    >
      <Form onFinish={(values: any) => onSave(values).then(close)} form={form}>
        <Form.Item
          name="Status"
          rules={[
            {
              required: true,
              message: t('changingContractStatusModal.emptyStatus'),
            },
          ]}
        >
          <Select
            placeholder={t('changeStatus')}
            options={
              statusesList
                ? Object.entries(statusesList)?.map(
                    ([key, value]: string[]) => ({
                      value: Number(key),
                      label: value,
                    }),
                  )
                : []
            }
          />
        </Form.Item>
        <Form.Item name="Comment">
          <Input.TextArea placeholder={t('commentPlaceholder')} />
        </Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            {t('editingModal.buttons.okText')}
          </Button>
        </Space>
      </Form>
      <Table
        style={{ marginTop: 20 }}
        dataSource={historyData}
        columns={columns}
      />
    </Modal>
  );
};
