import { AutoComplete, Input } from 'antd';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash-es';

import { useSearchInput } from './useSearchInput';

type SearchInputProps = {
  url?: string;
};

export const SearchInput: FC<SearchInputProps> = ({ url }): ReactElement => {
  const { searchValue } = useSearchInput(url ?? '');

  const { t } = useTranslation();

  return (
    <AutoComplete
      className="autocomplete-input"
      onSearch={debounce(value => searchValue({ search: value }), 200)}
    >
      <Input.Search allowClear size="large" placeholder={t('search')} />
    </AutoComplete>
  );
};
