import { useDispatch, useSelector } from 'react-redux';
import { reinsuranceContracts } from 'store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType } from 'antd/es/table';
import {
  ReinsuranceContractDto,
  ReinsuranceContractStatus,
} from 'types/insuranceContracts';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getReinsuranceContractStatus } from 'shared/utils/getReinsuranceContractStatus';
import { Button, Row } from 'antd';
import { formatMoney } from 'shared/utils/formatMoney';
import { Link } from 'react-router-dom';
import { downloadDocument } from 'shared/utils/fileSaver';
import { isRussian } from 'shared/utils/isRussian';
import { canReinsurerEdit } from 'shared/utils/checkUserRole';

const generateDocumentLink = (
  insuranceContractId: number,
  reinsuranceContractId: number,
) =>
  `InsuranceContracts/${insuranceContractId}/coverage/${reinsuranceContractId}/download`;

export const useReinsurerPage = () => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [modalData, setModalData] = useState<ReinsuranceContractDto>();
  const counterpartyType = localStorage.getItem('CounterpartyType');
  const { data: contracts, isFetching } = useSelector(
    reinsuranceContracts.selector.state,
  );

  const refreshReinsuranceContracts = useCallback(() => {
    dispatch(reinsuranceContracts.thunk.request());
  }, [dispatch]);

  const openModal = useCallback((contract: ReinsuranceContractDto) => {
    setModalData(contract);
  }, []);

  useEffect(() => {
    dispatch(reinsuranceContracts.thunk.request());
  }, [dispatch]);

  const insuredNameField = useMemo(
    () => (isRussian(language) ? 'InsuredNameRu' : 'InsuredNameEn'),
    [language],
  );

  const closeModal = useCallback(() => {
    setModalData(undefined);
  }, []);

  const columns = useMemo(
    (): ColumnType<ReinsuranceContractDto>[] => [
      {
        title: t('reinsurerPage.columns.insuredName'),
        dataIndex: insuredNameField,
        key: insuredNameField,
        render: (name: string, contract: ReinsuranceContractDto) => (
          <Link to={`/company=${contract.InsuredId}`}>
            {name || (
              <span className="empty-string company-link">
                {t('emptyCompanyName')}
              </span>
            )}
          </Link>
        ),
      },
      {
        title: t('reinsurerPage.columns.insuranceStartDate'),
        dataIndex: 'InsuranceStartDate',
        key: 'InsuranceStartDate',
        render: (updateDate: string) =>
          updateDate ? moment(updateDate).format('DD.MM.YYYY') : '-',
      },
      {
        title: t('reinsurerPage.columns.insuranceEndDate'),
        dataIndex: 'InsuranceEndDate',
        key: 'InsuranceEndDate',
        render: (updateDate: string) =>
          updateDate ? moment(updateDate).format('DD.MM.YYYY') : '-',
      },
      {
        title: t('reinsurerPage.columns.planesCount'),
        dataIndex: 'PlanesCount',
        key: 'PlanesCount',
      },
      {
        title: t('reinsurerPage.columns.reinsurersShare'),
        dataIndex: 'Share',
        key: 'Share',
        render: (share: string) => (share ? `${share}%` : '-'),
      },
      {
        title: t('reinsurerPage.columns.premiumSize'),
        dataIndex: 'Premium',
        key: 'Premium',
        render: (premium: string) => formatMoney(premium),
      },
      {
        title: t('reinsurerPage.columns.status'),
        dataIndex: 'Status',
        key: 'Status',
        render: (value: ReinsuranceContractStatus) =>
          getReinsuranceContractStatus(value, language),
      },
      {
        title: t('reinsurerPage.columns.document'),
        key: 'download',
        render: (_: undefined, contract: ReinsuranceContractDto) => {
          const downloadUrl = generateDocumentLink(
            contract.InsuranceContractId,
            contract.Id,
          );
          const fileName = contract.DocumentExtension
            ? `${contract.InsuredNameRu}${contract.DocumentExtension}`
            : '';

          const onClick = () => downloadDocument(downloadUrl, fileName);

          return <Button onClick={onClick}>{t('download')}</Button>;
        },
      },
      {
        width: isRussian(language) ? '20%' : '15%',
        key: 'edit',
        render: (contract, _r) => (
          <Row
            justify={
              canReinsurerEdit(counterpartyType, contract.Status)
                ? 'start'
                : 'space-between'
            }
            align="middle"
          >
            <Link to={`/request=${contract.InsuranceContractId}`}>
              {t('goToContract')}
            </Link>
            {!canReinsurerEdit(counterpartyType, contract.Status) && (
              <Button type="link" onClick={() => openModal(contract)}>
                {t('reinsurerPage.columns.edit')}
              </Button>
            )}
          </Row>
        ),
      },
    ],
    [t, insuredNameField, language, counterpartyType, openModal],
  );

  return {
    contracts,
    isFetching,
    columns,
    modalData,
    closeModal,
    refreshReinsuranceContracts,
  };
};
