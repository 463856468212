import { Form, FormInstance, Modal, notification, Row, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FC, ReactElement, useCallback } from 'react';
import { useBem } from 'shared/hooks';
import { useCreateRoutesModal } from './useCreateRoutesModal';
import { Button, DebounceSelect } from 'shared/components';
import './CreateRoutesModal.sass';
import { getPointList } from 'shared/utils/getPointList';
import { CreatedRoute } from 'types/route';

type CreateRoutesModalProps = {
  visible: boolean;
  close: () => void;
  refresh: () => void;
};

export const CreateRoutesModal: FC<CreateRoutesModalProps> = ({
  visible,
  close,
  refresh,
}): ReactElement => {
  const [form] = useForm<CreatedRoute>();
  const bem = useBem('CreateRoutesModal');
  const { renderFormItems, onSubmit } = useCreateRoutesModal(refresh, close);

  return (
    <Modal
      visible={visible}
      onCancel={close}
      onOk={async () => onSubmit(await form.getFieldsValue())}
      className={bem()}
      width={954}
      closable={false}
      footer={null}
    >
      <h1>Новый маршрут</h1>
      <Form form={form} layout="vertical">
        <Row>
          <Form.Item label="Пункт 1" name="StartPoint" requiredMark>
            <DebounceSelect
              showSearch
              autoFocus
              onChange={({ value }: { value: number }) =>
                form.setFieldsValue({ StartPointId: value })
              }
              fetchOptions={getPointList}
            />
          </Form.Item>
          <Form.Item label="Пункт 2" name="EndPoint" requiredMark>
            <DebounceSelect
              showSearch
              autoFocus
              onChange={({ value }: { value: number }) =>
                form.setFieldsValue({ EndPointId: value })
              }
              fetchOptions={getPointList}
            />
          </Form.Item>
          <Form.Item label="Тип" name="TypeId" requiredMark>
            <Select
              options={[
                {
                  value: 1,
                  label: 'Внутренний',
                },
                {
                  value: 2,
                  label: 'Международный',
                },
              ]}
            />
          </Form.Item>
        </Row>
        {renderFormItems()}
        <Row justify="end">
          <Button onClick={close} title="Отмена">
            Отмена
          </Button>
          <Button
            className={bem('submit-button')}
            type="primary"
            htmlType="submit"
            title="Добавить"
            onClick={async () => onSubmit(await form.getFieldsValue())}
          >
            Добавить
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
