import { FC, ReactElement, useMemo } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { CompanyData, Country } from 'types/company';
import { isRussian } from 'shared/utils/isRussian';

type RequisitesProps = {
  language: string;
  data?: CompanyData;
  countryList?: Country[] | null;
};

export const Requisites: FC<RequisitesProps> = ({
  language,
  data,
  countryList,
}): ReactElement => {
  const { t } = useTranslation();
  const { Title } = Typography;

  const legalCountry = countryList?.find(
    ({ Id }: { Id: number }) => Id === data?.LegalAddress.CountryId,
  );
  const mailingCountry = countryList?.find(
    ({ Id }: { Id: number }) => Id === data?.PostAddress.CountryId,
  );

  const companyName = useMemo(() => {
    return (
      (isRussian(language) ? data?.FullNameRu : data?.FullNameEn) || (
        <span className="empty-string">{t('emptyValues.value')}</span>
      )
    );
  }, [data?.FullNameEn, data?.FullNameRu, language, t]);

  const russianLegalAddressParts = [
    legalCountry?.NameRu,
    data?.LegalAddress.PostCode,
    data?.LegalAddress.CityRu,
    data?.LegalAddress.StreetRu,
    data?.LegalAddress.BuildingRu,
  ];

  const englishLegalAddressParts = [
    legalCountry?.NameEn,
    data?.LegalAddress.PostCode,
    data?.LegalAddress.CityEn,
    data?.LegalAddress.StreetEn,
    data?.LegalAddress.BuildingEn,
  ];

  const legalAddress = (isRussian(language)
    ? russianLegalAddressParts
    : englishLegalAddressParts
  )
    .filter(part => !!part)
    .join(', ');

  const russianMailingAddressParts = [
    mailingCountry?.NameRu,
    data?.PostAddress.PostCode,
    data?.PostAddress.CityRu,
    data?.PostAddress.StreetRu,
    data?.PostAddress.BuildingRu,
  ];

  const englishMailingAddressParts = [
    mailingCountry?.NameEn,
    data?.PostAddress.PostCode,
    data?.PostAddress.CityEn,
    data?.PostAddress.StreetEn,
    data?.PostAddress.BuildingEn,
  ];

  const mailingAddress = (isRussian(language)
    ? russianMailingAddressParts
    : englishMailingAddressParts
  )
    .filter(part => !!part)
    .join(', ');

  return (
    <>
      <div className="info-group">
        <p>
          {t('detailedInfoContent.fullName')}: {companyName}
        </p>
        <p>
          {t('detailedInfoContent.legalAddress')}:{' '}
          {legalCountry ? (
            legalAddress
          ) : (
            <span className="empty-string">{t('emptyValues.value')}</span>
          )}
        </p>
        <p>
          {t('detailedInfoContent.mailingAddress')}:{' '}
          {mailingCountry ? (
            mailingAddress
          ) : (
            <span className="empty-string">{t('emptyValues.value')}</span>
          )}
        </p>
      </div>
      <div className="info-group">
        <Title level={3}>{t('detailedInfoContent.bankDetails')}: </Title>
        <p>
          {t('detailedInfoContent.bankName')}:{' '}
          {data?.BankDetail.BankNameEn || data?.BankDetail.BankNameRu ? (
            isRussian(language) ? (
              data?.BankDetail.BankNameRu ?? (
                <span className="empty-string">{t('emptyValues.value')}</span>
              )
            ) : (
              data?.BankDetail.BankNameEn ?? (
                <span className="empty-string">{t('emptyValues.value')}</span>
              )
            )
          ) : (
            <span className="empty-string">{t('emptyValues.value')}</span>
          )}
        </p>
        <p>
          {t('detailedInfoContent.checkingAccount')}:{' '}
          {data?.BankDetail.AccountNumber ?? (
            <span className="empty-string">{t('emptyValues.value')}</span>
          )}
        </p>
        <p>
          {t('detailedInfoContent.correspondentAccount')}:{' '}
          {data?.BankDetail.CorrespondentAccount ?? (
            <span className="empty-string">{t('emptyValues.value')}</span>
          )}
        </p>
        <p>
          {t('detailedInfoContent.bic')}:{' '}
          {data?.BankDetail.Bic ?? (
            <span className="empty-string">{t('emptyValues.value')}</span>
          )}
        </p>
        <p>
          {t('detailedInfoContent.inn')}/{t('detailedInfoContent.kpp')}:{' '}
          {data?.BankDetail.Inn} /{' '}
          {data?.BankDetail.Kpp ?? (
            <span className="empty-string">{t('emptyValues.value')}</span>
          )}
        </p>
        <p>
          {t('detailedInfoContent.ogrn')}:{' '}
          {data?.BankDetail.Ogrn ?? (
            <span className="empty-string">{t('emptyValues.value')}</span>
          )}
        </p>
      </div>
      <div className="info-group">
        <Title level={3}>{t('detailedInfoContent.contactDetails')}: </Title>
        <p>
          {t('detailedInfoContent.email')}:{' '}
          <a href={`mailto:${data?.Email}`}>
            {data?.Email ?? (
              <span className="empty-string">{t('emptyValues.value')}</span>
            )}
          </a>
        </p>
        <p>
          {t('detailedInfoContent.phoneNumber')}:{' '}
          <a href={`tel:${data?.Phone}`}>
            {data?.Phone ?? (
              <span className="empty-string">{t('emptyValues.value')}</span>
            )}
          </a>
        </p>
      </div>
    </>
  );
};
