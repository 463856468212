import React, { ReactElement } from 'react';
import { Button as AntButton } from 'antd';
import { useBem } from 'shared/hooks';
import { NativeButtonProps } from 'antd/lib/button/button';

import './Button.sass';
import cn from 'classnames';

type CustomButtonType = 'auth' | 'send' | 'cancel' | 'delete';

interface ButtonProps extends NativeButtonProps {
  title: string;
  className?: string;
  icon?: ReactElement;
  customType?: CustomButtonType;
}

export const Button: React.FC<ButtonProps> = ({
  title,
  className,
  customType,
  ...props
}: ButtonProps) => {
  const bem = useBem('Button');
  const typeClassName = customType ? bem(customType) : '';

  return (
    <>
      <AntButton className={cn(bem(), typeClassName, className)} {...props}>
        {title}
      </AntButton>
    </>
  );
};
