import { FC, ReactElement } from 'react';
import { useContractStatusSelect } from './useContractStatusSelect';
import { useParams } from 'react-router-dom';
import { Button, Row, Tooltip, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import { Params } from 'types/common';
import { ChangingContractStatusModal } from 'shared/components/ChangingContractStatusModal/ChangingContractStatusModal';
import { useTranslation } from 'react-i18next';

type ContractStatusSelectProps = {
  status: number;
};

export const ContractStatusSelect: FC<ContractStatusSelectProps> = ({
  status,
}): ReactElement => {
  const { t } = useTranslation();
  const { requestId } = useParams<Params>();
  const { Title } = Typography;
  const {
    visible,
    open,
    close,
    insuranceContractStatus,
    contractStatusByRole,
  } = useContractStatusSelect(requestId, status);
  const counterpartyType = localStorage.getItem('CounterpartyType');

  return (
    <>
      <Row>
        <Title level={2}>{insuranceContractStatus}</Title>
        {contractStatusByRole[Number(counterpartyType)]?.includes(status) && (
          <Tooltip placement="topRight" title={t('changeStatus')}>
            <Button
              onClick={open}
              className="changeContractStatusButton"
              shape="round"
            >
              <RightOutlined />
            </Button>
          </Tooltip>
        )}

        <ChangingContractStatusModal visible={visible} close={close} />
      </Row>
    </>
  );
};
