import { FC, ReactElement } from 'react';
import { Modal, Table } from 'antd';
import { useVersionHistoryModal } from './useVersionHistoryModal';
import { useTranslation } from 'react-i18next';
import { SpinWrapper } from 'shared/components';

type VersionModalProps = {
  documentId?: number;
  closeModal: () => void;
  showModal: boolean;
};

export const VersionHistoryModal: FC<VersionModalProps> = ({
  documentId,
  closeModal,
  showModal,
}): ReactElement => {
  const { t } = useTranslation();
  const { versionData, columns, isLoading } = useVersionHistoryModal(
    closeModal,
    documentId,
  );

  return (
    <Modal
      title={t('versionHistoryModal.title')}
      footer={null}
      visible={showModal}
      onCancel={closeModal}
      width={900}
    >
      <SpinWrapper isLoading={isLoading}>
        <Table dataSource={versionData} columns={columns} />
      </SpinWrapper>
    </Modal>
  );
};
