import { ReinsuranceContractStatus } from 'types/insuranceContracts';
import { api } from 'api';
import { Form, notification } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { InsuranceContractType } from 'types/insuranceContracts';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isRussian } from 'shared/utils/isRussian';
import { isReinsurer } from 'shared/utils/checkUserRole';
import { handleError } from '../../utils/validation';

type ChangedData = {
  Status: number;
  Comment: string;
};

export const useChangingContractStatusModal = (id: string) => {
  const [statusesList, setStatusesList] = useState<{ [key: number]: string }>();
  const [historyData, setHistoryData] = useState();
  const [form] = Form.useForm();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const counterpartyType = localStorage.getItem('CounterpartyType');

  const AvailableStatusesNames: { [key: number]: string } = useMemo(
    () => ({
      [InsuranceContractType.Canceled]: isRussian(language)
        ? 'Отменена'
        : 'Canceled',
      [InsuranceContractType.Blank]: isRussian(language) ? 'Черновик' : 'Blank',
      [InsuranceContractType.Review]: isRussian(language)
        ? 'На рассмотрении'
        : 'Review',
      [InsuranceContractType.Correction]: isRussian(language)
        ? 'Корректировка сведений'
        : 'Correction',
      [InsuranceContractType.Agreed]: isRussian(language)
        ? 'Согласовано'
        : 'Agreed',
      [InsuranceContractType.DocumentCorrection]: isRussian(language)
        ? 'Корректировка документов'
        : 'Document correction',
      [InsuranceContractType.DocumentApproval]: isRussian(language)
        ? 'Согласование документов'
        : 'Document approval',
    }),
    [language],
  );

  const requestHistory = useCallback(async (id: string) => {
    try {
      const { data } = await api.get(
        `/InsuranceContracts/${id}/status/history`,
      );

      return data;
    } catch (e) {
      handleError(e);
    }
  }, []);

  const requestStatuses = useCallback(async (): Promise<{
    [key: number]: string;
  }> => {
    try {
      const {
        data: { AvailableStatuses },
      } = await api.get<{ AvailableStatuses: number[] }>(
        `/InsuranceContracts/${id}/status/available`,
      );
      const result: { [key: number]: string } = {};
      AvailableStatuses?.forEach(status => {
        result[status] = AvailableStatusesNames[status];
      });
      return result;
    } catch (e) {
      throw e;
    }
  }, [AvailableStatusesNames, id]);

  const refresh = useCallback(() => {
    requestHistory(id).then(setHistoryData);
    requestStatuses().then(value =>
      Object.values(value).length
        ? setStatusesList(value)
        : setStatusesList(undefined),
    );
  }, [id, requestHistory, requestStatuses]);

  const columns = useMemo(
    () => [
      {
        title: t('changingContractStatusModal.table.prevState'),
        dataIndex: 'PrevStatus',
        key: 'PrevStatus',
        render: (value: ReinsuranceContractStatus) =>
          AvailableStatusesNames[value],
      },
      {
        title: t('changingContractStatusModal.table.currState'),
        dataIndex: 'CurrStatus',
        key: 'CurrStatus',
        render: (value: ReinsuranceContractStatus) =>
          AvailableStatusesNames[value],
      },
      {
        title: t('changingContractStatusModal.table.comment'),
        dataIndex: 'Comment',
        key: 'Comment',
        render: (Comment: string) => Comment || '-',
      },
      {
        title: t('changingContractStatusModal.table.updatedDate'),
        dataIndex: 'UpdateDate',
        key: 'UpdateDate',
        render: (UpdateDate: string) =>
          UpdateDate ? moment(UpdateDate).format('DD.MM.YYYY') : '-',
      },
    ],
    [AvailableStatusesNames, t],
  );

  const onSave = async (changedValue: ChangedData) => {
    try {
      await api.post(`/InsuranceContracts/${id}/status`, changedValue);
      window.location.reload();
    } catch (e) {
      notification.error({ message: e?.response?.data?.Message });
      throw e;
    }
  };

  useEffect(() => {
    if (!isReinsurer(counterpartyType)) {
      refresh();
    }
  }, [counterpartyType, refresh]);

  return {
    form,
    columns,
    historyData,
    statusesList,
    onSave,
  };
};
