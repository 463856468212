import { useCallback, useEffect, useState } from 'react';

import { Plane } from 'types/planes';
import { api } from 'api';
import { useParams } from 'react-router-dom';
import { Params } from 'types/common';
import { handleError } from 'shared/utils/validation';

const requestPlaneData = async (id?: string) => {
  if (id) {
    try {
      const { data } = await api.get(`/Planes/${id}`);
      return data;
    } catch (error) {
      handleError(error);
    }
  }
};

export const useDetailedAircraftInfo = () => {
  const [planeData, setPlaneData] = useState<Plane>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { planeId } = useParams<Params>();

  const isLoading = isFetching || !planeData;

  const refresh = useCallback(() => {
    setIsFetching(true);
    requestPlaneData(planeId)
      .then(setPlaneData)
      .finally(() => setIsFetching(false));
  }, [planeId]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    planeData,
    isLoading,
  };
};
