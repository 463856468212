import { getDiff } from 'shared/utils/getDiff';
import {
  canAirlineEdit,
  canBrokerEdit,
  isAirline,
  isBroker,
} from 'shared/utils/checkUserRole';
import { prepareDataSource } from 'shared/utils/prepareStatisticData';
import { api } from 'api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ExploitationStatistic,
  InsuranceExploitation,
} from 'types/exploitation';
import { ExploitationTypeNames } from 'shared/utils/exploitation';
import { prepareStatisticData } from 'shared/utils/prepareStatisticData';
import { renderColumnTitle } from '../../utils';
import { handleError } from 'shared/utils/validation';
import { InsuranceContractType } from 'types/insuranceContracts';

const requestExploitation = async (id: string) => {
  try {
    const { data } = await api.get(
      `/InsuranceContracts/${id}/fleet/exploitations`,
    );
    return data;
  } catch (e) {
    throw handleError(e);
  }
};

const updateExploitation = async (
  recordId: string,
  exploitation: ExploitationStatistic,
  changedId: number,
) => {
  try {
    await api.put(
      `/InsuranceContracts/${recordId}/fleet/exploitations/${changedId}`,
      exploitation,
    );
  } catch (e) {
    throw handleError(e);
  }
};

const createExploitation = async (
  recordId: string,
  exploitation: ExploitationStatistic,
) => {
  try {
    const { data } = await api.post(
      `/InsuranceContracts/${recordId}/fleet/exploitations`,
      exploitation,
    );
    return data;
  } catch (e) {
    handleError(e);
  }
};

export const useExploitationStatistic = (
  id: string,
  contractStatus?: number,
) => {
  const [exploitationData, setExploitationData] = useState<
    InsuranceExploitation
  >();
  const [responseData, setResponseData] = useState<any>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { t } = useTranslation();
  const counterpartyType = localStorage.getItem('CounterpartyType');

  const columnsByName = useMemo(
    () => ({
      PassengerCount: [ExploitationTypeNames.PassengerCount],
      DepartureCount: [ExploitationTypeNames.DepartureCount],
      FlyingHours: [ExploitationTypeNames.FlyingHours],
      AvgFlightHours: [ExploitationTypeNames.AvgFlightHours],
      Loading: [ExploitationTypeNames.Loading],
      StartDate: [ExploitationTypeNames.StartDate],
      EndDate: [ExploitationTypeNames.EndDate],
    }),
    [],
  );
  const refreshExploitation = useCallback(() => {
    requestExploitation(id).then(data => {
      const updatedData = { ...data };
      delete updatedData.StartDate;
      delete updatedData.EndDate;
      const result = prepareStatisticData(columnsByName, updatedData);
      setExploitationData(prepareDataSource(result));
      setResponseData(data);
      setIsFetching(false);
    });
  }, [columnsByName, id]);

  useEffect(() => {
    refreshExploitation();
  }, [refreshExploitation]);

  const exploitationTypeNames: string[] = useMemo(
    () => [
      t('exploitationPage.table.passengers'),
      t('exploitationPage.table.flights'),
      t('exploitationPage.table.flightHours'),
      t('exploitationPage.table.averageFlying'),
      t('exploitationPage.table.loading'),
    ],
    [t],
  );

  const isEditable = useMemo(() => {
    return (
      (isAirline(counterpartyType) &&
        [
          InsuranceContractType.Blank,
          InsuranceContractType.Correction,
        ].includes(Number(contractStatus))) ||
      (isBroker(counterpartyType) &&
        contractStatus === InsuranceContractType.Review)
    );
  }, [contractStatus, counterpartyType]);

  const columns = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'Period',
        key: 'type',
        width: '10%',
        render: (_: unknown, x: unknown, index: number) =>
          exploitationTypeNames[index],
      },
      ...Array(5)
        .fill(0)
        .map((_, index) => ({
          editable: isEditable,
          title: renderColumnTitle(
            responseData?.StartDate[index],
            responseData?.EndDate[index],
          ),
          key: `insurancePeriod${index}`,
          typeCell: 'inputNumber',
          maxNumCount: index === 4 ? 100 : undefined,
          dataIndex: index,
          render: (index: any) => index?.Value ?? '0',
        }))
        .reverse(),
      {
        title: t('insuranceTableColumns.periodChanges'),
        dataIndex: 'diff',
        key: 'PeriodChanges',
        render: (_: number, obj: any) => {
          return getDiff(obj[0].Diff);
        },
      },
    ];
  }, [
    t,
    exploitationTypeNames,
    isEditable,
    responseData?.StartDate,
    responseData?.EndDate,
  ]);

  const isLoading = !columns || !exploitationData || isFetching;

  const onExploitationSave = useCallback(
    ({
      record,
      values,
    }: {
      record: { key: string };
      values: { [key: number]: number };
    }) => {
      if (!responseData || !exploitationData) {
        return;
      }

      const key = Number(Object.keys(values)[0]);
      const value = Number(Object.values(values)[0]);

      const dataForUpdating: any = {
        PassengerCount:
          exploitationData[ExploitationTypeNames.PassengerCount][key].Value,
        DepartureCount:
          exploitationData[ExploitationTypeNames.DepartureCount][key].Value,
        FlyingHours:
          exploitationData[ExploitationTypeNames.FlyingHours][key].Value,
        Loading: exploitationData[ExploitationTypeNames.Loading][key].Value,
      };

      const test: { [key: string]: number } = {
        PassengerCount: 0,
        DepartureCount: 1,
        FlyingHours: 2,
        AvgFlightHours: 3,
        Loading: 4,
        StartDate: 5,
        EndDate: 6,
      };
      const changedId = exploitationData[test[record.key]][key].Id;
      dataForUpdating[record.key] = value;
      dataForUpdating.Period = key;

      if (changedId) {
        updateExploitation(id, dataForUpdating, changedId).then(
          refreshExploitation,
        );
      } else {
        createExploitation(id, dataForUpdating).then((data: any) => {
          updateExploitation(
            id,
            { ...data, Period: Number(data?.Period) },
            data?.Id,
          ).then(refreshExploitation);
        });
      }

      setIsFetching(true);
      setTimeout(() => setIsFetching(false), 2000);
    },
    [responseData, exploitationData, id, refreshExploitation],
  );

  return {
    isLoading,
    columns,
    exploitationData,
    onExploitationSave,
  };
};
