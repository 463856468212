import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Typography, Tabs, Row, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useBem, useInsuranceContracts } from 'shared/hooks';
import { LossesPage, TrainingPage, RoutesPage, ServicePage } from 'pages';
import {
  AircraftStatistic,
  DocumentsTable,
  PilotStatistic,
  RoutesStatistic,
  ExploitationStatistic,
  CoverageTable,
  GeneralDetails,
  FlotTable,
} from './components';
import { Params } from 'types/common';
import { api } from 'api';
import {
  InsuranceContract,
  InsuranceContractType,
} from 'types/insuranceContracts';
import { ContractStatusSelect } from './components/ContractStatusSelect/ContractStatusSelect';
import { CounterpartyType } from 'types/counterparties';
import {
  canAirlineEdit,
  isAirline,
  isBroker,
  isReinsurer,
} from 'shared/utils/checkUserRole';

import './InsurancePage.sass';
import { Country } from 'types/company';
import { handleError } from 'shared/utils/validation';

export const InsurancePage: FC<any> = ({
  location: { state },
}): ReactElement => {
  const [generalDetails, setGeneralDetails] = useState<InsuranceContract>();
  const [requestDate, setRequestDate] = useState<string>('');
  const [showAircraftStatistic, setShowAircraftStatistic] = useState<boolean>(
    false,
  );
  const [showTrainings, setShowTrainings] = useState<boolean>(false);
  const [showServices, setShowServices] = useState<boolean>(false);
  const [showRoutes, setShowRoutes] = useState<boolean>(false);
  const [showBackButton, setShowBackButton] = useState<boolean>(false);
  const bem = useBem('InsurancePage');
  const { t } = useTranslation();
  const { requestId } = useParams<Params>();
  const history = useHistory();
  const { Title } = Typography;
  const { TabPane } = Tabs;
  const [isContractEditable, setContractEditable] = useState(false);
  const [activeTab, setActiveTab] = useState('generalInfo');
  const counterpartyType = localStorage.getItem('CounterpartyType');

  const {
    deleteInsuranceContract,
    refreshInsuranceContracts,
  } = useInsuranceContracts();

  const canEditContract = useMemo(
    () =>
      generalDetails?.Status &&
      (canAirlineEdit(counterpartyType, generalDetails?.Status) ||
        (isBroker(counterpartyType) &&
          generalDetails?.Status === InsuranceContractType.Review)) &&
      activeTab === 'generalInfo',
    [activeTab, counterpartyType, generalDetails?.Status],
  );

  const canDeleteContract = useMemo(
    () =>
      generalDetails?.Status === InsuranceContractType.Blank &&
      counterpartyType === CounterpartyType.Airline,
    [counterpartyType, generalDetails?.Status],
  );

  const deleteContract = () => {
    history.push('/');
    deleteInsuranceContract(requestId);
    setTimeout(() => refreshInsuranceContracts(), 300);
  };

  const RenderAircraftContentByRole = ({
    counterpartyType,
  }: {
    counterpartyType: string | null;
  }) => {
    if (isAirline(counterpartyType) || isBroker(counterpartyType)) {
      return showAircraftStatistic ? (
        <AircraftStatistic contractStatus={generalDetails?.Status} />
      ) : (
        <FlotTable contractStatus={generalDetails?.Status} />
      );
    }
    return showAircraftStatistic ? (
      <FlotTable contractStatus={generalDetails?.Status} />
    ) : (
      <AircraftStatistic contractStatus={generalDetails?.Status} />
    );
  };

  useEffect(() => {
    api
      .get<InsuranceContract>(`/InsuranceContracts/${requestId}`)
      .then(({ data }) => {
        setGeneralDetails({
          ...data,
          CountryIds: Array.from(
            new Set(data.CountryList.map(country => (country as Country)?.Id)),
          ),
        });
      })
      .catch(error => {
        handleError(error);
      });
  }, [requestId, isContractEditable]);

  useEffect(() => {
    setRequestDate(
      state?.requestsList.find(
        ({ Id }: { Id: number }) => Id === Number(requestId),
      ).Date,
    );
  }, [requestId, state]);

  useEffect(() => {
    setContractEditable(false);
  }, [requestId]);

  return (
    <>
      <div className={bem('title')}>
        <Title level={2} className={bem('title-item')}>
          {requestDate ??
            `${moment(generalDetails?.StartDate).format(
              'DD.MM.YYYY',
            )} - ${moment(generalDetails?.EndDate).format('DD.MM.YYYY')}`}
        </Title>
        <Row className={bem('title-item')}>
          {canEditContract && (
            <button
              className="button"
              onClick={() => setContractEditable(true)}
            >
              <EditOutlined className={bem('edit-icon')} />
            </button>
          )}
          {canDeleteContract && (
            <Popconfirm
              title={`${t('deleteContract')} ${moment(
                generalDetails?.StartDate,
              ).format('DD.MM.YY')}-${moment(generalDetails?.EndDate).format(
                'DD.MM.YYYY',
              )}?`}
              onConfirm={deleteContract}
              okText={t('deleteOk')}
              cancelText={t('deleteCancel')}
              placement="bottomRight"
            >
              <button className={cn('button', 'delete-contract-button')}>
                <DeleteOutlined />
              </button>
            </Popconfirm>
          )}
          <ContractStatusSelect status={generalDetails?.Status!} />
        </Row>
      </div>
      <Tabs
        type="card"
        onChange={(activeKey: string) => {
          setContractEditable(false);
          setActiveTab(activeKey);
        }}
      >
        <TabPane tab={t('tabs.generalInformation')} key="generalInfo">
          <GeneralDetails
            data={generalDetails}
            isEditable={isContractEditable}
            setEditable={setContractEditable}
          />
        </TabPane>
        <TabPane tab={t('tabs.flot')} key="flot">
          {!showBackButton ? (
            <>
              <p
                className={cn('action-string', 'tabpane-string')}
                onClick={() => {
                  setShowBackButton(true);
                  setShowAircraftStatistic(!showAircraftStatistic);
                }}
              >
                {isReinsurer(counterpartyType)
                  ? t('aircraftStatistic.aircraftList')
                  : t('aircraftStatistic.statistic')}
              </p>
              <p
                className={cn('action-string', 'tabpane-string')}
                onClick={() => {
                  setShowBackButton(true);
                  setShowServices(!showServices);
                }}
              >
                {t('aircraftStatistic.service')}
              </p>
            </>
          ) : (
            <p
              className={cn('action-string', 'tabpane-string')}
              onClick={() => {
                setShowBackButton(false);
                setShowAircraftStatistic(false);
                setShowServices(false);
              }}
            >
              {t('back')}
            </p>
          )}
          {!showServices && (
            <RenderAircraftContentByRole counterpartyType={counterpartyType} />
          )}
          {showServices && <ServicePage showTitle={false} />}
        </TabPane>
        <TabPane tab={t('tabs.marchingNet')} key="routesNetwork">
          <p
            className={cn('action-string', 'tabpane-string')}
            onClick={() => setShowRoutes(!showRoutes)}
          >
            {showRoutes
              ? t('routesNetworkStatistic.routesStatistic')
              : t('routesNetworkStatistic.routes')}
          </p>
          {showRoutes ? (
            <RoutesPage showTitle={false} />
          ) : (
            <RoutesStatistic contractStatus={generalDetails?.Status} />
          )}
        </TabPane>
        <TabPane tab={t('tabs.exploitation')} key="exploitation">
          <ExploitationStatistic contractStatus={generalDetails?.Status} />
        </TabPane>
        <TabPane tab={t('tabs.pilots')} key="pilots">
          <p
            className={cn('action-string', 'tabpane-string')}
            onClick={() => setShowTrainings(!showTrainings)}
          >
            {showTrainings
              ? t('pilotsStatistic.pilots')
              : t('pilotsStatistic.trainings')}
          </p>
          {showTrainings ? (
            <TrainingPage showTitle={false} isEditable={false} />
          ) : (
            <PilotStatistic />
          )}
        </TabPane>
        <TabPane tab={t('tabs.damages')} key="losses">
          <LossesPage showTitle={false} />
        </TabPane>
        <TabPane tab={t('tabs.coating')} key="coating">
          <CoverageTable contractStatus={generalDetails?.Status} />
        </TabPane>
        <TabPane tab={t('tabs.documents')} key="documents">
          <DocumentsTable contractStatus={generalDetails?.Status} />
        </TabPane>
      </Tabs>
    </>
  );
};
