import { FC, ReactElement } from 'react';
import { InputFloatingLabel } from 'shared/components/Input';
import { Col, Form } from 'antd';

interface BankDetailProps {
  name: string;
  className: string;
  message: string;
  maxLength?: number;
  label: string;
  typeNumber?: boolean;
}

export const BankDetail: FC<BankDetailProps> = ({
  name,
  message,
  maxLength,
  label,
  className,
  typeNumber,
}): ReactElement => {
  return (
    <Col span={12}>
      <Form.Item
        name={name}
        rules={[
          {
            required: true,
            message: message,
          },
        ]}
      >
        {typeNumber ? (
          <InputFloatingLabel
            className={className}
            customType="number"
            label={label}
            controls={false}
            maxLength={maxLength}
            stringMode
            min={0}
          />
        ) : (
          <InputFloatingLabel className={className} label={label} />
        )}
      </Form.Item>
    </Col>
  );
};
