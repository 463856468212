import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from 'api';
import { handleError } from 'shared/utils/validation';
import { ReduxState } from 'store';
import {
  getFetchedRequestState,
  getFetchingRequestState,
  getInitialRequestState,
  MergeRequestState,
  ResponseApiError,
} from 'store/utils';
import { SearchQueryParams } from 'types/common';
import { ResponseDataWithPagination } from 'types/requests';

export interface SearchState
  extends MergeRequestState<
    ResponseDataWithPagination<any>,
    ResponseApiError
  > {}

const selector = {
  state: (state: ReduxState) => state.search,
  isFetching: (state: ReduxState) => state.search.isFetching,
  data: (state: ReduxState) => state.search.data,
  error: (state: ReduxState) => state.search.error,
};

const initialState: SearchState = {
  ...getInitialRequestState(),
  error: null,
  data: null,
};

const SLICE_NAME = 'search';

type RequestThunk<T = any> = {
  url: string;
  queryParams?: SearchQueryParams<T>;
};

const requestThunk = createAsyncThunk(
  `${SLICE_NAME}/request`,
  async ({ url, queryParams }: RequestThunk, { rejectWithValue }) => {
    try {
      if (url) {
        const { data } = await api.get(url, { params: queryParams });
        return data;
      }
    } catch (e) {
      handleError(e);
      rejectWithValue(e);
    }
  },
  {
    condition: (payload, { getState }) =>
      !selector.isFetching(getState() as ReduxState),
  },
);

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(requestThunk.pending, state => ({
        ...state,
        ...getFetchingRequestState(),
      }))
      .addCase(requestThunk.fulfilled, (state, action) => ({
        ...state,
        ...getFetchedRequestState(),
        data: action.payload as any,
        error: null,
      }))
      .addCase(requestThunk.rejected, (state, action) => ({
        ...state,
        ...initialState,
        error: action.payload as ResponseApiError,
      }));
  },
});

interface Search {
  action: typeof actions;
  thunk: {
    request: typeof requestThunk;
  };
  reducer: typeof reducer;
  selector: typeof selector;
}

export const search: Search = {
  action: actions,
  thunk: {
    request: requestThunk,
  },
  reducer,
  selector,
};
