import { FC, ReactElement, useState } from 'react';
import { InputNumberProps } from 'antd/lib/input-number';
import { InputNumber } from 'antd';
import { Input as AntdInput } from 'antd';
import { InputProps as AntdInputProps } from 'antd/lib/input';
import cn from 'classnames';

import { useBem } from 'shared/hooks';
import { FloatingLabel } from './FloatingLabel';

import './InputFloatingLabel.sass';

type InputProps = {
  label: string;
  customType?: string;
  className?: string;
} & (AntdInputProps | InputNumberProps);

export const InputFloatingLabel: FC<InputProps> = ({
  label,
  customType,
  className,
  required,
  ...props
}): ReactElement => {
  const [focus, setFocus] = useState(false);
  const bem = useBem('UIKitInput');

  const InputComponent = () => {
    if (customType === 'number') {
      return <InputNumber {...(props as InputNumberProps)} />;
    }

    if (customType === 'password') {
      return <AntdInput.Password {...(props as AntdInputProps)} />;
    }

    return <AntdInput {...(props as AntdInputProps)} />;
  };

  return (
    <div
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      className={cn(bem(), `${className}`)}
    >
      <InputComponent />
      <FloatingLabel
        text={label || ''}
        small={focus || !!props.value || props.value === 0}
        required={required}
        disabled={props.disabled}
      />
    </div>
  );
};
